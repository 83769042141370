export default {
  badge: 'Placa',
  close: 'Fechar',
  dataIterator: {
    noResultsText: 'Nenhum elemento coincide com a pesquisa',
    loadingText: 'A carregar...'
  },
  dataTable: {
    itemsPerPageText: 'Filas por página:',
    ariaLabel: {
      sortDescending: ': Ordem descendente. Prima para retirar ordem.',
      sortAscending: ': Ordem ascendente. Prima para ordenar de forma descendente.',
      sortNone: ': Sem ordenar. Prima para ordenar de forma ascendente.',
      activateAscending: 'Ativar ascensão',
      activateDescending: 'Ativar diminuição',
      activateNone: 'Desativar tudo'
    },
    sortBy: 'Ordenado por'
  },
  dataFooter: {
    itemsPerPageText: 'Elementos por página:',
    itemsPerPageAll: 'Todos',
    nextPage: 'Página seguinte',
    prevPage: 'Página anterior',
    firstPage: 'Primeira página',
    lastPage: 'Última página',
    pageText: '{0}-{1} de {2}'
  },
  datePicker: {
    itemsSelected: '{0} selecionados',
    prevMonthAriaLabel: 'mês anterior',
    nextMonthAriaLabel: 'mês seguinte'
  },
  noDataText: 'Não existem dados disponíveis',
  carousel: {
    prev: 'Visual anterior',
    next: 'Visual seguinte',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}'
    }
  },
  calendar: {
    moreEvents: '{0} mais'
  },
  fileInput: {
    counter: '{0} ficheiros',
    counterSize: '{0} ficheiros ({1} no total)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  home: 'Início',
  templates: 'Fluxos',
  accounts: 'Contas',
  processes: 'Processos',
  transactions: 'Transações',
  statistics: 'Estatísticas',
  support: 'Suporte',
  contacts: 'Contactos',
  administration: 'Administração',
  search: 'Pesquisa',
  appearance: 'Estilos',
  users: 'Utilizadores',
  alastria: 'Alastria',
  billing: 'Faturação',
  account: 'Gerir a minha conta',
  admin: 'Administração',
  demos: 'Demos',
  selectedSubaccount: 'Subconta selecionada',
  withoutSubaccount: 'Sem subcontas',
  groups: 'grupos',
  filterDate: 'Filtro de data',
  subaccount: 'Subconta',
  subaccounts: 'Subcontas',
  costCenters: 'Centros de Custos',
  subaccountsAssigned: 'Subcontas atribuídas',
  defaultCostCenter: 'Centro de custos por defeito',
  selectedCostCenter: 'Centro de custos selecionado',
  availableCostCenters: 'Centros de custos disponíveis',
  changeDefaultCostCenter: 'Alterar centros de custos por defeito',
  costCenterDialogText: 'Se o desejar pode alterar o centro de custos selecionado para a transação. Isto não modificará o centro de custos por defeito, utilizar-se-á apenas de forma pontual para esta transação',
  costCenterDialogTextAccount: 'Se o desejar pode alterar o centro de custos por defeito. Esta ação estabelecerá o centro de custos selecionado como pré-determinado',
  costCenterSelectedTitle: 'Centro de custos selecionado',
  activeSubaccount: 'Subconta ativa',
  user: 'Utilizador',
  userData: 'Dados do utilizador',
  userCECOS: 'Centros de custos',
  userCECO: 'Centro de custos',
  userCodeCECO: 'Código',
  userCECOPrimary: 'Por defeito',
  userCECOCodeTitle: 'Código do centro de custos',
  password: 'Palavra-passe',
  passwordchange: 'Alterar palavra-passe',
  oldpassword: 'Palavra-passe atual',
  newpassword: 'Nova palavra-passe',
  updatePassword: 'Atualizar palavra-passe',
  repeatpassword: 'Repetir palavra-passe',
  passwordrequirements: 'A palavra-passe deve ter pelo menos 8 caracteres e cumprir no mínimo 3 das regras adicionais:',
  passwordMinOneLowercase: 'Mínimo 1 letra minúscula.',
  passwordMinOneUppercase: 'Mínimo 1 letra maiúscula.',
  passwordMinOneNumber: 'Mínimo 1 número.',
  passwordMinOneSpecialChar: 'Mínimo 1 caractere especial.',
  passwordMinEightChars: 'Mínimo 8 caracteres.',
  passwordCompares: 'Comparação de palavra-passe requerida.',
  passwordFieldError: 'Error no campo, consultar regras.',
  passwordrequirementsMoreThanEightChars: 'A palavra-passe deve ter pelo menos 8 caracteres',
  notequalpasswords: 'As palavras-passe não coincidem',
  samepasswordthanolder: 'A nova palavra-passe é igual à anterior',
  emptyOldPassword: 'Não introduziu a palavra-passe anterior',
  role: 'Papel',
  lastConnection: 'Última ligação',
  languageSelector: 'Selecione um idioma',
  logout: 'Fechar sessão',
  others: 'Outros',
  continue: 'Continuar',
  continueWSend: 'Continuar envio',
  sendRecoveryForm: 'Enviar pedido',
  restartFilters: 'Reiniciar filtros',
  back: 'Voltar',
  backToMethods: 'Métodos de recuperação',
  moreInfo: 'Mais informações',
  activateuser: 'Ativar utilizador',
  exit: 'Sair',
  platformVersion: 'Versão da plataforma',
  termsAndConditionsCompliance: 'Termos e Condições',
  privacyPoliciesCompliance: 'Política de Proteção de Dados',
  cookiesPoliciesCompliance: 'Política de Cookies',
  cookiesPoliciesCertySign: 'Cookies na CertySign',
  alertSizeFiles: 'Todos os ficheiros devem pesar no máximo 8Mb',
  login: 'Iniciar sessão',
  noSubaccountsMessage: 'Não dispõe de subcontas atribuídas',
  telephone: 'Telefone',
  name: 'Nome',
  lastName: 'Apelidos',
  email: 'Email',
  omit: 'Omitir',
  id: 'Referência cliente',
  clear: 'Limpar',
  add: 'Adicionar',
  recipients: 'Destinatários',
  shipmentData: 'Dados do envio',
  prevAndSend: 'Pré visualização e envio',
  previewFileSize: 'O ficheiro deve ter no máximo 15Mb',
  rulesFilesSize: 'Todos os ficheiros devem pesar no máximo 15Mb',
  sectionDataFieldsDescription: 'Na parte inferior dispõe dos diferentes campos necessários para o envio da transação dos destinatários. Os campos de contacto serão preenchidos automaticamente, a partir dos dados do destinatário',
  sectionDataFieldsNotAditionalFields: 'Este processo não dispõe de campos adicionais, finalize o envio da sua transação a partir de Pré visualização.',
  sectionPreviewSubtitle: 'Na parte inferior pode navegar entre os destinatários para verificar, individualmente, cada envio',
  manual_version: 'Mostrando a última versão do manual de utilizador.',
  view_login: 'Iniciar sessão',
  view_transactions: 'Transações',
  view_transaction: 'Detalhes de transação',
  view_process: 'Lançar processo',
  view_processes: 'Processos',
  view_statistics: 'Estatísticas',
  view_support: 'Ajuda e suporte',
  view_administration: 'Administração',
  view_groups: 'Grupos',
  view_group: 'Detalhes de grupo',
  view_users: 'Utilizadores',
  view_account: 'A minha conta',
  view_remember_password: 'Lembrar palavra-passe',
  view_change_password: 'Alterar palavra-passe',
  view_activate_user: 'Ativar utilizador',
  view_new_password: 'Nova palavra-passe',
  view_contacts: 'Contactos',
  view_user: 'Detalhes de utilizador',
  view_withoutsubaccounts: '',
  view_forbidden: '',
  view_root: '',
  view_error500: 'Erro',
  searchAgain: 'Voltar a pesquisar',
  recipient: 'Destinatário',
  sender: 'Remetente',
  namesender: 'Nome do remetente',
  subject: 'Assunto',
  emailbody: 'Corpo do email',
  resourcespreview: 'Pré visualização de recursos',
  attach: 'Anexar',
  actions: 'Ações',
  changePassword: 'Atualizar palavra-passe',
  sessionClosed: 'Sessão encerrada corretamente',
  noPermissionMessage: 'Não tem autorizações para aceder a esta secção',
  download: 'Descarregar',
  viewDocument: 'Ver Documento',
  accept: 'Aceitar',
  reject: 'Recusar',
  change: 'Alterar',
  addVariables: 'Adicionar variável',
  resources: 'Recursos',
  launchDataKo: 'Os dados proporcionados não são válidos para realizar o envio',
  launchDataOk: 'Os dados estão corretos, pode proceder ao envio',
  addNewRecipient: 'Adicionar novo destinatário',
  transactionRecipients: 'Destinatários da transação',
  noRecipientsSelected: 'Nenhum destinatário selecionado',
  recipientsSelected: 'Foram selecionados',
  recipientSelected: 'Foi selecionado',
  preview: 'Pré visualização',
  summary: 'Resumo',
  transaction_summary: 'Resumo do envio',
  PDFpreview: 'Pré visualizar PDF',
  previewing: 'Pré visualizando: ',
  notAvailable: 'Não disponível',
  hide: 'Ocultar',
  send: 'Enviar',
  showFile: 'Ver ficheiro',
  error500Message: 'Ocorreu um erro. Se o problema persistir, pode contactar o suporte através',
  thisForm: 'deste formulário',
  error401Message: 'A sessão expirou',
  withoutData: 'Sem dados',
  notInformed: 'Não informado',
  loginTitleText: 'Iniciar sessão',
  changePasswordTitleText: 'Alteração de palavra-passe',
  activateUserTitleText: 'Ativação de utilizador',
  homeWelcomeMessageTitle: 'Bem-vindo à CertySign',
  homeWelcomeMessageSubtitle: 'Ambiente de gestão de transações e fluxos de entrega certificada',
  changePasswordUserTitleText: 'Estabelecer nova palavra-passe',
  activateUserSubtitleText: 'Para a ativação do utilizador é necessário introduzir uma nova palavra-passe',
  changePasswordUserSubtitleText: 'Para estabelecer uma nova palavra-passe preencha os campos',
  activateUserTitleTextWithoutHash: 'URL inválida',
  activateUserSubtitleTextWithoutHash: 'A URL que está a utilizar é inválida. Se o desejar, pode solicitar uma nova URL através da opção "Esqueceu a palavra-passe" do ecrã de login.',
  goToLogin: 'Início de sessão',
  activeFilters: 'Filtros ativos',
  rememberPassword: 'Esqueceu a sua palavra-passe?',
  rememberPasswordTitleText: 'Recuperar palavra-passe',
  supportContact: 'Contacto com suporte',
  rememberPasswordSubTitleText: 'Selecione um método para recuperar a palavra-passe',
  rememberPasswordEmailResponse: 'Se o utilizador proporcionado se encontrar registado, receberá um email com as instruções para a alteração da palavra-passe',
  rememberPasswordWebResponse: 'Se os dados introduzidos estiverem corretos, a nossa equipa de suporte contactará consigo via email e fornecer-lhe-á as instruções para a alteração de palavra-passe',
  rememberPasswordPhoneResponse: 'Se os dados introduzidos estiverem corretos, a nossa equipa de suporte contactará consigo via telefone e fornecer-lhe-á as instruções para a alteração de palavra-passe',
  expiredPasswordResponse: 'A sua palavra-passe expirou, em breve receberá um email com as instruções para estabelecer uma nova palavra-passe',
  rememberPasswordSupportEmail: 'Email de recuperação',
  rememberPasswordSupportEmailSubtitle: 'Enviar-lhe-emos um email a partir do qual poderá recuperar a sua palavra-passe',
  rememberPasswordSupportEmailText: 'Preencha o formulário com o seu email registado na CertySign',
  rememberPasswordSupportWeb: 'Suporte via web',
  rememberPasswordSupportWebSubtitle: 'Enviar um email à equipa de suporte técnico da CertySign',
  rememberPasswordSupportWebText: 'Preencha os dados e a equipa de suporte técnico responder-lhe-á com a maior brevidade.',
  emailSupportServiceHours: 'Horários de atendimento:',
  rememberPasswordSupportPhone: 'Suporte telefónico',
  rememberPasswordSupportPhoneSubtitle: 'Chamada telefónica para a equipa de suporte técnico da CertySign',
  rememberPasswordSupportPhoneText: 'Preencha os dados e a equipa de suporte técnico contactá-lo-á com a maior brevidade.',
  phoneSupportServiceHours: 'Horário de suporte telefónico:',
  rememberPasswordNotSupportBySchedule: 'Opção desativada por estar fora do horário',
  rememberPasswordMondayToFriday: 'De segunda e sexta-feira:',
  rememberPasswordSaturdaySundayAndOthers: 'Sábados, Domingos e Feriados:',
  rememberPasswordNotIncludedDays: '(feriados não incluídos)',
  supportFinalSendMessage: 'Foi enviado o seu pedido ao nosso departamento de suporte',
  goBackToMethods: 'Selecionar outro método',
  rememberPasswordContactName: 'Nome e apelidos',
  goToC2C: 'Solicitar chamada',
  messageDescription: 'Mensagem',
  company: 'Empresa',
  legalCheckboxText: 'Aceito as Condições de utilização da página e a política de proteção de dados',
  selectHelps: 'Como podemos ajudar?',
  selectHelpsTooltip: 'Ajuda e suporte',
  helpManual: 'Ver manual',
  helpGuide: 'Ver guia rápido',
  helpSupport: 'Contactar o suporte',
  helpSupportPhonePreffered: 'Contactar preferencialmente por telefone',
  helpSupportPhoneSchedule: 'Horário de atendimento telefónico, comercial',
  requiredEmail: 'Email requerido',
  requiredPhone: 'Telefone requerido',
  requiredName: 'Nome requerido',
  requiredEmailFormat: 'Formato de email incorreto',
  requiredPhoneFormat: 'Formato de telefone incorreto',
  requiredTextarea: 'Mensagem requerida',
  tag_signature: 'Assinatura eletrónica',
  tag_email: 'Email',
  tag_sms: 'Sms',
  tag_cert_email: 'Email certificado',
  tag_landing: 'Página web',
  tag_biometric_signature: 'Assinatura biométrica',
  tag_postal: 'Postal',
  processesViewListTitle: 'Processos',
  processesViewListDescription: 'Listagem com todos os processos disponíveis na CertySign',
  tags: 'Etiquetas',
  searchs: 'Pesquisar',
  processInfo: 'Informações do processo',
  processesCategoriesFilter: 'Filtro por categorias',
  processesTagFilter: 'Filtro por etiquetas',
  processesTagOthers: 'outros',
  processesSearch: 'Pesquisar processos',
  searchTransactions: 'Consultar transações',
  searchTransaction: 'Consultar transação',
  deleteFilters: 'Eliminar filtros',
  withoutResults: 'Sem resultados',
  withoutProcessResults: 'Não dispõe de processos para o seu lançamento de acordo com as suas autorizações ou com os critérios de pesquisa estabelecidos',
  allProcesses: 'Todos os processos',
  emailNotValid: 'O email é inválido',
  phoneNotValid: 'O telefone é inválido',
  phoneAtLeastNineChars: 'O telefone deve ter pelo menos 9 números',
  doesntExist: 'Não existe',
  dontExist: 'Não existem',
  requiredField: 'Campo requerido',
  errorField: 'Campo errado',
  numberField: 'Campo numérico',
  fiveCharsField: 'O campo deve ter 5 caracteres',
  CPvalidField: 'CP não corresponde à província',
  delete: 'Eliminar',
  save: 'Guardar',
  file: 'Ficheiro',
  loading: 'A carregar',
  countrySelector: 'Selecione um país',
  provinceSelector: 'Selecione uma província',
  andNumberMore: 'e {count} mais',
  selectFile: 'Selecione um ficheiro',
  selectFileHtml: 'Selecione um ficheiro html',
  selectFiles: 'Selecione ficheiros',
  allTransactions: 'Todas as transações',
  transactionsPerPage: 'Transações por página',
  transactionsSend: 'Foram enviados',
  transactionslower: 'transações',
  transactionWithoutId: 'Atribuição de ID pendente',
  withoutCertificated: 'Certificado indisponível',
  filter: 'Filtrar',
  transactionsProcessFilter: 'Filtro por processo',
  processDataPreviewText: 'Em seguida pode encontrar o resumo da nova transação, incluindo o número de envios necessários e o centro de custos associado',
  processDataPreviewTextWithoutCC: 'Em seguida pode encontrar o resumo da nova transação, que indica o número de envios necessários',
  transactionsLinkedGroupsFilter: 'Filtro por grupo',
  transactionsDateFilter: 'Filtro por data',
  numberOfTransactions: 'Número de envios',
  associatedCECO: 'Centro de custos associado',
  transactionsCategoryFilter: 'Filtro por categoria',
  transactionsStatusFilter: 'Filtro por estado',
  transactionState: 'Estado da transação',
  transactionSummary: 'Resumo da transação',
  transactionGeneralData: 'Informações da transação',
  transactionData: 'Dados da transação',
  files: 'Ficheiros',
  transactionContact: 'Dados do contacto',
  transaction: 'Transação',
  transactionEvents: 'Eventos certificáveis da transação',
  transactionWithoutEvents: 'Não existem eventos registados para a transação',
  transactionProgress: 'Progresso da transação',
  transactionSubtitle: 'Informações do estado da transação',
  transactionDetails: 'Detalhes da transação',
  transactionEventInfo: 'Informações do evento',
  eventMeaningPrepend: 'O que significa o evento ',
  eventMeaningAppend: '?',
  eventMeaning: 'O que significa este evento?',
  transactionLaunchedDate: 'Data de criação',
  transactionLastEvent: 'Último evento recebido',
  transactionLastEventDate: 'Data do último evento',
  transactionContactName: 'Nome e apelidos',
  transactionContactMobilephone: 'Telemóvel',
  transactionContactEmail: 'Email',
  transactionContactID: 'ID',
  advancedFilters: 'Filtros avançados',
  group: 'Grupo',
  date: 'Data',
  hideFilters: 'Ocultar filtros',
  processName: 'Nome do processo',
  usernameTransactionDetail: 'Utilizador remetente',
  recipientsNumber: 'Número de destinatários',
  invalidTransaction: 'Dados inválidos',
  dataTableShowRecipients: 'A visualizar envio <span class="font-weight-medium">:page:</span> de <span class="font-weight-medium">{total}</span>',
  transactionRedirectToProcesses: 'Ir para processos',
  transactionRedirectToTransactions: 'Ir para transações',
  transactionRedirectToNewSend: 'Novo envio',
  transactionProcessed: 'Transação processada com sucesso',
  transactionsProcessed: 'Transações processadas com sucesso',
  transactionError: 'Transação processada com inválidos',
  transactionsError: 'Transações processadas com inválidos',
  transactionProcessing: 'A processar transação...',
  transactionLaunchedMessage: 'A transação foi processada com sucesso, à medida que forem processados os envios, serão visíveis na secção de',
  transactionsLaunchedMessage: 'As transações foram processadas com sucesso, à medida que forem processados os envios, serão visíveis na secção de',
  transactionDownloadFile: 'Ver certificado',
  transactionProcessingMessage: 'O sistema está a processar a transação, aguarde um instante',
  transactionsProcessingMessage: 'O sistema está a processar as transações, aguarde um instante',
  transactionsErrorMessage: 'Durante o processo das transações dos diferentes destinatários, detetaram-se dados inválidos em pelo menos uma transação. Em seguida, dispõe da listagem completa de transações do envio',
  transactionErrorMessage: 'Durante o processo da transação detetaram-se dados inválidos',
  recoverProcessQuestion: 'Se começar um processo novo, perderá os avanços',
  recoverProcessQuestionAppend: 'Deseja iniciar um novo processo?',
  statisticsTransactionsMultipleTitle: 'Transações realizadas entre os dias <span class="font-weight-bold">{start_day} de {start_month}</span> e <span class="font-weight-bold">{end_day} de {end_month}</span>',
  statisticsTransactionsOneTitle: 'Transações realizadas no dia {start_day} de {start_month}',
  statisticsTotalTransactionsTitle: 'Totais por tipo de transação',
  statisticsNoResults: 'Não existem resultados que coincidam com os filtros indicados',
  statisticsProcessTitle: 'Processo:',
  total: 'Total',
  totalTransactions: 'Transações totais',
  totalTransactionsByProcesses: 'Descrição de transações por processos',
  accountDescription: 'Configuração de conta',
  accountTitle: 'Conta',
  contactsDescription: 'Listagem e gestão de contactos',
  contactsTitle: 'Contactos',
  transactionsDescription: 'Listagem e detalhes de transações',
  transactionsTitle: 'Transações',
  statisticsDescription: 'Análise gráfica das suas transações',
  statisticsTitle: 'Estatísticas',
  supportDescription: 'Nesta secção encontrará ajuda através do suporte técnico ou do manual de utilizador',
  supportManualDescription: 'Neste ponto pode consultar a documentação relacionada com o manuseamento da plataforma',
  supportManualTitle: 'Consultar manual e guia',
  supportManualTitleOnlyManual: 'Consultar manual',
  supportManualTitleOnlyGuide: 'Consultar guia',
  administrationDescription: 'Administração de contas da plataforma',
  administrationTitle: 'Administração',
  userActive: 'Utilizador ativo',
  userInactive: 'Utilizador desativado',
  userStateTitle: 'Estado do utilizador',
  groupsTitle: 'Grupos de autorizações',
  groupsDescription: 'Gestão dos grupos da conta. Configuração de parâmetros e autorizações de cada grupo.',
  groupsAssignation: 'Grupo atribuído',
  groupInheritFrom: 'Herdar autorizações de',
  groupDataTitle: 'Informações do grupo',
  groupUsersPermissionsTitle: 'Autorizações, utilizadores e processos',
  groupPermissionsTabsTitle: 'Autorizações',
  groupProcessesAndSubaccounts: 'Subcontas e processos',
  groupLinked: 'Grupos vinculados',
  groupPermissionsOwnerText: 'Este grupo é o proprietário da conta, tem atribuídas todas as autorizações',
  groupPermissionsOwnerSubaccountProcessesText: 'Este grupo é o proprietário da conta, tem atribuídas todas as subcontas e todos os processos',
  groupAssociatedProcesses: 'Processos associados',
  groupAssociatedSubaccounts: 'Subcontas associadas',
  newGroup: 'Novo grupo',
  groupName: 'Nome do grupo',
  editGroup: 'A editar grupo',
  groupDescription: 'Descrição',
  groupMinDaysToChange: 'Dias mínimos de vigência de palavra-passe',
  groupTabUsersNameTitle: 'Nome e apelidos',
  groupTabPermissionSectionTitle: 'Secções',
  groupTabPermissionGroupTitle: 'Grupos',
  groupTabPermissionUsersTitle: 'Utilizadores',
  usersSearch: 'Pesquisar utilizadores',
  subaccountWithoutProcesses: 'Subconta sem processos associados',
  selectCostCenter: 'Selecione um centro de custos',
  adminGroupInfoDescription: 'Nesta secção pode editar a descrição, as políticas de sessão e palavras-passe da plataforma.',
  adminGroupInfoUsers: 'Nesta secção pode gerir os utilizadores associados a este grupo.',
  adminGroupInfoPerms: 'Nesta secção pode gerir as autorizações relacionadas com as funções que o utilizador pode realizar na plataforma.',
  adminGroupInfoProcesses: 'Nesta secção pode gerir o acesso do utilizador a processos e subcontas. Este acesso outorgará autorização de lançamento, visualização de transações e estatísticas dos processos atribuídos.',
  adminGroupInfoLinked: 'Nesta secção pode gerir a supervisão sobre outros grupos. Os grupos que estiverem ativados partilharão as informações das transações lançadas, bem como as estatísticas com o grupo atual.',
  passwordsQuantity: 'Número de palavras-passe armazenadas',
  passwordExpiration: 'Dias para expirar a palavra-passe',
  loginRetriesQuantity: 'Quantidade de tentativas em login',
  loginRetriesTime: 'Minutos para voltar a tentar o login',
  sessionExpiration: 'Minutos para expirar a sessão',
  processAlertExpirationQuestion: 'Deseja continuar?',
  processAlertExpirationPhrase: 'Por motivos de segurança, a sessão expirará em breves momentos',
  processAlertExpiration: 'Alerta de validade',
  continueSession: 'Continuar',
  usersTitle: 'Utilizadores',
  activate: 'Ativado',
  deactivate: 'Desativado',
  usersPerPage: 'Utilizadores por página',
  usersDescription: 'Gestão dos utilizadores da conta',
  userInfoTitle: 'Informações do utilizador',
  userPermissionTitle: 'Tipo de autorização atribuída',
  newUser: 'Novo utilizador',
  editUser: 'A editar utilizador',
  groupTitle: 'Nome do grupo',
  administrator: 'Administrador',
  groupLinkedHeader: 'Vinculado',
  firmaElectronica: 'Assinatura eletrónica',
  comunicacionCertificada: 'Comunicação certificada',
  publicacionWeb: 'Publicação web',
  groupVisibility: 'Visibilidade do grupo',
  passwordPolicy: 'Política de palavras-passe',
  frontalPolicy: 'Política de sessão',
  groupData: 'Dados do grupo',
  groupsPerPage: 'Grupos por página',
  groupsFound: 'Foram encontrados',
  cancel: 'Cancelar',
  description: 'Descrição',
  subaccountChanged: 'Subconta alterada',
  processContinueTitle: 'Tem um processo pendente de envio',
  processContinueSubtitle: 'Deseja recuperar o processo ou iniciar um novo',
  newProcess: 'Novo processo',
  recover: 'Recuperar',
  newShipping: 'Novo envio',
  toContinueFillFields: 'Para continuar preencha corretamente os campos',
  passwordRequired: 'Palavra-passe necessária',
  userRequired: 'Utilizador requerido',
  fieldAlert: 'O campo %%fields%% será preenchido automaticamente a partir dos destinatários',
  fieldAlertPlural: 'Os campos %%fields%% serão preenchidos automaticamente a partir dos destinatários',
  fieldNumberPhone: 'Telefone',
  fieldCountryCode: 'código de país',
  fieldEmail: 'email',
  fieldName: 'nome',
  fieldSurname: 'apelidos',
  and: 'e',
  certificate: 'Certificado',
  country: 'País',
  continueEditing: 'Editar os dados de envio',
  second: 'segundo',
  seconds: 'segundos',
  nextRefreshAvailableAt: 'Próxima atualização disponível em',
  fastSearch: 'Pesquisa rápida',
  update: 'Atualizar',
  process: 'Processo',
  transactionId: 'Id da transação',
  transactionClientId: 'Referência do cliente',
  createdDate: 'Criada',
  updatedAt: 'Atualizada',
  state: 'Estado',
  noGroupAssigned: 'Sem grupo selecionado',
  contact_country_code: 'Código de país',
  contact_email: 'Email',
  contact_id: 'Referência cliente',
  contact_name: 'Nome',
  contact_phone: 'Telefone',
  contact_surname: 'Apelidos',
  contact_complete_name: 'Nome completo',
  certificateValidDownload: 'Certificado descarregado corretamente',
  certificateFailDownload: 'Não foi possível descarregar o certificado',
  graphicBarValidDownload: 'Gráfico descarregado corretamente',
  graphicBarFailDownload: 'Não foi possível descarregar o gráfico',
  //events
  queued: 'A transação está pendente de ser processada.',
  new_communication: 'A transação foi gerada corretamente.',
  orchestrator_new_communications_communication_received: 'A transação foi processada corretamente.',
  commstracker_generate_url_generated: 'Foi gerada a ligação da URL encurtada.',
  commstracker_generate_url_click: 'Foi aberta a ligação da transação.',
  communication_send_sms_sent: 'Foi depositada a mensagem de texto no centro de serviço de envio do fornecedor. Este, por sua vez, fará a gestão do envio ao seu destinatário final.',
  communication_send_sms_not_sent: 'A mensagem de texto não foi depositada no centro de serviço de envio do fornecedor.',
  communication_send_sms_delivered: 'A mensagem de texto foi entregue ao destinatário.',
  communication_send_sms_not_delivered: 'A mensagem de texto não foi entregue ao destinatário.',
  generate_otp_code_otp_generated: 'Foi gerada uma chave de utilização única (OTP) para utilização.',
  generate_certificate_generated: 'Foi gerado o certificado de finalização da transação, o qual reúne os conteúdos da comunicação e as evidências associadas aos eventos ocorridos no processo.',
  communication_send_email_delivered: 'A mensagem foi entregue ao fornecedor de serviços de correio eletrónico (ESP) do destinatário.',
  communication_send_email_complaint: 'O destinatário da mensagem assinalou o correio eletrónico recebido como não desejado.',
  communication_send_email_queued: 'A mensagem de correio eletrónico está em fila.',
  communication_send_email_deferred: 'A mensagem de correio eletrónico está em fila e será entregue quando for possível.',
  communication_send_email_bounce: 'A mensagem de correio eletrónico foi recusada.',
  communication_send_email_sent: 'Foi depositada a mensagem de correio eletrónico no centro de serviço de envio do fornecedor. Este, por sua vez, fará a gestão do envio ao seu destinatário final.',
  communication_send_email_sender_not_allowed: 'A mensagem de correio eletrónico não foi enviada porque o remetente não está autorizado.',
  communication_send_email_open: 'A mensagem de correio eletrónico foi aberta pelo destinatário.',
  communication_send_email_click: 'O destinatário da mensagem de correio eletrónico premiu uma ligação.',
  communication_send_email_block: 'O endereço de correio eletrónico do destinatário está na lista de exclusões, a mensagem não foi enviada.',
  communication_send_email_unsubscribe: 'O destinatário da mensagem de correio eletrónico deve eliminar o registo da lista de distribuição.',
  communication_send_email_spam: 'A mensagem de correio eletrónico foi identificada como não desejada.',
  communication_send_email_hard_blacklisted: 'O endereço de correio eletrónico do destinatário está na lista de exclusões, a mensagem não foi enviada.',
  communication_send_email_complaint_blacklisted: 'O endereço de correio eletrónico do destinatário está na lista de reclamações, a mensagem não foi enviada.',
  document_upload_s3_uploaded: 'Foi armazenado de forma segura o certificado de finalização da transação, o qual reúne os conteúdos da comunicação e as evidências associadas aos eventos ocorridos no processo.',
  communication_send_telegram_delivered: 'A mensagem de texto foi entregue ao destinatário.',
  communication_send_telegram_not_delivered: 'A mensagem de texto não foi entregue.',
  web_framework_generated: 'Foi gerada a interface gráfica web para que o destinatário possa interagir.',
  web_framework_solicitud_otp: 'Foi solicitada uma chave de utilização única (OTP).',
  web_framework_signable_document_loaded: 'Foi carregado na interface web um documento para ser assinado.',
  web_framework_otp_simple_signature_ok: 'Foi assinada a documentação através da chave de utilização única.',
  web_framework_commited: 'Foi concluído o processo na interface web.',
  web_framework_document_signed: 'Foi assinada a documentação.',
  web_framework_form_based_identification: 'Foi concluído o passo de autenticação através de formulário.',
  web_framework_certificate_based_identification: 'Foi realizado um acesso com certificado pessoal de forma satisfatória',
  web_framework_document_based_identification: 'Foi identificado o formulário de forma correta.',
  web_framework_certificate_based_identification_failed: 'Falhou o processo de identificação mediante certificado.',
  web_framework_document_based_identification_failed: 'Falhou o processo de identificação mediante documento.',
  web_framework_form_based_identification_failed: 'Falhou o processo de identificação mediante formulário.',
  web_framework_web_framework_expired: 'A transação expirou.',
  web_framework_documentation_downloaded: 'A documentação foi descarregada pelo destinatário.',
  web_framework_electronic_notificacion_rejected: 'A notificação eletrónica foi recusada pelo destinatário.',
  web_framework_gdpr_formulary_committed: 'O formulário de consentimento foi preenchido pelo destinatário.',
  web_framework_sepa_formulary_committed: 'O formulário SEPA foi preenchido pelo destinatário.',
  web_framework_requested_otp_code: 'Foi solicitada uma chave de utilização única (OTP).',
  web_framework_entered_wrong_otp_code: 'A chave de utilização única (OTP) introduzida pelo destinatário está incorreta.',
  web_framework_entered_timedout_otp_code: 'A chave de utilização única expirou.',
  web_framework_entered_correct_otp_code: 'A chave de utilização única (OTP) introduzida pelo destinatário está correta.',
  web_framework_electronic_notificacion_accepted: 'A notificação eletrónica foi aceite pelo destinatário.',
  web_framework_signature_rejected: 'O pedido de assinatura eletrónica foi recusado pelo destinatário.',
  web_framework_request_otp_code_too_early: 'A chave de utilização única (OTP) foi solicitada pelo destinatário antes do tempo estipulado para a poder voltar a gerar.',
  web_framework_capture_data_commited_no_response: 'Foi tentada a realização do envio do formulário sem dados nas respostas',
  web_framework_capture_data_commited: 'Foi realizado o envio de dados corretamente',
  web_framework_document_previewed: 'A documentação foi pré visualizada pelo destinatário.',
  web_framework_document_simply_signed: 'Foi assinada eletronicamente a documentação.',
  document_upload_alastria_uploaded: 'O hash do certificado de finalização foi carregado no nó da CertySign na Rede Blockchain de Alastria.',
  publish_downloadable_file_generated: 'Foi gerada a documentação para publicação na interface web.',
  publish_downloadable_file_open: 'Foi aberta a documentação para publicação na interface web.',
  document_sign_signed: 'Foi assinado o certificado.',
  rpc_callback_published: 'Foi publicado o retorno da chamada RPC',
  loop_add_added: 'Foi adicionada a transação ao circuito de tentativas.',
  loop_add_tick: 'Foi realizada uma tentativa adicional',
  loop_add_timeout: 'Esgotou-se o limite de tentativas',
  loop_cancel_canceled: 'O circuito foi cancelado',
  identification_create_identification_generated: 'Foi criado o processo mediante o qual o utilizador se identificará na transação.',
  identification_create_identification_ok: 'Foi identificado da forma correta',
  test_load_processed: 'Foi processado o comprovativo de carga',
  instance_delete_deleted: 'A instância correspondente à transação foi eliminada.',
  unzip_file_extracted: 'O ficheiro foi descomprimido.',
  communicalia_consent_accepted: 'O destinatário da comunicação aceitou o consentimento.',
  communicalia_consent_declined: 'O destinatário da comunicação recusou o consentimento.',
  communicalia_consent_no_data: 'O destinatário da comunicação não respondeu.',
  robinson_list_accepted: 'O destinatário não se encontra na lista de Robinson',
  robinson_list_declined: 'O destinatário encontra-se registado na lista Robinson',
  robinson_list_no_data: 'O destinatário encontra-se registado na lista Robinson',
  publish_embeddable_file_generated: 'Foi gerado o ficheiro embutido para a interface web',
  document_upload_sftp_uploaded: 'Foi realizado o carregamento da documentação através de SFTP.',
  file_delete_deleted: 'Foi eliminado o ficheiro.',
  disable_framework_web_framework_web_disabled: 'Foi desativada a interface web associada à transação.',
  generate_onespan_transaction_transaction_create: 'Foi criada a transação de assinatura eletrónica com OneSpan',
  generate_onespan_transaction_transaction_create_error: 'Ocorreu um erro ao criar a transação de assinatura eletrónica no OneSpan.',
  generate_onespan_transaction_transaction_complete: 'Foi concluída a transação de assinatura eletrónica no OneSpan.',
  generate_onespan_transaction_transaction_expire: 'Expirou a transação de assinatura eletrónica no OneSpan.',
  generate_onespan_transaction_transaction_decline: 'Foi recusada a transação de assinatura eletrónica no OneSpan.',
  generate_onespan_transaction_email_bounce: 'A mensagem de correio eletrónico correspondente à transação de assinatura eletrónica no OneSpan foi recusada.',
  generate_onespan_transaction_transaction_opt_out: 'O destinatário optou por não assinar a transação.',
  generate_onespan_transaction_transaction_delete: 'Foi eliminada a transação de assinatura eletrónica no OneSpan.',
  generate_onespan_transaction_transation_desactivate: 'Foi desativada a transação de assinatura eletrónica no OneSpan.',
  generate_onespan_transaction_kba_failure: 'A transação foi bloqueada devido a motivos de segurança no acesso ao processo.',
  generate_onespan_transaction_transaction_sent: 'A transação de assinatura eletrónica do OneSpan foi enviada.',
  generate_onespan_transaction_transaction_sent_error: 'O envio da transação de assinatura eletrónica do OneSpan falhou.',
  generate_onespan_transaction_package_trash: 'A transação foi movida para o caixote de lixo do OneSpan.',
  get_onespan_files_files_onespan_generated: 'Foram gerados os ficheiros necessários para o OneSpan',
  communication_send_postal_generated: 'Foi gerado o envio da comunicação postal.',
  communication_send_postal_sent_to_factory: 'O envio foi carregado na plataforma de comunicação postal.',
  communication_send_postal_printed: 'A documentação associada ao envio postal foi impressa.',
  communication_send_postal_pre_admission_requested: 'O envio da comunicação está a ser pré-registado telematicamente na web do operador postal.',
  communication_send_postal_pre_admitted: 'O envio da comunicação foi registado telematicamente pelo operador postal, antes do seu depósito.',
  communication_send_postal_sent_to_logistic_operator: 'O envio da comunicação está a ser depositado no operador postal.',
  communication_send_postal_on_the_way_to_logistic_operator: 'O envio está a caminho da estação de correios mais próxima do destinatário da comunicação.',
  communication_send_postal_received_by_logistic_operator: 'O envio está na estação de correios mais próxima do destinatário da comunicação.',
  communication_send_postal_not_received_by_logistic_operator: 'O envio da comunicação chegou à estação de correios.',
  communication_send_postal_on_delivery: 'O envio da comunicação postal está a caminho.',
  communication_send_postal_delivered_to_recipient: 'O envio da comunicação postal foi entregue ao destinatário.',
  communication_send_postal_returned_to_postal_office: 'O envio está na estação de correios e o destinatário da comunicação tem o aviso na caixa de correio.',
  communication_send_postal_delivered_into_postal_office: 'O envio da comunicação postal foi entregue ao destinatário na estação de correios.',
  communication_send_postal_generic_delivered: 'O envio da comunicação postal foi entregue ao destinatário.',
  communication_send_postal_returned: 'Foi impossível realizar a entrega do envio e a comunicação postal foi devolvida à origem.',
  communication_send_postal_canceled: 'O envio da comunicação postal foi cancelado.',
  communication_send_postal_lost: 'O envio da comunicação postal foi extraviado.',
  communication_send_postal_expired: 'O envio da comunicação postal foi devolvido à origem por não ter sido recolhido pelo destinatário nos 15 dias úteis após o aviso ter sido deixado na caixa de correio.',
  communication_send_postal_rejected: 'O envio da comunicação postal foi recusado pelo destinatário.',
  communication_send_postal_stolen: 'O envio da comunicação postal foi subtraído ou roubado.',
  communication_send_postal_outside_sicer: 'O envio da comunicação não está registado no operador postal.',
  communication_send_postal_bad_postal_address: 'A morada do envio não estão correta.',
  communication_send_postal_passed_away: 'O destinatário do envio da comunicação postal faleceu.',
  communication_send_postal_other: 'Outros',
  communication_send_postal_no_information: 'Não consta informação do envio da comunicação no operador postal.',
  communication_send_postal_received_pee: 'Foi recebido Comprovativo Eletrónico de Entrega (PEE) do envio.',
  communication_send_postal_certificate_returned: 'Foi gerado o certificado de devolução com Comprovativo de Impossibilidade de entrega do operador postal.',
  communication_send_postal_unknown: 'O destinatário do envio da comunicação é desconhecido na morada indicada.',
  zip_files_path_zipped: 'Foram comprimidos um ou vários documentos',
  intromission_api_retries_muted: 'Foram desativadas as tentativas da transação',
  intromission_api_retries_unmuted: 'Foram ativadas as tentativas da transação',
  intromission_api_instance_canceled: 'A transação foi cancelada.',
  antivirus_filtered: 'O antivírus filtrou a transação devido ao facto de haver recursos potencialmente perigosos.',
  //API messages
  user_operation_error_creating_user: 'Ocorreu um erro ao criar o utilizador',
  user_operation_user_already_exists: 'O utilizador já existe',
  user_operation_user_created: 'Utilizador criado com sucesso',
  user_operation_user_updated: 'Utilizador atualizado com sucesso',
  user_operation_no_exists: 'O utilizador não existe',
  user_operation_password_changed: 'Foi atualizada a palavra-passe',
  user_operation_pass_incorrect: 'A combinação de utilizador e palavra-passe está incorreta',
  user_operation_password_expired: 'A palavra-passe expirou',
  user_operation_logout: 'Sessão encerrada corretamente',
  user_operation_blocked_user: 'O utilizador está bloqueado',
  user_operation_wrong_subdomain: 'O nome de utilizador, ou a palavra-passe não está correta',
  user_operation_max_pass_tries: 'Utilizador bloqueado, número de tentativas excedido',
  user_operation_deleted: 'Utilizador eliminado corretamente',
  user_operation_error_deleted: 'Erro ao eliminar o utilizador',
  user_has_been_disabled: 'Utilizador desativado',
  user_operation_error_user_not_found: 'Se o email indicado já existe, receberá em breve um email com as indicações para alterar a sua palavra-passe',
  user_operation_remember_password_email: 'Se o email indicado já existe, receberá em breve um email com as indicações para alterar a sua palavra-passe',
  email_sent_to_support: 'Pedido enviado corretamente',
  user_operation_error_change_password_min_days: 'Não é possível alterar a palavra-passe devido a não ter passado o mínimo de dias necessário',
  user_operation_error_passwords_mismatch: 'As palavras-passe não coincidem',
  user_operation_error_password_has_been_used_before: 'A palavra-passe foi utilizada com anterioridade, escolha uma nova',
  user_or_group_no_exists: 'Utilizador ou grupo não existe',
  user_error_already_active: 'O utilizador já está ativo',
  activation_email_resend: 'Foi reenviado o email de ativação',
  group_operation_error_creating_group: 'Ocorreu um erro ao criar o grupo',
  group_operation_group_already_exists: 'O grupo já existe',
  group_operation_group_created: 'Grupo criado com sucesso',
  group_operation_group_updated: 'Grupo :group_name: atualizado com sucesso',
  //Terms and conditions
  termsConditionsDPOTitle: 'Delegado de Proteção de Dados',
  termsConditionsTitular: 'Titular',
  termsConditionsNIF: 'N.I.F.',
  termsConditionsDomicilioSocial: 'Domicílio Social',
  termsConditionsDatosRegistrales: 'Dados de Registo',
  termsConditionsContacto: 'Contacto',
  termsConditionsDPOEmailTitle: 'Email Customer Comms',
  termsConditionsDPOEmail: 'Endereço de correio eletrónico do Delegado de Proteção de Dados',
  termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (doravante CCOMMS) na sua condição de titular e responsável pela presente APP, em cumprimento do disposto no artigo 10 da Lei 34/2002, de 11 de julho, de Serviços da Sociedade de Informação e de Comércio Eletrónico, põe à disposição dos utilizadores as seguintes informações desta APP:',
  termsConditionsObjetoTitulo: 'OBJETO',
  termsConditionsObjetoParrafo1: 'As presentes Condições Gerais de Utilização (doravante, as «CGU») regulam a utilização da Aplicação CertySign (doravante, a APP) que a CCOMMS põe à disposição dos utilizadores que acedam à mesma a fim de proceder a poder realizar o pedido realizado.',
  termsConditionsObjetoParrafo2: 'Portanto, as presentes CGU aplicar-se-ão tanto à atividade promocional e de fornecimento de informações que se efetua através da APP como, por exemplo, a prestação dos serviços oferecidos no mesmo, de tal forma que as mesmas irão reger, sempre, tanto a simples navegação pela APP, como para a contratação de serviços no enquadramento da APP, se bem que estas últimas atividades adicionalmente, se submeterão tanto às CGU descritas em seguida, como às Condições Gerais de Contratação aplicáveis, e às Condições Particulares que, em cada caso, possam existir.',
  termsConditionsUsuariosTitulo: 'UTILIZADORES',
  termsConditionsUsuariosParrafo1: 'O acesso e/ou a utilização da APP, atribui a quem o realiza a condição de utilizador aceitando, a partir desse mesmo momento, plenamente e sem reservas algumas, as presentes CGU, bem como as Condições Particulares que, em cada caso, complementem, modifiquem ou substituam as CGU relativamente a determinados serviços e conteúdos da APP, obrigando-se a respeitar as restrições de utilização de dita página estabelecidas pela CCOMMS em cada momento.',
  termsConditionsAccesoAPPTitulo: 'ACESSO À APP',
  termsConditionsAccesoAPPParrafo1: 'O acesso e a utilização da APP tem caráter restringido e para poder aceder à mesma será necessário o registo prévio do utilizador.',
  termsConditionsUsoAPPTitulo: 'UTILIZAÇÃO DA APP',
  termsConditionsUsoAPPParrafo1: 'O utilizador compromete-se a utilizar a APP, os seus serviços e conteúdos sem ir contra a legislação vigente, a boa-fé, as utilizações geralmente aceites e a ordem pública. Assim, fica proibida a utilização da APP com fins ilícitos ou lesivos contra a CCOMMS ou qualquer terceiro, ou que, de qualquer forma, possam causar prejuízos ou impedir o normal funcionamento da APP.',
  termsConditionsResposabilidadTitulo: 'RESPONSABILIDADE DO UTILIZADOR POR DANOS E PREJUÍZOS',
  termsConditionsResposabilidadParrafo1: 'A utilização da APP realizar-se-á sob a única e exclusiva responsabilidade do utilizador. Dita responsabilidade estender-se-á à utilização, por parte do utilizador, ou de qualquer terceiro, de quaisquer palavras-passe ou similares atribuídas para o acesso à APP, ou a quaisquer dos seus serviços.',
  termsConditionsResposabilidadParrafo2: 'Sem prejuízo do anterior, a CCOMMS reserva-se o direito a negar ou desativar, a qualquer momento e sem necessidade de aviso prévio, o acesso à APP ou o acesso restrito aos utilizadores que não cumpram estas CGU, as normas estabelecidas pela CCOMMS ou seus colaboradores ou possam perturbar o bom funcionamento, a imagem, a credibilidade e/ou o prestígio da CCOMMS ou dos seus colaboradores.',
  termsConditionsResposabilidadParrafo3: 'Neste sentido, a CCOMMS reserva-se a faculdade de cancelar ou restringir o acesso à APP a determinados utilizadores, no caso de observar quaisquer condutas que de acordo com a decisão da CCOMMS sejam contrárias a estas Condições de Utilização, à Lei, às normas estabelecidas pela CCOMMS ou seus colaboradores ou possam perturbar o bom funcionamento, a imagem, a credibilidade e/ou o prestígio da CCOMMS ou dos seus colaboradores. Assim, a CCOMMS poderá exigir responsabilidade aos Utilizadores pelos danos e prejuízos derivados da má utilização da APP.',
  termsConditionsResposabilidadParrafo4: 'A CCOMMS não garante a licitude, a fiabilidade e a utilidade, bem como a sua veracidade ou exatidão dos conteúdos que os utilizadores transmitam durante a utilização da APP.',
  termsConditionsResposabilidadParrafo5: 'A CCOMMS não controla a utilização que os utilizadores fazem da APP, nem garante que o façam de forma conforme as presentes Condições Gerais.',
  termsConditionsResposabilidadParrafo6: 'A CCOMMS não controla, nem garante a ausência de vírus informáticos nos serviços prestados por terceiros através da APP que possam produzir alterações no seu sistema informático (software e hardware), ou nos documentos e ficheiros eletrónicos armazenados no seu sistema informático.',
  termsConditionsPropiedadIntelectualTitulo: 'PROPRIEDADE INTELECTUAL',
  termsConditionsPropiedadIntelectualParrafo1: 'Qualquer utilização ou modificação do Material ou dos Conteúdos para qualquer outro fim que não o autorizado nas Condições Gerais será considerado uma violação das leis internacionais de "copyright", que protegem os direitos de autor.',
  termsConditionsPropiedadIntelectualParrafo2: 'Relativamente aos conteúdos é proibido:',
  termsConditionsPropiedadIntelectualListaELemento1: 'A sua reprodução, cópia, distribuição, modificação ou manipulação, de qualquer forma e com qualquer finalidade, a menos que se conte com a autorização prévia e por escrito dos seus legítimos titulares ou seja legalmente permitido.',
  termsConditionsPropiedadIntelectualListaELemento2: 'Qualquer violação dos direitos da CCOMMS ou dos seus legítimos titulares sobre os mesmos.',
  termsConditionsPropiedadIntelectualListaELemento3: 'A sua utilização para todo o tipo de fins comerciais ou publicitários, diferentes dos estritamente permitidos.',
  termsConditionsPropiedadIntelectualParrafo3: 'A CCOMMS garante que os conteúdos, incluindo os de propriedade intelectual, não são de caráter ilícito, nem infringem a normativa vigente. Por conseguinte, os conteúdos da APP não terão caráter xenófobo, pornográfico, discriminatório, racista ou que de qualquer forma fomente a violência.',
  termsConditionsPropiedadIntelectualParrafo4: 'Além disso, a CCOMMS adotará as medidas legais que considere convenientes para impedir qualquer tipo de conduta contrária à Lei ou à moral.',
  termsConditionsModificacionUnilateralTitulo: 'MODIFICAÇÃO UNILATERAL',
  termsConditionsModificacionUnilateralParrafo1: 'A CCOMMS poderá modificar, unilateralmente, e sem aviso prévio, sempre que o considere oportuno, a estrutura e o design da APP, bem como modificar ou eliminar os serviços, os conteúdos e as condições de acesso e/ou utilização da APP.',
  termsConditionsExclusionGarantiasTitulo: 'EXCLUSÃO DE GARANTIAS DE RESPONSABILIDADE',
  termsConditionsExclusionGarantiasParrafo1: 'A CCOMMS não outorga qualquer garantia, nem se responsabiliza, em caso algum, pelos danos e prejuízos de qualquer natureza que possam ter como causa:',
  termsConditionsExclusionGarantiasListaELemento1: 'O incumprimento da Lei, da moral e dos bons costumes, bem como a ordem pública, ou a utilização dos produtos e/ou serviços oferecidos de forma não diligente ou incorreta, ou com fins ou efeitos ilícitos ou contrários ao estabelecido nas presentes CGU ou nas condições aplicáveis que em cada caso sejam aplicáveis.',
  termsConditionsExclusionGarantiasListaELemento2: 'Pela aquisição ou contratação dos serviços, bens ou produtos que possam ser adquiridos ou contratados a terceiros através de um acesso na APP da CCOMMS, especialmente nos casos em que o processo de compra ou contratação se realiza diretamente na app de terceiros e ainda que apareçam distintivos ou uma «frame» com os elementos gráficos da app (Branding).',
  termsConditionsExclusionGarantiasListaELemento3: 'A falta de disponibilidade, manutenção e eficiente funcionamento da APP e/ou dos seus serviços ou conteúdos.',
  termsConditionsExclusionGarantiasListaELemento4: 'A falta de utilidade, adequação ou validade da APP e/ou dos seus serviços ou conteúdos para satisfazer necessidades, atividades ou resultados concretos ou expetativas dos utilizadores',
  termsConditionsExclusionGarantiasListaELemento5: 'A existência de vírus, programas maliciosos ou lesivos nos conteúdos.',
  termsConditionsExclusionGarantiasListaELemento6: 'A receção, obtenção, armazenamento, difusão ou transmissão, por parte dos utilizadores, dos conteúdos.',
  termsConditionsExclusionGarantiasListaELemento7: 'A ilicitude ou falta de veracidade, exatidão, fiabilidade, pertinência, atualidade e exaustividade dos conteúdos e informações transmitidas ou postas à disposição dos utilizadores, incluindo as informações e serviços prestados por terceiros ou pelos utilizadores através da APP.',
  termsConditionsExclusionGarantiasListaELemento8: 'O incumprimento por parte de terceiros das suas obrigações ou compromissos relativamente aos serviços prestados aos utilizadores através da APP.',
  termsConditionsExclusionGarantiasListaELemento9: 'Dos conteúdos, ficheiros, informações, publicidade, opiniões, conceitos e imagens que não dependam da APP, nem sejam geridos pela CCOMMS. Nem sequer se responsabiliza pela má utilização que se realize dos conteúdos da APP, sendo exclusiva a responsabilidade de cada pessoa que aceda a eles, ou os utilize.',
  termsConditionsExclusionGarantiasListaELemento10: 'Da utilização indevida das chaves de acesso dos utilizadores para o acesso aos conteúdos do serviço da APP que os requeiram e das consequências derivadas de qualquer natureza da má utilização por parte dos utilizadores, a sua perda ou esquecimento, e a sua utilização indevida por terceiros não autorizados.',
  termsConditionsExclusionGarantiasListaELemento11: 'A incapacidade de qualquer utilizador ou o roubo de identidade de um terceiro efetuada pelo utilizador.',
  termsConditionsExclusionGarantiasListaELemento12: 'Dos conteúdos fornecidos pelos utilizadores.',
  termsConditionsProteccionDatosTitulo: 'PROTEÇÃO DE DADOS',
  termsConditionsProteccionDatosParrafo1: 'Pode consultar as informações detalhadas sobre Proteção de Dados na nossa página de',
  termsConditionsLeyJurisdiccionTitulo: 'LEGISLAÇÃO APLICÁVEL E JURISDIÇÃO',
  termsConditionsLeyJurisdiccionParrafo1: 'Todas as Condições, Gerais e Particulares, inseridas na presente APP são regidas na normativa espanhola. Todos os conflitos, controvérsias ou situações derivadas das Condições Gerais e particulares submeter-se-ão aos Julgados e Tribunais da Vila de Madrid, renunciando expressamente as partes à sua jurisdição.',
  termsConditionsProteccionDatosTitle: 'Consultar Informações de Proteção de Dados',
  termsConditionsProteccionDatos: 'Proteção de Dados',
  //Privacy Policies
  privacyPoliciesConditionsTitle: 'Proteção de dados da MailTecK & Customer Communications Tecknalia',
  privacyPoliciesConditionsParrafo1: 'Em conformidade com o estabelecido tanto no Regulamento (UE) 2016/679 do Parlamento Europeu e do Conselho, de 27 de abril de 2016, relativo à proteção das pessoas físicas no que respeita ao tratamento de dados pessoais e à livre circulação destes dados (RGPD), como na Lei Orgânica 3/2018, de 5 de dezembro, de Proteção de Dados Pessoais e garantia dos direitos digitais (LOPD-GDD) e demais normativas sobre proteção de dados vigentes, informa-se os utilizadores da APP, que voluntariamente manifestaram o seu consentimento de forma livre, clara e inequívoca para o tratamento dos dados pessoais ao aceitar a caixa «Política de Proteção de Dados» que os seus dados vão ser detetados, em função da empresa na qual se tenham registado, pela empresa MAILTECK, S.A. ou pela empresa CUSTOMER COMMUNICATIONS TECKNALIA, S.L. as quais serão denominadas conjuntamente como GRUPO MAILCOMMS.',
  privacyPoliciesConditionsResponsable1Title: 'Responsável 1',
  privacyPoliciesConditionsResponsable2Title: 'Responsável 2',
  privacyPoliciesConditionsFinalidadTitle: 'Finalidade',
  privacyPoliciesConditionsLegitimacionTitle: 'Legitimação',
  privacyPoliciesConditionsDestinatariosTitle: 'Destinatários',
  privacyPoliciesConditionsDerechosTitle: 'Direitos',
  privacyPoliciesConditionsInformacionAdicionalTitle: 'Informações adicionais',
  privacyPoliciesConditionsPoliticaInformacion1Title: 'Política da informação 1',
  privacyPoliciesConditionsPoliticaInformacion2Title: 'Política da informação 2',
  privacyPoliciesConditionsIdentidadTitle: 'Identidade',
  privacyPoliciesConditionsNIFTitle: 'NIF',
  privacyPoliciesConditionsDireccionTitle: 'Morada',
  privacyPoliciesConditionsTelefonoTitle: 'Telefone',
  privacyPoliciesConditionsCorreoTitle: 'Email',
  privacyPoliciesConditionsDPDTitle: 'Contacto com o DPD',
  privacyPoliciesConditionsDPDCustomerCommsTitle: 'Delegado de Proteção de Dados da Customer Comms',
  privacyPoliciesConditionsDPDMailteckTitle: 'Delegado de Proteção de Dados da Mailteck',
  privacyPoliciesConditionsFinalidadIntro: 'Gerir e tramitar os serviços contratados',
  privacyPoliciesConditionsFinalidadParrafo1: 'O GRUPO MAILCOMMS vai tratar os dados fornecidos, como parte da execução de um contrato subscrito por si ou pela sua empresa, a fim de gerir e tramitar os serviços acordados no mesmo, bem como dar suporte à aplicação Certysign',
  privacyPoliciesConditionsFinalidadParrafo2: 'O GRUPO MAILCOMMS, tratará apenas os dados fornecidos para as finalidades descritas anteriormente e não serão tratados posteriormente de forma incompatível com ditos fins. Os dados pessoais fornecidos serão conservados enquanto se mantiver a relação contratual.',
  privacyPoliciesConditionsLegitimacionIntro: 'Execução do contrato',
  privacyPoliciesConditionsLegitimacionParrafo1: 'Os dados foram fornecidos por si ou pela sua empresa através dos canais estabelecidos para lhe poder dar acesso à aplicação Certysign, como consequência do contrato de prestação de serviços subscrito.',
  privacyPoliciesConditionsDestinatariosIntro: 'Não se cedem a terceiros',
  privacyPoliciesConditionsDestinatariosParrafo1: 'Por exigência legal quando uma norma assim o exige.',
  privacyPoliciesConditionsDerechosIntro: 'Pode exercer os seus direitos na forma e nos prazos legalmente previstos.',
  privacyPoliciesConditionsDerechosElem1ListaTitulo: 'Confirmação geral:',
  privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Qualquer pessoa tem direito a obter confirmação sobre se o GRUPO MAILCOMMS está a tratar os dados pessoais que lhe são respeitantes.',
  privacyPoliciesConditionsDerechosElem2ListaTitulo: 'Direitos ARCO:',
  privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'As pessoas interessadas têm direito a aceder aos seus dados pessoais, bem como a solicitar a retificação dos dados incorretos ou, em cada caso, solicitar a sua supressão quando, entre outros motivos, os dados já não sejam necessários para os fins que foram recolhidos.',
  privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Limitação do tratamento:',
  privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'Em determinadas circunstancias, os interessados poderão solicitar a limitação do tratamento dos seus dados, em cujo caso apenas os conservaremos para o exercício ou para a defesa de reclamações.',
  privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Oposição ao tratamento:',
  privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'Em determinadas circunstâncias e por motivos relacionados com a sua situação particular, os interessados poder-se-ão opor ao tratamento dos seus dados. Portanto, o GRUPO MAILCOMMS deixará de tratar os dados, salvo por motivos legítimos imperiosos, ou pelo exercício da defesa de possíveis reclamações.',
  privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Portabilidade dos dados:',
  privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'Em determinadas circunstâncias e por motivos relacionados com a sua situação particular, os interessados poderão solicitar receber os dados pessoais fornecidos no formulário de contacto, num formato estruturado, de utilização comum e legível por máquina, bem como transmitir ditos dados a outros responsável pelo tratamento sem impedimentos.',
  privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Reclamação perante organismo público:',
  privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'No caso de considerar posto em causa o seu direito à proteção de dados pessoais, poderá intentar uma reclamação perante a Agência Espanhola de Proteção de Dados',
  privacyPoliciesConditionsDerechosAEPDTitle: 'AEPD',
  privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Canal para exercer os direitos:',
  privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Os interessados poderão exercer os direitos que lhes reconheça a normativa perante e contra cada um dos responsáveis, perante as indicações ativada como DPO no ponto "Responsável".',
  privacyPoliciesConditionsInformacionAdicionalIntro: 'Mais informações sobre Proteção de Dados.',
  privacyPoliciesConditionsInformacionAdicionalLista1: 'Os dados pessoais foram obtidos dos canais que as empresas do GRUPO MAILCOMMS estabeleceram para poder gerir e os serviços contratados por si ou pela sua empresa.',
  privacyPoliciesConditionsInformacionAdicionalLista2: 'As categorias de dados que se vão tratar serão os dados fornecidos para o desenvolvimento de cada serviço contratado.',
  privacyPoliciesConditionsInformacionAdicionalLista3: 'O GRUPO MAILCOMMS não trata dados especialmente protegidos.',
  privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTecK, S.A. Política de Segurança e Privacidade das Informações com base na ISO 27001 -> Ler mais.',
  privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Customer Communication Tecknalia, S.L. Política de Segurança e Privacidade das Informações baseada na norma ISO 27001 -> Ler mais.',
  privacyPoliciesConditionsPoliticaInformacionTituloH: 'ANEXO I: POLÍTICA DO SISTEMA DE GESTÃO DE SEGURANÇA E PRIVACIDADE DAS INFORMAÇÕES.',
  privacyPoliciesConditionsPoliticaInformacionP1: 'Esta Política de Segurança e Privacidade das Informações mostra o compromisso da Direção dotando a organização de recursos, responsabilidades e procedimentos que permitam garantir o cumprimento da normativa vigente, bem como a integridade, confidencialidade e disponibilidade das informações e dos sistemas, sendo crucial para a segurança, privacidade e continuidade da nossa organização, bem como a dos nossos clientes.',
  privacyPoliciesConditionsPoliticaInformacionP2: 'O Grupo MailTeck, consciente de que a segurança e privacidade das informações relativas aos nossos clientes é um recurso com grande valor, estabeleceu um Sistema de Gestão da Segurança e Privacidade das Informações de acordo com os requisitos das normas ISO/IEC 27001:2013 e ISO/IEC 27701:2019 para garantir os níveis de Segurança exigidos, a Continuidade dos serviços prestados e o Cumprimento legal no tratamento de dados de caráter pessoal, minimizando, assim, os riscos derivados desta gestão.',
  privacyPoliciesConditionsPoliticaInformacionCCTP2: 'O Custommer Comms, consciente de que a segurança e privacidade das informações relativas aos nossos clientes é um recurso com grande valor, estabeleceu um Sistema de Gestão da Segurança e Privacidade das Informações de acordo com os requisitos das normas ISO/IEC 27001:2013 e ISO/IEC 27701:2019 para garantir os níveis de Segurança exigidos, a Continuidade dos serviços prestados e o Cumprimento legal no tratamento de dados de caráter pessoal, minimizando, assim, os riscos derivados desta gestão.',
  privacyPoliciesConditionsPoliticaInformacionP3: 'O objetivo da Política de Segurança e Privacidade é fixar o enquadramento de atuação necessário para proteger os recursos de informações perante ameaças, internas ou externas, deliberadas ou acidentais, a fim de assegurar o cumprimento das confidencialidade, integridade e disponibilidade das informações.',
  privacyPoliciesConditionsPoliticaInformacionP4: 'A eficácia e aplicação do Sistema de Gestão da Segurança e Privacidade das Informações é responsabilidade direta do Comité de Segurança e Privacidade das Informações, o qual é responsável pela aprovação, difusão e cumprimento da presente Política de Segurança e Privacidade. Em seu nome e representação foi nomeado um Responsável pelo Sistema de Gestão da Segurança e Privacidade das Informações, que possua a suficiente autoridade para desempenhar um papel ativo no Sistema de Gestão da Segurança e Privacidade das Informações, supervisionando a sua implementação, desenvolvimento e manutenção.',
  privacyPoliciesConditionsPoliticaInformacionP5: 'Adicionalmente, e para dar cumprimento à normativa vigente de proteção de dados, foi nomeado um Delegado de Proteção de Dados (DPO) com o objetivo de garantir a segurança e privacidade dos dados de caráter pessoal, especialmente tendo em conta a atividade da empresa.',
  privacyPoliciesConditionsPoliticaInformacionP6: 'O Comité de Segurança e Privacidade das Informações procederá ao desenvolvimento e aprovação da metodologia de análise de riscos utilizada no Sistema de Gestão da Segurança e da Privacidade das Informações.',
  privacyPoliciesConditionsPoliticaInformacionP7: 'Todas as pessoas cuja atividade possa, direta ou indiretamente, ser afetada pelos requisitos do Sistema de Gestão da Segurança e Privacidade das Informações, é obrigada ao cumprimento estrito da Política de Segurança e Privacidade.',
  privacyPoliciesConditionsPoliticaInformacionP8: 'No Grupo MailTeck implementar-se-ão todas as medidas necessárias para cumprir a normativa aplicável em matéria de segurança e privacidade em geral e de segurança informática, relativa à política informática, à segurança de edifícios e instalações e ao comportamento de colaboradores e terceiros associados ao Grupo MailTecK na utilização de sistemas informáticos e tratamento de dados pessoais. As medidas necessárias para garantir a segurança e a privacidade das informações mediante a aplicação de normas, procedimentos r controlos deverão permitir assegurar a confidencialidade, integridade, disponibilidade das informações, essenciais para:',
  privacyPoliciesConditionsPoliticaInformacionCCTP8: 'Na Custommer Comms implementar-se-ão todas as medidas necessárias para cumprir a normativa aplicável em matéria de segurança e privacidade em geral e de segurança informática, relativa à política informática, à segurança de edifícios e instalações e ao comportamento de colaboradores e terceiros associados ao Custommer Comms na utilização de sistemas informáticos e tratamento de dados pessoais. As medidas necessárias para garantir a segurança e a privacidade das informações mediante a aplicação de normas, procedimentos r controlos deverão permitir assegurar a confidencialidade, integridade, disponibilidade das informações, essenciais para:',
  privacyPoliciesConditionsPoliticaInformacionLista1: 'Cumprir com a legislação, regulamentação e normativa vigente em matéria de privacidade e dos sistemas de informação e com todos os requisitos contratuais em matéria de proteção de dados, bem como os que considere oportunos levar a cabo com o objetivo de conseguir uma melhoria contínua da organização.',
  privacyPoliciesConditionsPoliticaInformacionLista2: 'Assegurar a confidencialidade dos dados geridos pelo Grupo MailTeck.',
  privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'Assegurar a confidencialidade dos dados geridos pela Custommer Comms.',
  privacyPoliciesConditionsPoliticaInformacionLista3: 'Assegurar a disponibilidade dos sistemas de informação, tanto nos serviços oferecidos aos clientes, como na gestão interna.',
  privacyPoliciesConditionsPoliticaInformacionLista4: 'Assegurar a capacidade de resposta perante situações de emergência, restabelecendo o funcionamento dos serviços críticos no menor tempo possível.',
  privacyPoliciesConditionsPoliticaInformacionLista5: 'Evitar alterações indevidas nas informações.',
  privacyPoliciesConditionsPoliticaInformacionLista6: 'Promover a consciencialização e formação em segurança e privacidade das informações.',
  privacyPoliciesConditionsPoliticaInformacionLista7: 'Impulsionar e participar na obtenção da melhoria contínua no sistema de gestão da segurança e privacidade das informações.',
  cookiesPoliciesIntroText: 'Esta página web utiliza cookies para melhorar a sua experiência enquanto navega pela página web. Pode alterar estas definições a qualquer momento. As cookies classificadas como necessárias são armazenadas no seu navegador e são essenciais para o funcionamento das funcionalidades básicas da página web. As cookies de terceiros serão armazenadas no seu navegador web apenas com o seu consentimento e ajudam-nos a analisar e a compreender como se utiliza esta página web. Tem a opção de optar por não receber estas cookies, no entanto, a exclusão voluntária de alguma destas cookies pode fazer com que algumas funções deixem de estar disponíveis. Para obter informações sobre eliminar as cookies, consulte a função de ajuda do seu navegador.',
  cookiesPoliciesNecessaryCookies: 'Cookies necessárias',
  cookiesPoliciesNecessaryCookiesText: 'Este tipo de cookies é absolutamente essencial para que a página web funcione corretamente. Esta categoria só inclui cookies que garantam funcionalidades básicas e características de segurança da página web e não recolhem ou armazenam quaisquer informações pessoais sobre os utilizadores da página web.',
  cookiesPoliciesUnnecessaryCookies: 'Cookies não necessárias',
  cookiesPoliciesUnnecessaryCookiesText: 'As cookies que possam não ser particularmente necessárias para que a página web funcione se utilizam especificamente para recolher dados pessoais do utilizador através da análise, anúncios e outros conteúdos integrados denominam-se cookies não necessárias. É obrigatório obter o consentimento do utilizador antes de executar estas cookies na sua página web.',
  cookiesPoliciesGoogleAnalyticsCookies: 'Cookies da Google Analytics',
  cookiesPoliciesGoogleAnalyticsCookiesText: 'Para obter mais informações relativamente a este tipo de cookies, pode obter mais informações consultando o documento “Utilização das cookies da Google Analytics em páginas web',
  cookiesPoliciesGoogleAnalyticsCheckboxText: 'Manter cookies ativas',
  cookiesNotificationBarText: 'Utilizamos cookies, tanto próprias, como de terceiros, para medir as visitas, garantir a funcionalidade da página web e analisar a navegação na mesma. Em seguida, pode aceitar as cookies ou configurar/recusar a utilização das mesmas. Pode obter mais informações sobre a titularidade da web, responsável pelo tratamento das cookies e dos seus dados de contacto na nossa',
  cookiesNotificationBarConfig: 'Configurar',
  cookiesPoliciesModalIntro: 'Introdução',
  cookiesPoliciesModalIntroP1: 'Esta página web utiliza cookies e/ou tecnologias similares que armazenam e recuperam informações quando navega para melhorar e otimizar a experiência do utilizador.',
  cookiesPoliciesModalIntroP2: 'Em geral, estas tecnologias podem servir para finalidades muito diversas como, por exemplo, reconhecer como utilizador, obter informações sobre hábitos de navegação, ou personalizar a forma como se mostram os conteúdos.',
  cookiesPoliciesModalIntroP3: 'Em conformidade com o Real Decreto Lei 13/2012 e sua afetação no artigo 22.2 da Lei de Serviços da Sociedade de Informações e Comércio Eletrónico, mediante a presente Política informamos das diferentes cookies que a CUSTOMMER COMMUNICATIONS TECKNALIA, S.L. recolhe através dos diferentes meios ou canais de comunicação, próprios ou de terceiros, isentas ou não do dever de informar e os diferentes meios através dos quais pode recusar, permitir ou bloquear as mesmas.',
  cookiesPoliciesModalIntroP4: 'Em seguida detalham-se informações sobre: o que são as «Cookies», que tipologia utiliza esta página web, como podem ser desativadas num navegador e como bloquear, especificamente, a instalação de Cookies de terceiros.',
  cookiesPoliciesModalResponsibility: 'Quem é o responsável pela utilização das cookies na presente página web?',
  cookiesPoliciesModalResponsibilityP1: 'O responsável pela utilização das cookies é a CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (doravante CCOMMS), com N.I.F. B-86414000 e com domicílio social em Leganés (Madrid) Avenida de la Recomba, 14, devidamente inscrita no Registo Comercial de Madrid no Tomo 29.801, Folio 98, Secção 8ª, Folha número M-536210.',
  cookiesPoliciesModalResponsibilityP2: 'Endereço de correio eletrónico do Delegado de Proteção de Dados.',
  cookiesPoliciesModalWhatIsCookie: 'O que é uma cookie?',
  cookiesPoliciesModalWhatIsCookieP1: 'Uma cookie é um dado, ficheiro ou dispositivo de armazenamento e recuperação de dados (DARD), que um navegador web armazena de forma automática no dispositivo (Smartphone, tablet ou televisão ligada) de um utilizador ao aceder a páginas web, contas de redes sociais ou ao receber um email',
  cookiesPoliciesModalWhatIsCookieP2: 'As cookies permitem armazenar e recuperar, de forma automática, dados sobre a utilização que se fez dos citados meios digitais e do equipamento, ou dispositivo, desde que acede aos mesmos.',
  cookiesPoliciesModalWhatIsCookieP3: 'Uma cookie são apenas dados que se armazenam no computador do utilizador, pelo que não são software, nem sequer são fragmentos de código, são simplesmente dados. Portanto, em princípio as cookies não podem transmitir e executar vírus, nem instalar malware como cavalos de troia ou programas de espionagem.',
  cookiesPoliciesModalCookieTypes: 'Que tipo de cookies existem na página web e sua finalidade',
  cookiesPoliciesModalCookiesInstall: 'Que cookies podem ser instaladas na web de Verificação de Certificados?',
  cookiesPoliciesModalCookiesInstallP1: 'O acesso da Web da Certysign implica que se possam instalar as Cookies, próprias e/ou de terceiros, que se detalham no quadro seguinte:',
  cookiesPoliciesModalBrowserConfigCookies: 'Como configurar as cookies no navegador',
  cookiesPoliciesModalBrowserConfigCookiesP1: 'Tem a opção de não receber cookies ou de ser informado sobre a sua fixação mediante a configuração do seu navegador para tal efeito, todos os cookies, salvo as de Flash, podem ser geridas a partir do seu navegador, normalmente na secção «Opções» ou «Preferências».',
  cookiesPoliciesModalBrowserConfigCookiesP2: 'Pode desativá-las de todo, em cujo caso o seu navegador não permitirá que se instale qualquer uma (impossibilitando que possa fazer uso de todas as funcionalidades da página web), ou apenas as que advenham de determinadas páginas web, o que lhe permitirá poder desfrutar das funcionalidades avançadas das webs que respeitam a privacidade dos seus dados, e excluir as que não o façam.',
  cookiesPoliciesModalBrowserConfigCookiesP3: 'Nas ligações seguintes indicamos-lhe os acessos às principais páginas de suporte:',
  cookiesPoliciesModalBrowserConfigCookiesP4: 'Assim, o Utilizador pode opor-se à utilização de determinadas cookies mediante os seguintes serviços:',
  cookiesPoliciesModalBrowserConfigCookiesLI1: 'Bloqueio de cookies no Chrome',
  cookiesPoliciesModalBrowserConfigCookiesLI2: 'Bloqueio de cookies no Safari',
  cookiesPoliciesModalBrowserConfigCookiesLI3: 'Bloqueio de cookies no Firefox',
  cookiesPoliciesModalBrowserConfigCookiesLI4: 'Bloqueio de cookies no Internet Explorer',
  cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
  cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
  cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
  cookiesPoliciesModalUnavailableCookies: 'O que acontece se se desativarem as cookies?',
  cookiesPoliciesModalUnavailableCookiesP1: 'Se recusar as cookies poderá continuar a navegar pela nossa página web, ainda que a utilização de alguns dos seus serviços possa ser limitada e, portanto, a sua experiência pode ser menos satisfatória.',
  cookiesPoliciesModalUnavailableCookiesP2: 'A recusa, bloqueio ou não autorização das cookies descritas na presente Política pode afetar uma navegação correta pelos conteúdos reproduzidos na página web',
  cookiesPoliciesModalAcceptCookies: 'Aceitação da política de cookies',
  cookiesPoliciesModalAcceptCookiesP1: 'Ao premir o botão «ACEITAR COOKIES» assume-se que aceita a utilização de cookies.',
  cookiesPoliciesModalAcceptCookiesP2: 'Não obstante, com cada início de sessão indicar-se-á a Política de Cookies na parte inferior de qualquer página do portal com o objetivo de que fique consciente.',
  cookiesPoliciesModalUpgradeCookies: 'Atualizamos a nossa política de cookies?',
  cookiesPoliciesModalUpgradeCookiesP1: 'É possível que as informações fornecidas na presente Política de Cookies possam sofrer modificações devido às atualizações ou alterações na política de tratamento de cookies, ou seja, a Agência Espanhola de Proteção de Dados pode indicar instruções e/ou recomendações que façam a mesma variar.',
  cookiesPoliciesModalUpgradeCookiesP2: 'Por isso recomendamos que consulte esta política sempre que aceder à nossa página web com o objetivo de estar adequadamente informado sobre como e para quê utilizamos as cookies.',
  cookiesPoliciesModalRecipientCookies: 'Quem são os destinatários das informações e como a utilização de cookies afeta a proteção de dados pessoais?',
  cookiesPoliciesModalRecipientCookiesP1: 'Para mais informações sobre o tratamento dos seus dados pode consultar a ',
  cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Política de Proteção de Dados',
  cookiesPoliciesModalContactInfoCookies: 'Informações de contacto',
  cookiesPoliciesModalContactInfoCookiesP1: 'Para qualquer dúvida, clarificação e/ou sugestão relativamente às cookies, pedimos-lhe que entre em contacto através de correio eletrónico ',
  cookiesPoliciesModalDatatableCookieType: 'Tipo de Cookie',
  cookiesPoliciesModalDatatableTreatmentTerm: 'Prazo de Tratamento',
  cookiesPoliciesModalDatatableTypeAnalytics: 'Analíticas',
  cookiesPoliciesModalDatatableTypeSelf: 'Próprias',
  cookiesPoliciesModalDatatableTypeAnalyticsD: 'Sempre que um utilizador visita um Serviço, uma ferramenta de um fornecedor externo gera uma Cookie analítica no computador do Utilizador. Esta Cookie, que só se gera na visita, servirá nas próximas visitas à CCOMMS para identificar, de forma anónima o visitante.',
  cookiesPoliciesModalDatatableTypeAnalyticsF: 'Permitir a identificação anónima dos Utilizadores navegantes através da «Cookie» (identifica navegadores e dispositivos, não pessoas) e, portanto, a contabilização aproximada do número de visitantes e a sua tendência no tempo. Identificar, de forma anónima, os conteúdos mais visitados e mais atrativos para os Utilizadores. Saber se o Utilizador que está a aceder é novo ou visitante repetente. Salvo se o utilizador se decidir registar na CCOMMS, a «Cookie» nunca será associada a qualquer dado de caráter pessoal que o possa identificar diretamente. Ditas Cookies só serão utilizadas com propósitos estatísticos que ajudem à otimização e melhoria da experiência dos Utilizadores na página web.',
  cookiesPoliciesModalDatatableTypeAnalyticsT: 'Persistentes',
  cookiesPoliciesModalDatatableTypeSelfD: 'Este tipo de Cookies permite ampliar as informações mostradas a cada Utilizador anónimo nos Serviços da CCOMMS.',
  cookiesPoliciesModalDatatableTypeSelfF: 'Entre outros, armazena-se a duração ou frequência de visualização das diferentes secções, a interação com as mesmas, ou os padrões de navegação e/ou comportamentos do Utilizador.',
  cookiesPoliciesModalDatatableTypeSelfT: 'Persistentes',
  eventInfo: 'Informações do evento',
  manual: 'Manual',
  guia: 'Guia',
  supportLoggedPhonePrefferedMessage: '(Suporte de utilizador já com sessão iniciada - Preferencial Telefone) ',
  supportLoggedNotPhoneMessage: 'Não marca o telefone',
  supportLoggedUserMessage: '(Suporte de utilizador já com sessão iniciada) ',
  supportNotLoggedUserWebMessage: '(Login - Suporte via web) O utilizador solicita assistência para recuperar a palavra-passe',
  supportNotLoggedUserNotAvailableMessage: 'Não disponível',
  supportNotLoggedUserC2CMessage: '(Login - Suporte via C2C) O utilizador solicita assistência telefónica para recuperar a palavra-passe',
  processA4NotSupported: 'O tamanho do documento não deve ultrapassar o formato A4, 389 páginas ou 15mb',
  cookiesGAMessage: 'Medição interna com a Google Analytics. Estas cookies armazenam um identificador único para reconhecer o utilizador nas futuras ocasiões que visite a página web.',
  cookiesGAFGenericMessage: 'Medição interna com a Google Analytics.',
  cookiesCookieMessage: 'Cookie',
  cookiesNecessaryCookieDescription: 'Esta cookie é necessária e utiliza-se para estabelecer a preferência do utilizador relativamente à política de cookies.',
  documentHtmlRule: 'Tipo de arquivo aceito - HTML',
  rulesFilesSizePostal: 'O arquivo não deve pesar mais que 15Mb',
  rulesFilesSizeEmail: 'O arquivo não deve pesar mais que 14Mb',
  rulesFilesSizeMultiple: 'O tamanho máximo total do arquivo é 14Mb',
  rulesFilesSizeHtml: 'O arquivo não deve pesar mais que {fileSize}Mb',
  rulesFilesExtensionHtml: 'O arquivo deve ser um HTML',
  group_cannot_be_empty: 'O grupo :group_name: não pode estar vazio',
  language: 'Idioma',
  defaultLanguage: 'Idioma por defecto',
  transactionDownloadPee: 'Ver PEE',
  transactionTracking: 'Rastreamento remessa',
  documentOrder: 'Estabeleça a ordem dos documentos',
  yes: 'Sim',
  no: 'Não',
  notifications: 'Notificações',
  activateNotifications: 'Ativar',
  deactivateNotifications: 'Desativar',
  selectCategoryNotifications: 'Selecionar ação para notificações',
  activateCategoryNotifications: 'Ativar todas',
  deactivateCategoryNotifications: 'Desativar todas',
  customCategoryNotifications: 'Personalizar',
  enabledNotifications: 'Notificações ativadas',
  disabledNotifications: 'Notificações desativadas',
  customNotifications: 'Notificações personalizadas',
  selectNotifications: 'Selecionar definições de notificação',
  enabledNotificationsDescriptionText: 'Cada evento irá gerar uma notificação. Poderá escolher entre receber um e-mail para cada uma, ou um relatório diário com as notificações agrupadas.',
  disabledNotificationsDescriptionText: 'Não receberá quaisquer notificações sobre o estado dos envios realizados.',
  customNotificationsDescriptionText: 'Pode selecionar os processos dos quais deseja receber notificações sobre o seu estado, e os processos dos quais não deseja receber notificações.',
  enabledNotificationsConfigurationText: 'Configuração',
  enabledNotificationsConfigurationRealTime: 'Notificações em tempo real',
  enabledNotificationsConfigurationGrouped: 'Notificações agrupadas',
  selectEnabledNotifications: 'Selecionar tipo de notificações',
  enabledNotificationsRealTimeDescriptionText: 'Receba um e-mail instantâneo para cada evento.',
  enabledNotificationsGroupedDescriptionText: 'Receberá um e-mail no momento selecionado com o relatório de todos os eventos realizados durante o dia anterior.',
  enabledNotificationsScheduleRangeDescriptionText: 'Notificar durante o horário seguinte.',
  enabledNotificationsScheduleDescriptionText: 'Notificar apenas durante o horário seguinte.',
  chooseNotificationsRange: 'Escolha como e quando deseja receber notificações.',
  enabledNotificationsCustomText: 'Personalização das notificações',
  realTimeNotificationsDescription: 'As notificações são enviadas em tempo real. Por cada evento realizado, é enviado um e-mail a informar do seu estado.',
  languageAndTimezone: 'Língua',
  defaultTimeZone: 'Fuso horário predefinido',
  user_process_notifications_updated: 'Notificações atualizadas',
  changeSectionConfirmation: 'Alterações a guardar',
  changeSectionConfirmationText: 'Fez alterações que ainda não foram guardadas. Se quiser guardá-las agora, clique em',
  groupedNotificationsDescription: 'As notificações são enviadas através de um relatório anexo contendo uma lista de todas as transações e o último evento registado em cada uma, no horário selecionado.',
  groupedNotificationsReportTime: 'A que horas pretende receber o relatório',
  discard: 'Dispensar',
  cost_center_updated: 'Centro de custos atualizado com êxito',
  loginInfo: 'Informação de início de sessão',
  lastLoginSuccess: 'Último início de sessão',
  lastLoginError: 'A última tentativa de acesso falhou',
  noRecord: 'Sem registo',
  charLimitLength: 'O campo deve ter {limit} carateres ou menos',
  requiredLastName: 'Apelidos necessários',
  optional: 'Opcional',
  availableVariables: 'Variáveis disponíveis',
  twoFactorText: 'O utilizador necessita de autenticação de dois factores. Para iniciar sessão, introduza o código que será apresentado na sua aplicação de gestão de acesso multifator.',
  mfa_configuration_required: 'O utilizador necessita de autenticação de dois factores. Para a configurar, leia o QR na sua aplicação de gestão de acesso multifator. Depois, introduza o código na caixa inferior',
  code: 'Código',
  codeRequired: 'O código é necessário para iniciar sessão',
  mfa_code_invalid: 'O código não está correto',
  communicationContent: 'Conteúdo da comunicação',
  uploadFiles: 'Incluir documentos',
  modifyFiles: 'Alterar documentos',
  fileResume: '{nfiles} ficheiro(s) ({size}) MB no total',
  moreThan1City: 'O código postal corresponde a mais do que uma localização. Veja o menu pendente ou edite o campo manualmente.',
  shipmentDataSectionDescription: 'Nesta secção, é adicionado o conteúdo da comunicação. Os documentos devem estar em formato PDF e tamanho A4.',
  zipCodeFormat: 'Deve introduzir um código postal válido',
  deleteRecipient: 'Eliminar este destinatário',
  mobileAddRecipientDescription: 'Mais do que um destinatário?',
  errorFilesTotalSizeExceeded: 'O tamanho total dos ficheiros não pode exceder 15MB.',
  fileExtensionNotAccepted: 'A extensão do ficheiro é inválida. (As extensões aceites são= {acceptedExtensions})',
  nameAndSurnameLength: 'O nome e os apelidos não podem exceder 99 caracteres.',
  batchModeTitle: 'Envio por lotes',
  batchModeCSVStep: 'Destinatários',
  batchModeAttachmentsStep: 'Ficheiros necessários',
  batchModeDownloadSampleCSV: 'Descarregar ficheiro de exemplo',
  batchModeExtraCSVStepIntroduction: 'Primeiro deve carregar um ficheiro <strong>CSV</strong> ou <strong>XLS</strong> com os dados dos envios. A seguir pode descarregar um ficheiro de amostra que inclui os campos necessários.',
  batchModeExtraCSVStepIntroduction2: 'Depois de ter preparado o ficheiro, pode adicioná-lo clicando ou arrastando-o para a área prevista para o efeito. Lembre-se de que o ficheiro <strong>não pode conter linhas em branco ou erros</strong> em nenhum dos destinatários.',
  batchModeExtraCSVStepIntroduction3: 'Assim que a estrutura do ficheiro for validada, o botão <strong>Continuar</strong> será ativado e será possível continuar com o processo.',
  batchModeExtraFilesStepIntroduction: 'Nesta etapa, serão acrescentados os recursos necessários para enviar a cada destinatário.<br><br>Foi detetado um total de <b>{count}</b> ficheiros. Pode adicioná-los clicando ou arrastando-os para a área prevista para o efeito.<br><br>Lembre-se que o número total de ficheiros em cada envio não pode exceder <b>{fileSizeLimit} MB</b> e o tamanho máximo global do envio é de <b>{totalSizeLimit} GB</b>.',
  extraFilesTableHeaderFileName: 'Nome do ficheiro',
  extraFilesTableHeaderStatus: 'Estado',
  extraFilesTableStatusNotFound: 'Carregamento pendente',
  extraFilesTableStatusProcessing: 'A processar',
  extraFilesTableStatusUploaded: 'OK',
  extraFilesTableStatusUploadedCorrectly: 'Processado',
  extraFilesTableHeaderActions: 'Ações',
  batchModePreviewStep: 'Visualizar previmante e enviar',
  batchModeStep1RemoveFile: 'Eliminar ficheiro',
  batchModeCSVTableTitle: 'O ficheiro <strong>{filename}</strong> contém <strong>{nOfRecipients}</strong> destinatários.',
  batchModeCSVTableSubtitle: 'A seguir poderá encontrar uma amostra dos primeiros destinatários do ficheiro. Clique em Ver mais para mais informações.',
  batchModeCSVTableSubtitleNoData: 'O ficheiro selecionado não contém quaisquer destinatários.',
  batchModeNoDataTitle: 'Nenhum ficheiro selecionado. Assim que forem adicionados, terá os primeiros destinatários do ficheiro.',
  dropZoneText: 'Clique ou arraste os seus ficheiros aqui',
  dropZoneTextSingle: 'Clique ou arraste o seu ficheiro aqui',
  batchModeDialogTitle: 'Adicionar destinatários',
  batchModeDialogManualColTitle: 'Manualmente',
  batchModeDialogManualColDescription: 'Se pretender fazer um ou mais envios individualmente, escolha esta opção.',
  batchModeDialogBatchColTitle: 'Envio por lotes',
  batchModeDialogBatchColDescription: 'Se quiser enviar um ficheiro csv ou xls com vários destinatários e documentos de uma forma simples, selecione esta opção.',
  batchModeDialogBatchBtnManual: 'Envio individual',
  batchModeDialogBatchBtnBatch: 'Envio por lotes',
  batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> ficheiros para anexar',
  batchModeResourceTableAllFilesAdded: 'Todos os ficheiros necessários foram acrescentados',
  // batchModeResourceTableStatus: 'Estado actual= <b>$currentFiles$/</b>$nOfRequiredFiles$',
  batchModeResourceTableExpandText: 'Clique para obter mais detalhes',
  batchModeResourceTableFilenameText: 'Nome do ficheiro',
  batchModeResourceTableFoundOnNRows: 'destinatários afetados',
  batchModeResourceTableErrorFilenameInvalid: 'Um ou mais dos ficheiros que tentou acrescentar não correspondem aos ficheiros esperados.',
  batchModeResourceTableErrorFileSizeLimit: 'O tamanho do ficheiro é superior a {size}MB',
  batchModeResourceTableErrorTotalFileSizeLimit: 'O total de ficheiros carregados não pode exceder {size}GB.',
  batchModeResourceTableInfoFileReplaced: 'O ficheiro {filename}foi substituído.',
  seeMore: 'Ver mais',
  seeLess: 'Ver menos',
  pending: 'Pendente',
  batchModePreviewText: 'Assim que forem processados, poderá visualizar as transações na visualização correspondente e receberá um e-mail com as informações de cada envio, bem como o identificador da transação associado a cada destinatário.',
  batchModePreviewText1: 'No caso de alguma das entregas não poder ser efetuada, o e-mail especificará quais delas não foram processadas e a razão do erro.',
  csvError: 'O ficheiro não pode ser processado',
  csvErrorDetailStructure: 'A estrutura do ficheiro está errada.',
  csvErrorDetailIncorrectFields: 'Os campos não correspondem aos dados necessários do processo',
  csvErrorDetailIncorrectExtensions: 'Existem extensões de ficheiros inválidos nas linhas seguintes:',
  batchModeStep2SpaceLeft: 'Espaço usado',
  batchModeStep2PendingFiles: 'Ficheiros pendentes',
  launchDataKoBatchMode: 'Os dados fornecidos não são válidos',
  launchDataKoBatchModeStep1: 'Nenhum ficheiro foi selecionado',
  launchDataOkBatchModeStep1: 'A estrutura do ficheiro está correta, é possível continuar com o processo',
  launchDataKoBatchModeStep2: 'Acrescentar os ficheiros necessários',
  launchDataOkBatchModeStep2: 'Os ficheiros necessários foram adicionados e pode continuar com o processo',
  launchDataOkBatchMode: 'Os dados estão corretos, pode continuar com o processo',
  batchModeProcessLaunchedStep1: 'Iniciar o processo de registo',
  batchModeProcessLaunchedStep2: 'Carregamento dos recursos necessários para os envios',
  batchModeProcessLaunchedStep3: 'Carregamento do ficheiro de destinatário',
  batchModeProcessLaunchedStep4: 'Processo de registo concluído',
  transactionsProcessedBatchMode: 'Envio em lote registado com sucesso',
  transactionProcessingBatchMode: 'Processamento de envio por lotes...',
  transactionProcessingBatchModeError: 'Erro no processamento do envio por lotes',
  transactionProcessingBatchModeErrorDescription: 'Ocorreu um erro no processamento do lote. Por favor contacte o suporte.',
  csvErrorDialogDescription: 'Lembre-se que deve carregar um ficheiro <b>CSV</b> ou <b>XLS</b> e que o mesmo <strong>não pode conter linhas em branco ou erros</strong> em nenhum dos destinatários.',
  extraFileError: 'Não foi possível adicionar o ficheiro',
  extraFileErrorDialogDescription: 'Note que o limite de tamanho por transação é <b>{fileSizeLimit} MB</b> e o tamanho máximo de todo o lote é <b>{totalSizeLimit} GB</b>.',
  extraFileErrorTransactionSizeLimit: 'O tamanho máximo para o envio foi excedido para os seguintes registos= {rows}',
  htmlEditorInfoParagraph1: 'Nesta etapa, irá personalizar o conteúdo do e-mail que será enviado a cada um dos destinatários.',
  htmlEditorInfoParagraph3: 'Pode selecionar um modo de edição:',
  htmlEditorInfoBullet1: '<span>Seleção do modelo</span>. Selecionar um modelo e fazer os ajustes necessários a partir do editor.',
  htmlEditorInfoBullet2: '<span>HTML personalizado</span>. Carregar um ficheiro HTML personalizado e fazer os ajustes necessários a partir do editor.',
  htmlEditorButtonUploadFile: 'CARREGAR HTML',
  htmlEditorDragAndDrop: 'Clique aqui ou arraste os ficheiros anexos para o e-mail.',
  batchModeExtraCSVStepIntroductionVariables: 'Lembre-se de que as seguintes variáveis estão disponíveis para utilização nos campos de transação:',
  batchModeContactId: 'Referência do destinatário',
  batchModeContactName: 'Nome do destinatário',
  batchModeContactSurname: 'Apelidos do destinatário',
  htmlEditorTemplateSelected: 'Modelo selecionado',
  htmlSubjectError: 'O tema tem mais de <span>{subjectLimit}</span> caracteres',
  htmlEditorInfoParagraph4: 'Tenha em atenção que o tamanho máximo total do ficheiro é <b>{totalSizeLimit} Mb.</b>',
  backToProcess: 'Voltar aos processos',
  infoAndHelp: 'Informação e assistência',
  modelEmailHtmlTemplateSelector: 'Selecionar modelo.',
  modelEmailEmailBodyNotHtml: "O ficheiro selecionado não é html",
  templateSelectorDefaultTemplate: 'Predefinição',
  templateSelectorCustomTemplate: 'Personalizado',
  templateSelectorNoLogoTemplate: 'Sem logótipo',
  filesExcedingSizeLimit: 'Ficheiros que excedem o limite de tamanho',
  emailAttachmentsSectionTitle: 'Anexos do email',
  emailContent: 'Conteúdo do email',
  emailSubject: 'Assunto do email',
  multipleFileExtensionError: 'Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})',
  filePasswordProtectedOrDamage: "Arquivo inválido. Verifique se o arquivo não está danificado ou protegido por senha e tente novamente",
  backLostChangesHTMLText: "Se retroceder, perderá as alterações no modelo de email, assim como os ficheiros anexados. Tem a certeza de que deseja <b>retroceder</b> ao passo anterior?",
  backLostChangesHTMLConfirmBack: "Retroceder ao passo anterior",
  backLostChangesHTMLConfirmContinue: "Continuar com o envio",
  processStepperDigitalSignatureConfigurationTitle: 'Configuração',
  processStepperDigitalSignatureConfigurationDescription: 'Personalização da cerimônia de assinatura',
  processStepperDigitalSignatureSignersTitle: 'Signatários',
  processStepperDigitalSignatureSignersDescription: 'Configuração dos signatários e experiência de assinatura',
  processStepperDigitalSignatureDocumentsTitle: 'Documentos',
  processStepperDigitalSignatureDocumentsDescription: 'Seleção de documentos e interação dos signatários',
  processStepperDigitalSignatureSummaryTitle: 'Confirmação e Envio',
  processStepperDigitalSignatureSummaryDescription: 'Revisão e confirmação da cerimônia de assinatura',
  transactionName: 'Nome da Transação',
  transactionInterveners: 'Intervenientes da Transação',
  more: 'mais',
  finished: 'Finalizada',
  eventsInfo: 'Informações dos Eventos',
  transactionInvalidAlert: 'Durante a verificação dos dados de entrada da transação, foram detectados possíveis erros que impediriam a execução correta. A transação foi bloqueada preventivamente. Você deve iniciar uma nova transação com os dados de entrada corretos.',
  transactionEntryData: 'Dados de Entrada da Transação',
  goToSignature: 'Ir para Assinatura',
  intervenersNotDetected: 'Nenhum interveniente detectado',
  transactionEventsRegistered: 'Eventos registrados na transação',
  aboutEvents: 'Sobre os Eventos',
  notEvents: 'Nenhum evento foi registrado na transação.',
  notEventsRegistered: 'Nenhum evento foi registrado na transação. Se a transação foi criada recentemente, é possível que ainda esteja sendo gerada.',
  downloadCertificate: 'Baixar Certificado',
  notValid: 'Não Válida',
  transactionPending: 'Em Andamento',
  refresh: 'Atualizar',
  creation: 'Criação',
  transactionDigitalSignatureInfo: 'Informações da Transação de Assinatura Digital',
  reminders: 'Lembretes',
  input: {
    clear: 'Limpar'
  },
  intervenerStartCeremony: 'Para iniciar a vez de assinatura deste interveniente, uma nova aba será aberta. Lembre-se de que, se houver uma ordem de assinatura na transação, os intervenientes anteriores devem ter completado os documentos.',
  details: 'Detalhes',
  front_renderer_builder_transaction_opened: 'Transação de assinatura visitada',
  front_renderer_builder_transaction_finished: 'A transação de assinatura foi completada por todos os intervenientes',
  front_renderer_builder_intervener_signed: 'Um interveniente completou a assinatura dos documentos associados',
  front_renderer_builder_transaction_created: 'A transação de assinatura foi criada com sucesso',
  front_renderer_builder_transaction_invalid: 'A transação de assinatura contém erros e não pôde ser criada corretamente',
  front_renderer_builder_intervener_finished: 'Interveniente completou todas as assinaturas',
  certificate_builder_generated: 'O certificado de conclusão da transação foi gerado, o qual inclui o conteúdo da comunicação e as evidências associadas aos eventos ocorridos no processo.',
  transactionFilesParagraph: 'Lista de arquivos contidos na transação',
  transactionDigitalProcessed: 'Transação "{transactionName}" processada com sucesso',
  signatureType: 'Tipo de Assinatura',
  faceToFace: 'Presencial',
  remote: 'Remota',
  transactionWarning: 'Com Erros',
  cancelTransaction: 'Cancelar Transação',
  identifyTransactionPlataformCommunications: 'Identifique a transação na plataforma e nas comunicações',
  placeholderTransactionName: 'Nome da Transação',
  determineMethodAccessSigning: 'Determine o método de acesso à cerimônia de assinatura',
  transactionType: 'Tipo de Transação',
  descriptionRemoteSignature: '<p><b>A assinatura eletrônica remota</b> permite que os signatários acessem a cerimônia de assinatura de qualquer lugar e dispositivo por meio dos canais de comunicação disponíveis.</p>',
  descriptionPresentialSignature: '<p><b>A assinatura presencial</b> permite que os signatários acessem a partir do dispositivo usado para iniciar a transação.</p>',
  setSignatureReminders: 'Defina lembretes para acessar a cerimônia de assinatura',
  transactionReminder: 'Lembretes da Transação',
  transactionRedirectToTransactionDetail: 'Ver a transação',
  transaccion_cancelada: 'Transação cancelada',
  cancelled: 'Cancelada',
  digital_certificate: 'Cert.',
  cert: 'Cert.',
  nif: 'ID/NIF',
  sms_otp: 'SMS OTP',
  email_otp: 'Email OTP',
  video_id: 'Identificação por documento',
  ama: 'AMA Portugal',
  lastUpdate: 'Última Atualização',
  cancelTransactionAdvertisement: 'O cancelamento da transação não é reversível. Lembre-se de que o processo de cancelamento pode levar alguns minutos. Você tem certeza de que deseja cancelar a transação?',
  transaction_cancelled: 'Transação cancelada',
  transaction_not_cancelled: 'A transação não pôde ser cancelada',
  signed: 'Assinatura completada',
  addReminder: 'Adicionar Lembrete',
  sendingNewReminder: 'Adicione um lembrete, estabelecendo os dias que devem passar entre o primeiro envio ou o lembrete anterior',
  confirm: 'Confirmar',
  sinceCreation: 'desde a criação',
  sinceReminderNumber: 'desde o lembrete nº',
  reminderLimitReached: 'Você atingiu o limite de lembretes',
  reminderLimitReachedZero: 'Não é possível adicionar lembretes',
  customTextComplementSignatureAccessInformation: 'Texto informativo incluído nos emails de acesso à assinatura',
  globalMessageInterveners: 'Mensagem global aos intervenientes',
  exampleInformSignatory: 'Insira o conteúdo da mensagem',
  days: 'dias',
  infoReminder: '<p>Os lembretes da cerimônia de assinatura são comunicações enviadas aos signatários após a criação. O limite é determinado pela expiração da transação ou, se houver, pelos limites do próprio processo.</p>',
  infoReminderDays: '<p>Os dias indicados no primeiro lembrete serão contados <b>a partir da criação da transação</b>, enquanto os dias indicados nos lembretes seguintes serão contados a partir do envio do lembrete anterior.</p>',
  determineDaysSigningProcessRemainActive: 'Defina os dias que a cerimônia de assinatura permanecerá ativa',
  transactionExpiration: 'Expiração da Transação',
  orderOfSignature: 'Ordem de Assinatura',
  signatoriesAdded: 'adicionados à transação',
  infoTransactionWithoutSignatures: 'A transação ainda não tem signatários. Você pode começar a adicioná-los pelo botão inferior.',
  intervenersLimitReached: 'Você atingiu o limite de signatários',
  addSigner: 'Adicionar Signatário',
  signerEdition: 'Configuração de Signatário',
  nameSurname: 'Nome e sobrenome / Razão social',
  placeholderSignerName: 'João Exemplo Exemplo',
  identificationType: 'Tipo de Identificação',
  withoutIdentification: 'Sem identificação',
  securitySignature: 'Método de confirmação',
  shippingChannel: 'Canal de envio',
  completetionEmail: 'Email de conclusão',
  personalizedMessage: 'Mensagem personalizada do email inicial',
  numberDocument: 'Nº Documento',
  contactMethod: 'Método de contato',
  notIndicated: 'Não indicado',
  identificationSignature: 'Identificação/Assinatura',
  documents: 'Documento/s',
  infoTransactionWithoutDocuments: 'A transação ainda não tem documentos. Você pode começar a adicioná-los a partir do botão abaixo.',
  documentsLimitReached: 'Você atingiu o limite de documentos',
  addDocument: 'Adicionar documento',
  requirementsAddDocument: 'Você deve considerar que os documentos devem atender aos seguintes requisitos:',
  addDocumentError: 'O documento não pôde ser adicionado',
  documentNameRepeated: 'O nome do documento que você está tentando adicionar já existe na transação.',
  documentDetail: 'Detalhes do documento',
  documentActions: 'Ações',
  withoutActions: 'Sem ações',
  positionSignature: 'Posicionar assinaturas',
  deleteDocument: 'Excluir documento',
  size: 'Tamanho',
  totalFileSizeExceeded: 'O tamanho total dos arquivos enviados não pode exceder {size&Unit}',
  minCharacters: 'Mínimo {min} caracteres',
  maxCharacters: 'Máximo {max} caracteres',
  expirationDays: 'Deve ser maior que 0 e menor que {max} dias',
  expirationMustBeLongerThanReminders: 'A expiração deve ser maior que os lembretes',
  reminderRuleAvailableDaysInput: 'Deve ser maior que 0 e menor que {max} dias',
  remoteDigitalSignature: 'Assinatura eletrônica remota',
  presentialSignature: 'Assinatura presencial',
  none: 'Nenhuma',
  noneMasc: 'Nenhum',
  certificateDigital: 'Certificado digital',
  alertAbandonProcess: 'Se você sair do processo agora, perderá os dados inseridos. Você tem certeza de que deseja sair?',
  amaPT: 'Ama Portugal',
  graphometricSignature: 'Assinatura grafométrica',
  signature: 'Assinatura',
  documentSignatures: 'Assinaturas do documento',
  closeDocument: 'Fechar documento',
  selectSignersActions: 'Selecione um assinante para conhecer as ações disponíveis sobre o documento',
  sendSignedDocuments: 'Enviar documentos assinados',
  notSendSignedDocuments: 'Não enviar documentos assinados',
  documentLimitSizeAlert: 'O tamanho do documento não pode exceder 12 MB',
  documentFormatAlert: 'Os documentos devem estar no formato PDF e não podem estar criptografados ou corrompidos',
  documentLimitCountAlert: 'O limite de documentos para a transação é de ',
  signersAdded: 'Assinante/s',
  sendTransaction: 'Enviar cerimônia de assinatura',
  sendTransactionInfo: 'Na parte superior, como um resumo, você tem os dados inseridos durante a configuração. Uma vez revisados, você pode prosseguir com o envio clicando no botão abaixo',
  transactionOkMessage: 'A transação foi processada com sucesso; nos próximos minutos, você poderá ver os eventos relacionados a ela na visualização "Detalhes da transação". Você pode acessá-la clicando no botão abaixo',
  transactionsOkMessage: 'As transações foram processadas com sucesso; nos próximos minutos, elas estarão visíveis na visualização "Transações". Você pode acessá-la clicando no botão abaixo',
  goToPresentialCeremony: 'Iniciar cerimônia de assinatura presencial',
  interactionType: 'Tipo de interação',
  signDocuments: 'Assinar documentos',
  acceptDocuments: 'Aceitar documentos',
  requireCompleteReading: 'Revisão completa do documento',
  availableActions: 'Ações disponíveis',
  signerAcceptDocument: 'O assinante deve aceitar este documento',
  signaturesNotAvailable: 'As assinaturas não estão disponíveis se o assinante tiver a "Aceitação dos documentos" ativada',
  selectSigners: 'Selecionar assinantes',
  selectSignersDescription: 'Selecione os assinantes da transação que devem participar do documento',
  action: 'ação',
  singleClickSignatureInfoText: 'A aceitação do documento foi ativada, portanto será assinada com um único clique.',
  requireScrollToBottomInfoText: 'A revisão completa do documento está ativada',
  page: 'Página',
  interveners: 'Intervenientes',
  "face-to-face": 'Presencial',
  expiration: 'Expiração',
  signatureStatus: 'Estado de assinatura',
  remaining: 'Restante/s',
  completed: 'Completada',
  contact: 'Contato',
  events: 'evento/s',
  notSpecified: 'Não especificado',
  intervener: 'Interveniente',
  reference: 'Referência',
  expired: 'Expirada',
  documentViewerDialogSubtitle: 'Selecione os signatários que interagirão com o documento, bem como as ações a realizar',
  front_renderer_builder_intervener_identification_success: 'O interveniente foi identificado com sucesso na cerimônia de assinatura',
  front_renderer_builder_signature_otp_generated: 'O código de acesso à transação foi gerado',
  front_renderer_builder_signature_otp_sent: 'O código de acesso à transação foi enviado para o dispositivo do interveniente',
  front_renderer_builder_transaction_expired: 'A transação não é mais acessível, pois expirou',
  batches: 'Envios em lote',
  view_batches: 'Envios em lote',
  open: 'Abrir',
  selectProcess: 'Selecione um processo',
  transactionsSearchPlaceHolder: 'Pesquisa por ID da Transação ou interveniente',
  batchesDescription: 'Esta seção exibe os envios em lote realizados na plataforma',
  scheduleTime: 'Data de envio',
  schedule: 'Status/Envio',
  totalRecords: 'Registros',
  showTransactions: 'Ver transações',
  cancelBatch: 'Cancelar envios',
  cancelBatchConfirmText: 'Tem certeza de que deseja cancelar o envio e todas as transações associadas?',
  selectDateFilter: 'Filtro por data',
  stateFilterPlaceholder: 'Filtro de status',
  processFilterPlaceholder: 'Filtro por processo',
  batchSearchTooltip: 'Os campos disponíveis para pesquisa são',
  batchSearchTooltipFirstItem: 'ID do envio em lote',
  batchSearchTooltipSecondItem: 'Email do usuário remetente',
  batchSearchTooltipThirdItem: 'Nome do arquivo de envio',
  processed: 'Processado',
  batchCancelled: 'Cancelado',
  batchDetail: 'Detalhes do envio em lote',
  batchInfo: 'Informações do envio',
  batchInfoSectionIntroductionText: 'Se desejar, pode selecionar um nome identificativo que será utilizado em todo o processo. Caso este campo seja omitido, o nome do processo será utilizado.',
  batchNamePlaceholder: 'Nome padrão (nome do processo)',
  shipmentScheduling: 'Agendamento de envio',
  scheduleShipmentIntroduction: 'Pode agendar o envio para uma data e hora específicas. Se nenhuma data for selecionada, o envio será feito imediatamente.',
  shippingDate: 'Data de envio',
  time: 'Hora',
  selectDate: 'Data',
  selectTime: 'Hora',
  scheduledShipmentIncompleteDate: 'Deve selecionar uma data e hora para agendar o envio',
  scheduleShipment: 'Agendar envio',
  edit: 'Editar',
  digitalSignatureEmptyStepMessage: 'Não há nenhum campo disponível para editar, clique no botão <b>Continuar</b> para prosseguir.',
  shipmentScheduled: 'Envio agendado para',
  scheduled: 'Agendado',
  sentTransactions: 'Transações enviadas',
  sent: 'Enviadas',
  pendingTransactions: 'Transações agendadas',
  see: 'Ver',
  backToTransactions: 'Voltar para transações enviadas',
  shipmentDate: 'Data de envio',
  selectProcessFirst: 'Para visualizar os estados disponíveis, selecione primeiro um processo',
  scheduledShipment: 'Envio',
  scheduledPastShipment: 'Enviado',
  errors: 'Erros',
  checkEmailInfoErrors: 'Você pode verificar o e-mail com o resultado do processo para conhecer as transações com erro e os motivos.',
  nextMinutes: 'Nos próximos minutos'


}
