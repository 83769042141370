<template>
  <div class="date-time-picker">
    <div class="date-time-group">
      <v-menu
        v-model="menu"
        :close-on-content-click="true"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            v-model="formattedDate"
            :placeholder="$vuetify.lang.t('$vuetify.selectDate')"
            :value="formattedDate"
            background-color="white"
            class="date-input"
            dense
            height="36"
            outlined
            readonly
            single-line
            v-on="on"
          />
        </template>

        <v-date-picker
          v-model="internalDate"
          :allowed-dates="allowedDates"
          @change="updateDateTime"
        />
      </v-menu>
    </div>

    <div class="date-time-group">
      <v-select
        v-model="internalTime"
        :disabled="!internalDate"
        :items="filteredTimeIntervals"
        :placeholder="$vuetify.lang.t('$vuetify.selectTime')"
        class="time-input"
        dense
        height="36"
        outlined
        @change="updateDateTime"
      />
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

export default {
  name: "DateTimePicker",
  props: {
    modelValue: {
      type: Number,
      default: null,
    },
    daysLimit: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      menu: false,
      internalDate: null,
      internalTime: null,
      now: dayjs()
    };
  },

  computed: {
    timeIntervals() {
      const intervals = [];
      for (let i = 0; i < 24 * 60; i += 30) {
        const hours = Math.floor(i / 60);
        const minutes = i % 60;
        intervals.push(
          `${hours.toString().padStart(2, "0")}:${minutes
            .toString()
            .padStart(2, "0")}`
        );
      }
      return intervals;
    },

    filteredTimeIntervals() {
      if (!this.internalDate) return [];

      const selectedDate = dayjs(this.internalDate);

      if (selectedDate.isSame(this.now, "day")) {
        const cutoffTime = this.now.add(30, "minutes");
        return this.timeIntervals.filter((time) => {
          const [hours, minutes] = time.split(":");
          const intervalTime = dayjs(selectedDate)
            .hour(hours)
            .minute(minutes);
          return intervalTime.isAfter(cutoffTime);
        });
      }

      return this.timeIntervals;
    },

    formattedDate() {
      if (this.internalDate) {
        return dayjs(this.internalDate).format("DD/MM/YYYY");
      }

      return null;
    },
  },
  watch: {
    modelValue: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          const dateTime = dayjs(newValue * 1000);
          this.internalDate = dateTime.format("YYYY-MM-DD");
          this.internalTime = dateTime.format("HH:mm");
        }
      },
    },
  },
  methods: {
    allowedDates(date) {
      if (this.daysLimit != 0) {
        return dayjs(date).isSameOrAfter(this.now, "day") && dayjs(date).isBefore(this.now.add(this.daysLimit, "days"), "day");
      }
      return dayjs(date).isSameOrAfter(this.now, "day");
    },

    updateDateTime() {
      if (this.internalDate && this.internalTime) {
        const combinedDateTime = dayjs(
          `${this.internalDate} ${this.internalTime}`
        );
        const newTimestamp = Math.floor(combinedDateTime.valueOf() / 1000);
        this.$emit("changeTimestamp", newTimestamp);
      }
    },
  },
};
</script>

<style lang="scss">
.date-time-picker {
  display: flex;
  gap: 8px;
  align-items: flex-start;

  & .v-text-field__slot {
    cursor: pointer !important;
  }

  & .v-input__slot {
    margin: 0 !important;
  }
}

.date-time-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

div.v-picker__title {
  display: none;
}

.date-input, .time-input {
  width: 150px;
}

.time-input .v-input__append-inner {
  margin-top: 4px !important;
}
</style>
