<template>
  <v-data-table
    id="transactions-table-transactions_table"
    :footer-props="{'items-per-page-options':[5, 10, 15, 50]}"
    :headers="headers"
    :items="availableBatches"
    :options.sync="internalFrontOptions"
    :server-items-length="totalRecords"
    calculate-widths
    class="elevation-0 col-12 px-0 pt-0 mt-0 cts-finder-table"
    dense
    @update:options="handleOptionsUpdate"
  >
    <template v-slot:item="{ item }">
      <tr class="cts-datatable-row">
        <td>
          <v-list-item class="px-0">
            <v-list-item-avatar id="transactions-table-transaction_row_icon" class="cts-process-card-icon ma-1"
                                size="35" tile>
              <icon-base
                :icon-name="item.process_name" :src="item.image"
                height="28"
                icon-color="var(--darken)"
                width="28"
              />
            </v-list-item-avatar>
            <v-list-item-content class="pl-2">
              <span class="font-weight-bold d-block">
                {{item.batch_name || item.process_name}}
              </span>
              <span class="cts-color-tertiary text-uppercase">{{item.batch_id}}</span>
            </v-list-item-content>
          </v-list-item>
        </td>
        <td>
          <v-list-item class="px-0">
            <v-list-item-avatar
              id="transactions-table-transaction_row_icon"
              :title="availableStatuses.find(status => status.value === item.status) ? availableStatuses.find(status => status.value === item.status).text: item.status"
              class="cts-process-card-icon ma-0"
              size="35"
              tile>
              <v-icon :color="batchStatus(item.status).color">
                {{batchStatus(item.status).icon}}
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content class="pl-2">
              <span class="cts-color-tertiary font-weight-medium">
                {{batchStatus(item.status).text}}
              </span>
              <span class=" d-block">
                {{availableCancelBatch(item.schedule_time, item.status) ? $vuetify.lang.t('$vuetify.scheduledShipment') : $vuetify.lang.t('$vuetify.scheduledPastShipment')}}: {{dayjs.unix(item.schedule_time != 0 ? item.schedule_time : item.creation_timestamp).format(const_global.DATE_TIME_FORMAT_A)}}
                <span>({{dayjs.unix(item.schedule_time != 0 ? item.schedule_time : item.creation_timestamp).fromNow()}})</span>
              </span>
            </v-list-item-content>
          </v-list-item>
        </td>
        <td>
          <span class="font-weight-medium d-block">{{item.username}}</span>
          <span class="cts-color-tertiary">  {{item.group_name}}  </span>
        </td>
        <td>
          <span class="font-weight-medium">{{item.original_filename}}</span>
          <span class="cts-color-tertiary d-block">{{
              $vuetify.lang.t('$vuetify.totalRecords')
            }}: {{item.transaction_count}}
            <v-tooltip v-if="item.transaction_error_count > 0" bottom>
              <template v-slot:activator="{ on }">
                <span class="cts-color-primary-alternative d-inline-block"
                      v-on="on">
                  ({{$vuetify.lang.t('$vuetify.errors').toLowerCase()}}: {{item.transaction_error_count}} )
                </span>
              </template>
              <span>{{$vuetify.lang.t('$vuetify.checkEmailInfoErrors')}}</span>
            </v-tooltip>
          </span>
        </td>
        <td class="text-center">
          <v-btn
            v-if="item.schedule_time != null && availableCancelBatch(item.schedule_time,item.status)"
            id="batch-button-cancel_batch"
            :title="$vuetify.lang.t('$vuetify.cancelBatch')"
            class="cts-actions-button"
            text
            @click="$emit('cancelBatch',item.batch_id)">
            <v-icon left size="24px">
              cancel
            </v-icon>
          </v-btn>
          <v-btn
            id="batch-button-show_transactions"
            :disabled="item.status !== 'PROCESSED'"
            :title="$vuetify.lang.t('$vuetify.showTransactions')"
            class="cts-actions-button"
            text
            @click="showTransactionsWithBatchId(item.batch_id)">
            <v-icon left size="24px">
              mdi-text-search
            </v-icon>
          </v-btn>
        </td>
      </tr>
    </template>
  </v-data-table>
</template>

<script lang="js">
import IconBase from "@/assets/images/IconBase.vue";
import dayjs from "dayjs";
import const_global from "@/constants/global";
import {getObjectWithoutReactivity} from "@/util/utils";

export default {
  name: "BatchesDatatable",
  components: {IconBase},
  props: {
    availableBatches: Array,
    frontOptions: Object,
    totalRecords: Number,
    currentLocation: Object,
    availableStatuses: Array
  },
  data() {
    return {
      const_global,
      headers: [
        {
          text: this.$vuetify.lang.t('$vuetify.process'),
          value: 'batch_id',
          width: '30%',
          sortable: false
        }, {
          text: this.$vuetify.lang.t('$vuetify.schedule'),
          value: 'creation_timestamp',
          sortable: true
        }, {
          text: this.$vuetify.lang.t('$vuetify.user'),
          value: 'username',
          sortable: false
        }, {
          text: this.$vuetify.lang.t('$vuetify.file'),
          width: '20%',
          value: 'transaction_count',
          sortable: false
        },
        {align: ' d-none', value: 'process_name'},
        {align: ' d-none', value: 'batch_name'},
        {align: ' d-none', value: 'image'},
        {align: ' d-none', value: 'chedule_time'},
        {align: ' d-none', value: 'status'},
        {align: ' d-none', value: 'original_filename'},
        {align: ' d-none', value: 'transaction_error_count'},
        {align: ' d-none', value: 'group_name'},
        {
          text: this.$vuetify.lang.t('$vuetify.actions'),
          align: 'center',
          value: 'actions',
          sortable: false,
          custom: true,
          width: '100px'
        }
      ],
      internalFrontOptions: null
    }
  },
  created() {
    this.internalFrontOptions = getObjectWithoutReactivity(this.frontOptions)
  },
  methods: {
    batchStatus(status) {
      if (status.toString().toUpperCase() !== 'SCHEDULED' && status.toString().toUpperCase() !== 'CANCELLED' && status.toString().toUpperCase() !== 'PROCESSED') {
        status = 'SCHEDULED'
      }
      const statusObj = this.availableStatuses.find(s => s.value === status)

      return {
        color: statusObj.color,
        icon: statusObj.icon,
        text: statusObj.text
      }
    },
    handleOptionsUpdate() {
      this.$emit('updateOptions', this.internalFrontOptions)
    },

    availableCancelBatch(schedule_time, status) {
      const now = dayjs()
      const schedule = dayjs.unix(schedule_time)
      return schedule_time != 0 && status === 'SCHEDULED' && schedule.isAfter(now.add(5, 'minutes'))
    },
    showTransactionsWithBatchId(batch_id) {
      this.$router.push({name: 'transactions', query: {search: batch_id}})
    }
  }
}
</script>

<style lang="scss">

</style>