<template>
  <v-container class="cts-main-container cts-main-view col-12 pa-0">
    <v-col class="ma-0 pa-0 " cols="12">
      <v-row class="ma-0" dense>
        <v-col class=" mx-auto cts-view-header" cols="11" lg="10" md="11" sm="11" xl="10">
          <v-row dense>
            <v-col cols="12">
              <view-title
                :icon="const_icons.BATCHES"
                :subtitle="$vuetify.lang.t('$vuetify.batchesDescription')"
                :title="$vuetify.lang.t('$vuetify.batches')"
                idName="transactions"
              ></view-title>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0 justify-center" dense>
        <v-col class="pa-0" cols="11" lg="10" md="11" xl="10">
          <v-row class="py-2 datatable-filter-bar" dense>
            <v-text-field
              id="batches-input-search"
              v-model="search"
              :aria-label="$vuetify.lang.t('$vuetify.fastSearch')"
              :placeholder="$vuetify.lang.t('$vuetify.fastSearch')"
              :prepend-inner-icon="const_icons.SEARCH"
              aria-autocomplete="none"
              class="ma-0 cts-filter-text"
              clearable
              dense
              hide-details
              single-line
              solo
              @blur="getBatches(null)"
              @keydown.enter="getBatches(null)"
              @click:clear="clearSearch"
            ></v-text-field>
            <DateFilter :model-value="filters.date" @change="changeFilterDate"/>
            <v-select
              id="batches-input-status"
              v-model="filters.status"
              :aria-label="$vuetify.lang.t('$vuetify.stateFilterPlaceholder')"
              :items="availableStatuses"
              :placeholder="$vuetify.lang.t('$vuetify.stateFilterPlaceholder')"
              :prepend-inner-icon="const_icons.STATUS"
              class="ma-0 cts-filter-text cts-small-filter"
              clearable
              dense
              hide-details
              item-text="text"
              item-value="value"
              single-line
              solo
              @change="getBatches(null)"
              @click:clear="getBatches(null)"
            ></v-select>
            <v-autocomplete
              id="batches-input-process"
              v-model="filters.process_code"
              :aria-label="$vuetify.lang.t('$vuetify.processFilterPlaceholder')"
              :items="availableProcesses"
              :placeholder="$vuetify.lang.t('$vuetify.processFilterPlaceholder')"
              :prepend-inner-icon="const_icons.PROCESSES"
              class="ma-0 cts-filter-text cts-medium-filter "
              clearable
              dense
              hide-details
              item-text="name"
              item-value="code"
              single-line
              solo
              @change="getBatches(null)"
              @click:clear="getBatches(null)"
            ></v-autocomplete>
            <v-col class="text-right align-content-center px-2">
              <v-btn
                :loading="loading"
                class="cts-font-size-5 cts-bgcolor-primary-alternative cts-color-clear"
                right
                @click="getBatches(null,true)"
              >
                <v-icon size="18">
                  refresh
                </v-icon>
                {{$vuetify.lang.t('$vuetify.refresh')}}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="ma-0" dense justify="center">
        <v-col v-if="availableBatches" class="pa-0" cols="11" lg="10" md="11" xl="10" xs="11">
          <BatchesDatatable
            :available-statuses="availableStatuses"
            :availableBatches="availableBatches"
            :current-location="currentLocation"
            :frontOptions="frontOptions"
            :totalRecords="totalRecords"
            @cancelBatch="cancelBatch"
            @updateOptions="updateDataTableOptions"
          ></BatchesDatatable>
        </v-col>
      </v-row>
    </v-col>
    <ConfirmDialog ref="confirm_action"/>
  </v-container>
</template>
<script>
import ViewTitle from "@/components/structures/viewTitle.vue";
import const_icons from "@/constants/icons";
import const_global from "@/constants/global";
import {find} from "@/services/finderService"
import ConfirmDialog from "@/components/structures/ConfirmDialog.vue";
import BatchesDatatable from "@/components/sections/batches/components/BatchesDataTable.vue";
import {mapGetters} from "vuex";
import {GETTERS as LOCATION_GETTERS, NAMESPACE as LOCATION_NAMESPACE} from "@/constants/vuex/location";
import DateFilter from "@/components/structures/datatables/filters/DateFilter.vue";
import {cancelBatch} from "@/services/batchModeServices";
import {getAvailableProcesses} from "@/services/groupServices";
import dayjs from "dayjs";
import {updateQueryParams} from "@/util/utils";


export default {
  name: "Batches",
  components: {
    DateFilter,
    BatchesDatatable,
    ConfirmDialog,
    ViewTitle
  },
  data() {
    return {
      const_global,
      const_icons,
      availableStatuses: [
        {
          text: this.$vuetify.lang.t('$vuetify.processed'),
          value: 'PROCESSED',
          icon: 'mdi-check-circle-outline',
          color: 'var(--success)'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.batchCancelled'),
          value: 'CANCELLED',
          icon: 'mdi-close-circle-outline',
          color: 'var(--error)'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.pending'),
          value: 'SCHEDULED',
          icon: 'mdi-clock-time-four-outline',
          color: 'var(--warning)'
        },
      ],
      availableProcesses: [],
      search: '',
      fields: [
        "batch_id",
        "schedule_time",
        "username",
        "transaction_count",
        "transaction_error_count",
        "process_name",
        "process_code",
        "batch_name",
        "image",
        "creation_timestamp",
        "status",
        "original_filename",
        "group_name"
      ],
      filters: {
        date: [],
        status: null,
        process_code: null
      },
      backFilters: null,
      availableBatches: [],
      frontOptions: {
        page: 1,
        itemsPerPage: 10,
        sortBy: ["creation_timestamp"],
        sortDesc: [
          true
        ],
        groupBy: [],
        groupDesc: [],
        mustSort: false,
        multiSort: false
      },
      totalRecords: 0,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      currentLocation: `${LOCATION_NAMESPACE}/${LOCATION_GETTERS.G_CURRENT_LOCATION}`,
    }),
  },
  created() {
    this.fillProcesses()
    this.initializeFiltersFromRoute()
  },
  methods: {
    initializeFiltersFromRoute() {
      const query = this.$route.query;
      this.filters.date = query.date ? query.date.split(",") : [];
      this.filters.status = query.status || null;
      this.filters.process_code = query.process_code || null;
      this.search = query.search || "";
      this.frontOptions.page = query.page ? parseInt(query.page) : 1;
      this.frontOptions.itemsPerPage = query.rpp ? parseInt(query.rpp) : 10;
      this.frontOptions.sortBy = query.sort ? [query.sort] : ["creation_timestamp"];
      this.frontOptions.sortDesc = query.sortDesc ? [query.sortDesc === "true"] : [true];
    },
    updateRouteWithFilters(filters) {
      const query = {
        ...this.$route.query,
        date: filters.date && filters.date.length === 2 ? filters.date.join(",") : undefined,
        status: filters.status || undefined,
        process_code: filters.process_code || undefined,
        search: this.search ? this.search : undefined,
        page: this.frontOptions.page,
        rpp: this.frontOptions.itemsPerPage,
        sort: this.frontOptions.sortBy[0],
        sortDesc: this.frontOptions.sortDesc[0] ? "true" : "false"
      };
      updateQueryParams(query)
    },
    changeFilterDate(dates) {
      this.filters.date = dates
      this.getBatches(null, false)
    },
    async fillProcesses() {
      this.availableProcesses = await getAvailableProcesses().then(response => {
        return response.data.map(process => {
          return {code: process.code, name: process.name}
        }).sort((a, b) => a.name.localeCompare(b.name))
      })
    },
    async updateDataTableOptions(options) {
      this.frontOptions = options
      await this.getBatches(options)
    },
    async getBatches(newFrontOptions, refresh = false) {
      this.loading = true
      if (newFrontOptions) {
        this.frontOptions = newFrontOptions
      } else {
        this.frontOptions.page = 1
      }
      const backOptions = {
        rpp: this.frontOptions.itemsPerPage,
        page: this.frontOptions.page,
        order: {
          field: this.frontOptions.sortBy[0],
          direction: this.frontOptions.sortDesc.length === 0 ? 'ASC' : this.frontOptions.sortDesc[0] ? 'DESC' : 'ASC'
        }
      }
      const newBackFilters = {
        status: this.filters.status || undefined,
        process_code: this.filters.process_code || undefined,
        date: this.filters.date || undefined
      };
      if (this.search) {
        const searchFields = {};
        this.fields.forEach((field_key) => {
          searchFields[`${field_key}[~]`] = this.search;
        });
        newBackFilters.OR = searchFields;
      } else {
        delete newBackFilters.OR;
      }
      if (refresh || newFrontOptions || JSON.stringify(newBackFilters) !== JSON.stringify(this.backFilters)) {
        this.backFilters = newBackFilters
        this.updateRouteWithFilters(this.backFilters);

        if (this.filters.date && this.filters.date.length === 2) {
          newBackFilters['schedule_time[<>]'] = [
            dayjs(this.filters.date[0]).startOf('day').unix(),
            dayjs(this.filters.date[1]).endOf('day').unix()
          ]
          delete newBackFilters.date
        } else {
          delete newBackFilters['schedule_time[<>]']
          delete newBackFilters.date
        }

        find('/batch_upload', this.fields, newBackFilters, backOptions).then(data => {
          this.availableBatches = data.extra.results
          this.totalRecords = data.extra.count
          this.loading = false
        })

      }

    },

    clearSearch() {
      this.search = ''
      this.getBatches()
    },
    async cancelBatch(batch_id) {
      if (
        await this.$refs.confirm_action.open(
          null,
          this.$vuetify.lang.t('$vuetify.cancelBatchConfirmText'),
          {
            width: "600px",
            cancelText: this.$vuetify.lang.t('$vuetify.back'),
            confirmText: this.$vuetify.lang.t('$vuetify.cancelBatch')
          }
        )
      ) {
        cancelBatch(batch_id).then(() => {
          this.getBatches(null, true)
        })

      }
    },
  }
}
</script>

<style lang="scss">


</style>