export default {
  badge: 'Placa',
  close: 'Tanca',
  dataIterator: {
    noResultsText: 'Cap element coincideix amb la cerca',
    loadingText: 'Carregant...'
  },
  dataTable: {
    itemsPerPageText: 'Files per pàgina:',
    ariaLabel: {
      sortDescending: ': Ordre descendent. Premeu per treure ordre.',
      sortAscending: ': Ordre ascendent Premeu per establir ordre descendent.',
      sortNone: ': Sense ordenar. Premeu per establir ordre ascendent.',
      activateAscending: 'Activa ascens',
      activateDescending: 'Activa descens',
      activateNone: 'Desactiva-ho tot'
    },
    sortBy: 'Ordenat per'
  },
  dataFooter: {
    itemsPerPageText: 'Elements per pàgina:',
    itemsPerPageAll: 'Tots',
    nextPage: 'Pàgina següent',
    prevPage: 'Pàgina anterior',
    firstPage: 'Primer pàgina',
    lastPage: 'Última pàgina',
    pageText: '{0}-{1} de {2}'
  },
  datePicker: {
    itemsSelected: '{0} seleccionats',
    prevMonthAriaLabel: 'mes anterior',
    nextMonthAriaLabel: 'mes següent'
  },
  noDataText: 'No hi ha dades disponibles',
  carousel: {
    prev: 'Visual anterior',
    next: 'Visual següent',
    ariaLabel: {
      delimiter: 'Carousel slide {0} of {1}'
    }
  },
  calendar: {
    moreEvents: '{0} més'
  },
  fileInput: {
    counter: '{0} arxius',
    counterSize: '{0} arxius ({1} en total)'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  home: 'Inici',
  templates: 'Fluxos',
  accounts: 'Comptes',
  processes: 'Processos',
  transactions: 'Transaccions',
  statistics: 'Estadístiques',
  support: 'Suport',
  contacts: 'Contactes',
  administration: 'Administració',
  search: 'Cerca',
  appearance: 'Estils',
  users: 'Usuaris',
  alastria: 'Alastria',
  billing: 'Facturació',
  account: 'Gestiona el meu compte',
  admin: 'Administració',
  demos: 'Demos',
  selectedSubaccount: 'Subcompte seleccionat',
  withoutSubaccount: 'Sense subcomptes',
  groups: 'grups',
  filterDate: 'Filtre de data',
  subaccount: 'Subcompte',
  subaccounts: 'Subcomptes',
  costCenters: 'Centre de cost',
  subaccountsAssigned: 'Subcomptes assignats',
  defaultCostCenter: 'Centre de cost per defecte',
  selectedCostCenter: 'Centre de cost seleccionat',
  availableCostCenters: 'Centres de cost disponibles',
  changeDefaultCostCenter: 'Canvia centre de cost per defecte',
  costCenterDialogText: 'Si ho desitgeu, podeu canviar el centre de cost seleccionat per a la transacció. Això no modificarà el centre de cost per defecte; només s\'utilitzarà de forma puntual per a aquesta transacció',
  costCenterDialogTextAccount: 'Si ho desitgeu, podeu canviar el centre de cost per defecte. Aquesta acció establirà el centre de cost seleccionat com a predeterminat',
  costCenterSelectedTitle: 'Centre de cost seleccionat',
  activeSubaccount: 'Subcompte actiu',
  user: 'Usuari',
  userData: 'Dades de l\'usuari',
  userCECOS: 'Centres de cost',
  userCECO: 'Centre de cost',
  userCodeCECO: 'Codi',
  userCECOPrimary: 'Per defecte',
  userCECOCodeTitle: 'Codi de centre de cost',
  password: 'Contrasenya',
  passwordchange: 'Canvia contrasenya',
  oldpassword: 'Contrasenya actual',
  newpassword: 'Nova contrasenya',
  updatePassword: 'Actualitza contrasenya',
  repeatpassword: 'Repeteix contrasenya',
  passwordrequirements: 'La contrasenya ha de tenir almenys 8 caràcters i complir com a mínim 3 de les regles addicionals:',
  passwordMinOneLowercase: 'Mínim 1 lletra minúscula.',
  passwordMinOneUppercase: 'Mínim 1 lletra majúscula.',
  passwordMinOneNumber: 'Mínim 1 número.',
  passwordMinOneSpecialChar: 'Mínim 1 caràcter especial.',
  passwordMinEightChars: 'Mínim 8 caràcters.',
  passwordCompares: 'Comparació de contrasenya requerida.',
  passwordFieldError: 'Error en el camp. Consulteu regles.',
  passwordrequirementsMoreThanEightChars: 'La contrasenya ha de tenir almenys 8 caràcters',
  notequalpasswords: 'Les contrasenyes no coincideixen',
  samepasswordthanolder: 'La nova contrasenya és igual que l\'anterior',
  emptyOldPassword: 'No heu introduït la contrasenya anterior',
  role: 'Rol',
  lastConnection: 'Última connexió',
  languageSelector: 'Seleccioneu un idioma',
  logout: 'Tanca sessió',
  others: 'Altres',
  continue: 'Continua',
  continueWSend: 'Continua enviament',
  sendRecoveryForm: 'Envia sol·licitud',
  restartFilters: 'Reinicia filtres',
  back: 'Enrere',
  backToMethods: 'Mètodes de recuperació',
  moreInfo: 'Més informació',
  activateuser: 'Activa usuari',
  exit: 'Surt',
  platformVersion: 'Versió de la plataforma',
  termsAndConditionsCompliance: 'Termes i Condicions',
  privacyPoliciesCompliance: 'Política de Protecció de Dades',
  cookiesPoliciesCompliance: 'Política de Galetes',
  cookiesPoliciesCertySign: 'Galetes a CertySign',
  alertSizeFiles: 'Tots els arxius han de pesar com a màxim 8 Mb',
  login: 'Inicia sessió',
  noSubaccountsMessage: 'No disposeu de subcomptes assignats',
  telephone: 'Telèfon',
  name: 'Nom',
  lastName: 'Cognoms',
  email: 'Correu electrònic',
  omit: 'Omet',
  id: 'Referència de client',
  clear: 'Esborra',
  add: 'Afegeix',
  recipients: 'Destinataris',
  shipmentData: 'Dades de l\'enviament',
  prevAndSend: 'Previsualització i enviament',
  previewFileSize: 'L\'arxiu ha de pesar com a màxim 15 Mb',
  rulesFilesSize: 'Tots els arxius han de pesar com a màxim 15 Mb',
  sectionDataFieldsDescription: 'A la part inferior disposeu dels diferents camps necessaris per a l\'enviament de la transacció als destinataris. Els camps de contacte s\'ompliran automàticament des de les dades del destinatari',
  sectionDataFieldsNotAditionalFields: 'Aquest procés no disposa de camps addicionals. Finalitzeu l\'enviament de la vostra transacció des de Previsualització.',
  sectionPreviewSubtitle: 'A la part inferior podeu navegar entre els destinataris per comprovar individualment cada enviament',
  manual_version: 'Mostrant l\'última versió del manual d\'usuari.',
  view_login: 'Inicia sessió',
  view_transactions: 'Transaccions',
  view_transaction: 'Detalls de la transacció',
  view_process: 'Executa procés',
  view_processes: 'Processos',
  view_statistics: 'Estadístiques',
  view_support: 'Ajuda i suport',
  view_administration: 'Administració',
  view_groups: 'Grups',
  view_group: 'Detalls de grup',
  view_users: 'Usuaris',
  view_account: 'El meu compte',
  view_remember_password: 'Recorda contrasenya',
  view_change_password: 'Canvia contrasenya',
  view_activate_user: 'Activa usuari',
  view_new_password: 'Nova contrasenya',
  view_contacts: 'Contactes',
  view_user: 'Detalls d\'usuari',
  view_withoutsubaccounts: '',
  view_forbidden: '',
  view_root: '',
  view_error500: 'Error',
  searchAgain: 'Torna a cercar',
  recipient: 'Destinatari',
  sender: 'Remitent',
  namesender: 'Nom del remitent',
  subject: 'Assumpte',
  emailbody: 'Cos del correu electrònic',
  resourcespreview: 'Previsualització de recursos',
  attach: 'Adjunta',
  actions: 'Accions',
  changePassword: 'Actualitza contrasenya',
  sessionClosed: 'Sessió tancada correctament',
  noPermissionMessage: 'No teniu permisos per accedir a aquesta secció',
  download: 'Descarrega',
  viewDocument: 'Visualitza document',
  accept: 'Accepta',
  reject: 'Rebutja',
  change: 'Canvia',
  addVariables: 'Afegeix variable',
  resources: 'Recursos',
  launchDataKo: 'Les dades proporcionades no són vàlides per realitzar l\'enviament',
  launchDataOk: 'Les dades són correctes. Podeu procedir a l\'enviament',
  addNewRecipient: 'Afegeix nou destinatari',
  transactionRecipients: 'Destinataris de la transacció',
  noRecipientsSelected: 'Cap destinatari seleccionat',
  recipientsSelected: 'S\'han seleccionat',
  recipientSelected: 'S\'ha seleccionat',
  preview: 'Previsualització',
  summary: 'Resum',
  transaction_summary: 'Resum de l\'enviament',
  PDFpreview: 'Previsualitza PDF',
  previewing: 'Previsualitzant: ',
  notAvailable: 'No disponible',
  hide: 'Oculta',
  send: 'Envia',
  showFile: 'Visualitza fitxer',
  error500Message: 'S\'ha produït un error, Si el problema persisteix, podeu posar-vos en contacte amb suport a través de',
  thisForm: 'este formulario',
  error401Message: 'La sessió ha caducat',
  withoutData: 'Sense dades',
  notInformed: 'No informat',
  loginTitleText: 'Inicia sessió',
  changePasswordTitleText: 'Canvi de contrasenya',
  activateUserTitleText: 'Activació d\'usuari',
  homeWelcomeMessageTitle: 'Benvingut a CertySign',
  homeWelcomeMessageSubtitle: 'Entorn de gestió de transaccions i fluxos d\'entrega certificada',
  changePasswordUserTitleText: 'Estableix nova contrasenya',
  activateUserSubtitleText: 'Per a l\'activació de l\'usuari cal introduir una nova contrasenya',
  changePasswordUserSubtitleText: 'Per establir una nova contrasenya, ompliu els camps',
  activateUserTitleTextWithoutHash: 'URL no vàlida',
  activateUserSubtitleTextWithoutHash: 'La URL que esteu utilitzant no és vàlida. Si ho desitgeu, podeu sol·licitar una nova URL a través de l\'opció "He oblidat la contrasenya" de la pantalla d\'inici de sessió.',
  goToLogin: 'Inici de sessió',
  activeFilters: 'Filtres actius',
  rememberPassword: 'Heu oblidat la vostra contrasenya?',
  rememberPasswordTitleText: 'Recupera contrasenya',
  supportContact: 'Contacte amb suport',
  rememberPasswordSubTitleText: 'Seleccioneu un mètode per recuperar la contrasenya',
  rememberPasswordEmailResponse: 'Si l\'usuari proporcionat es troba donat d\'alta, rebreu un correu electrònic amb les instruccions per al canvi de contrasenya',
  rememberPasswordWebResponse: 'Si les dades introduïdes són correctes, el nostre equip de suport us enviarà un correu electrònic i us proporcionarà les instruccions per al canvi de contrasenya',
  rememberPasswordPhoneResponse: 'Si les dades introduïdes són correctes, el nostre equip de suport us trucarà per telèfon i us proporcionarà les instruccions per al canvi de contrasenya',
  expiredPasswordResponse: 'La vostra contrasenya ha caducat. Aviat rebreu un correu electrònic amb les instruccions per establir una nova contrasenya',
  rememberPasswordSupportEmail: 'Correu electrònic de recuperació',
  rememberPasswordSupportEmailSubtitle: 'Us enviarem un correu electrònic des del qual podreu recuperar la vostra contrasenya',
  rememberPasswordSupportEmailText: 'Si us plau, ompliu el formulari amb el vostre correu electrònic registrat a CertySign',
  rememberPasswordSupportWeb: 'Suport via web',
  rememberPasswordSupportWebSubtitle: 'Envia un correu electrònic a l\'equip de suport tècnic de CertySign',
  rememberPasswordSupportWebText: 'Introduïu les dades i l\'equip de suport tècnic us respondrà al més aviat possible.',
  emailSupportServiceHours: 'Horaris d\'atenció:',
  rememberPasswordSupportPhone: 'Suport telefònic',
  rememberPasswordSupportPhoneSubtitle: 'Trucada telefònica a l\'equip de suport tècnic de CertySign',
  rememberPasswordSupportPhoneText: 'Introduïu les dades i l\'equip de suport tècnic us trucarà al més aviat possible.',
  phoneSupportServiceHours: 'Horari de suport telefònic:',
  rememberPasswordNotSupportBySchedule: 'Opció deshabilitada perquè esteu fora d\'horari',
  rememberPasswordMondayToFriday: 'Dilluns a divendres:',
  rememberPasswordSaturdaySundayAndOthers: 'Dissabtes, diumenges i festius:',
  rememberPasswordNotIncludedDays: '(festius no inclosos)',
  supportFinalSendMessage: 'La vostra sol·licitud s\'ha enviat al nostre departament de Suport',
  goBackToMethods: 'Selecciona un altre mètode',
  rememberPasswordContactName: 'Nom i cognoms',
  goToC2C: 'Sol·licita trucada',
  messageDescription: 'Missatge',
  company: 'Empresa',
  legalCheckboxText: 'Accepto les Condicions d\'ús de la pàgina i la política de protecció de dades',
  selectHelps: 'Com us podem ajudar?',
  selectHelpsTooltip: 'Ajuda i suport',
  helpManual: 'Visualitza manual',
  helpGuide: 'Visualitza guia ràpida',
  helpSupport: 'Contacta amb suport',
  helpSupportPhonePreffered: 'Contacta preferentment per telèfon',
  helpSupportPhoneSchedule: 'Horari d\'atenció telefònica, laborables',
  requiredEmail: 'Correu electrònic requerit',
  requiredPhone: 'Telèfon requerit',
  requiredName: 'Correu electrònic requerit',
  requiredEmailFormat: 'Format de correu electrònic incorrecte',
  requiredPhoneFormat: 'Format de telèfon incorrecte',
  requiredTextarea: 'Missatge requerit',
  tag_signature: 'Signatura electrònica',
  tag_email: 'Correu electrònic',
  tag_sms: 'SMS',
  tag_cert_email: 'Correu electrònic certificat',
  tag_landing: 'Pàgina web',
  tag_biometric_signature: 'Signatura biomètrica',
  tag_postal: 'Postal',
  processesViewListTitle: 'Processos',
  processesViewListDescription: 'Llista amb tots els processos disponibles a CertySign',
  tags: 'Etiquetes',
  searchs: 'Cerca',
  processInfo: 'Informació del procés',
  processesCategoriesFilter: 'Filtre per categories',
  processesTagFilter: 'Filtre per etiquetes',
  processesTagOthers: 'altres',
  processesSearch: 'Cerca processos',
  searchTransactions: 'Consulta transaccions',
  searchTransaction: 'Consulta transacció',
  deleteFilters: 'Esborra filtres',
  withoutResults: 'Sense resultats',
  withoutProcessResults: 'No disposeu de processos per a la seva execució segons els vostres permisos o els criteris de cerca establerts',
  allProcesses: 'Tots els processos',
  emailNotValid: 'El correu electrònic no és vàlid',
  phoneNotValid: 'El telèfon no és vàlid',
  phoneAtLeastNineChars: 'El telèfon ha de tenir almenys 9 números',
  doesntExist: 'No existeix',
  dontExist: 'No existeixen',
  requiredField: 'Camp requerit',
  errorField: 'Camp erroni',
  numberField: 'Camp numèric',
  fiveCharsField: 'Camp ha de tenir 5 caracters',
  CPvalidField: 'CP no correspon amb província',
  delete: 'Elimina',
  save: 'Desa',
  file: 'Fitxer',
  loading: 'Carregant',
  countrySelector: 'Seleccioneu un país',
  provinceSelector: 'Seleccioneu una província',
  andNumberMore: 'i {count} més',
  selectFile: 'Seleccioneu un fitxer',
  selectFileHtml: 'Seleccioneu un fitxer html',
  selectFiles: 'Seleccioneu fitxers',
  allTransactions: 'Totes les transaccions',
  transactionsPerPage: 'Transaccions per pàgina',
  transactionsSend: 'S\'han enviat',
  transactionslower: 'transaccions',
  transactionWithoutId: 'Assignació d\'ID pendent',
  withoutCertificated: 'Certificat no disponible',
  filter: 'Filtra',
  transactionsProcessFilter: 'Filtre per procés',
  processDataPreviewText: 'A continuació podeu trobar el resum de la nova transacció, que inclou el número d\'enviaments necessaris i el centre de cost associat',
  processDataPreviewTextWithoutCC: 'A continuació podeu trobar el resum de la nova transacció, que indica el número d\'enviaments necessari',
  transactionsLinkedGroupsFilter: 'Filtre per grup',
  transactionsDateFilter: 'Filtre per data',
  numberOfTransactions: 'Número d\'enviaments',
  associatedCECO: 'Centre de cost associat',
  transactionsCategoryFilter: 'Filtre per categoria',
  transactionsStatusFilter: 'Filtre per estat',
  transactionState: 'Estat de la transacció',
  transactionSummary: 'Resum de la transacció',
  transactionGeneralData: 'Informació de la transacció',
  transactionData: 'Dades de la transacció',
  files: 'Fitxers',
  transactionContact: 'Dades del contacte',
  transaction: 'Transacció',
  transactionEvents: 'Esdeveniments certificables de la transacció',
  transactionWithoutEvents: 'No hi ha esdeveniments per a la transacció',
  transactionProgress: 'Progrés de la transacció',
  transactionSubtitle: 'Informació de l\'estat de la transacció',
  transactionDetails: 'Detalls de la transacció',
  transactionEventInfo: 'Informació de l\'esdeveniment',
  eventMeaningPrepend: 'Què significa l\'esdeveniment ',
  eventMeaningAppend: '?',
  eventMeaning: 'Què significa aquest esdeveniment?',
  transactionLaunchedDate: 'Data de creació',
  transactionLastEvent: 'Últim esdeveniment rebut',
  transactionLastEventDate: 'Data de l\'últim esdeveniment',
  transactionContactName: 'Nom i cognoms',
  transactionContactMobilephone: 'Telèfon mòbil',
  transactionContactEmail: 'Correu electrònic',
  transactionContactID: 'ID',
  advancedFilters: 'Filtres avançats',
  group: 'Grup',
  date: 'Data',
  hideFilters: 'Oculta filtres',
  processName: 'Nom del procés',
  usernameTransactionDetail: 'Usuari remitent',
  recipientsNumber: 'Número de destinataris',
  invalidTransaction: 'Dades invàlides',
  dataTableShowRecipients: 'Visualitzant enviament <span class=\'font-weight-medium\'>:page:</span> de <span class=\'font-weight-medium\'>{total}</span>',
  transactionRedirectToProcesses: 'Ves a processos',
  transactionRedirectToTransactions: 'Ves a transaccions',
  transactionRedirectToNewSend: 'Nou enviament',
  transactionProcessed: 'Transacció processada amb èxit',
  transactionsProcessed: 'Transaccions processades amb èxit',
  transactionError: 'Transacció processada amb invàlids',
  transactionsError: 'Transaccions processades amb invàlids',
  transactionProcessing: 'Processant transacció...',
  transactionLaunchedMessage: 'La transacció s\'ha processat amb èxit. A mesura que es processin els enviaments, es podran veure a la secció de',
  transactionsLaunchedMessage: 'Les transaccions s\'han processat amb èxit. A mesura que es processin els enviaments, es podran veure a la secció de',
  transactionDownloadFile: 'Visualitza certificat',
  transactionProcessingMessage: 'El sistema està processant la transacció. Si us plau, espereu uns instants',
  transactionsProcessingMessage: 'El sistema està processant la transacció. Si us plau, espereu uns instants',
  transactionsErrorMessage: 'Durant el procés de les transaccions dels diferents destinataris s\'han detectat dades invàlides a almenys una transacció. A continuació disposeu de la llista completa de transaccions de l\'enviament',
  transactionErrorMessage: 'Durant el procés de la transacció s\'han detectat dades invàlides',
  recoverProcessQuestion: 'Si inicieu un nou procés, perdreu els avanços',
  recoverProcessQuestionAppend: 'Voleu iniciar un nou procés?',
  statisticsTransactionsMultipleTitle: 'Transaccions realitzades entre els dies <span class="font-weight-bold">{start_day} de {start_month}</span> y <span class="font-weight-bold">{end_day} de {end_month}</span>',
  statisticsTransactionsOneTitle: 'Transaccions realitzades el dia {start_day} de {start_month}',
  statisticsTotalTransactionsTitle: 'Totals per tipus de transacció',
  statisticsNoResults: 'No hi ha resultats que coincideixin amb els filtres indicats',
  statisticsProcessTitle: 'Procés:',
  total: 'Total',
  totalTransactions: 'Transaccions totals',
  totalTransactionsByProcesses: 'Desglossament de transaccions per processos',
  accountDescription: 'Configuració de compte',
  accountTitle: 'Compte',
  contactsDescription: 'Llista i gestió de contactes',
  contactsTitle: 'Contactes',
  transactionsDescription: 'Llista i detalls de transaccions',
  transactionsTitle: 'Transaccions',
  statisticsDescription: 'Anàlisi gràfic de les vostres transaccions',
  statisticsTitle: 'Estadístiques',
  supportDescription: 'A aquesta secció trobareu ajuda a través de suport tècnic o el manual d\'usuari',
  supportManualDescription: 'A aquest apartat podeu consultar la documentació relacionada amb el maneig de la plataforma',
  supportManualTitle: 'Consulta manual i guia',
  supportManualTitleOnlyManual: 'Consulta manual',
  supportManualTitleOnlyGuide: 'Consulta guia',
  administrationDescription: 'Administració de comptes de la plataforma',
  administrationTitle: 'Administració',
  userActive: 'Usuari actiu',
  userInactive: 'Usuari desactivat',
  userStateTitle: 'Estat de l\'usuari',
  groupsTitle: 'Grups de permisos',
  groupsDescription: 'Gestió dels grups del compte. Configuració de paràmetres i permisos de cada grup.',
  groupsAssignation: 'Grup assignat',
  groupInheritFrom: 'Hereta permisos de',
  groupDataTitle: 'Informació del Grup',
  groupUsersPermissionsTitle: 'Permisos, usuaris i processos',
  groupPermissionsTabsTitle: 'Permisos',
  groupProcessesAndSubaccounts: 'Subcomptes i processos',
  groupLinked: 'Grups vinculats',
  groupPermissionsOwnerText: 'Aquest grup és el propietari del compte i té assignats tots els permisos',
  groupPermissionsOwnerSubaccountProcessesText: 'Aquest grup és el propietari del compte i té assignats tots els subcomptes i tots els processos',
  groupAssociatedProcesses: 'Processos associats',
  groupAssociatedSubaccounts: 'Subcomptes associats',
  newGroup: 'Nou grup',
  groupName: 'Nom del grup',
  editGroup: 'Editant grup',
  groupDescription: 'Descripció',
  groupMinDaysToChange: 'Dies mínims de vigència de contrasenya',
  groupTabUsersNameTitle: 'Nom i cognoms',
  groupTabPermissionSectionTitle: 'Seccions',
  groupTabPermissionGroupTitle: 'Grups',
  groupTabPermissionUsersTitle: 'Usuaris',
  usersSearch: 'Cerca usuaris',
  subaccountWithoutProcesses: 'Subcompte sense processos associats',
  selectCostCenter: 'Seleccioneu un centre de cost',
  adminGroupInfoDescription: 'En aquesta secció podeu editar la descripció, polítiques de sessió i contrasenyes de la plataforma.',
  adminGroupInfoUsers: 'En aquesta secció podeu gestionar els usuaris associats a aquest grup.',
  adminGroupInfoPerms: 'En aquesta secció podeu gestionar els permisos relacionats amb les funcions que l\'usuari pot realitzar a la plataforma.',
  adminGroupInfoProcesses: 'En aquesta secció podeu gestionar l\'accés de l\'usuari a processos i subcomptes. Aquest accés atorgarà permís d\'execució, visualització de transaccions i estadístiques dels processos assignats.',
  adminGroupInfoLinked: 'En aquesta secció podeu gestionar la supervisió d\'altres grups. Els grups que estiguin habilitats compartiran la informació de les transaccions executades i les estadístiques amb el grup actual.',
  passwordsQuantity: 'Número de contrasenyes emmagatzemades',
  passwordExpiration: 'Dies per a la caducitat de la contrasenya',
  loginRetriesQuantity: 'Quantitat de reintents a inici de sessió',
  loginRetriesTime: 'Minuts per reintentar a inici de sessió',
  sessionExpiration: 'Minuts per a la caducitat de la sessió',
  processAlertExpirationQuestion: 'Voleu continuar?',
  processAlertExpirationPhrase: 'Per motius de seguretat, la sessió caducarà en breus moments',
  processAlertExpiration: 'Alerta de caducitat',
  continueSession: 'Continua',
  usersTitle: 'Usuaris',
  activate: 'Activat',
  deactivate: 'Desactivat',
  usersPerPage: 'Usuaris per pàgina',
  usersDescription: 'Gestió dels usuaris del compte',
  userInfoTitle: 'Informació de l\'usuari',
  userPermissionTitle: 'Tipus de permís assignat',
  newUser: 'Nou usuari',
  editUser: 'Editant usuari',
  groupTitle: 'Nom del grup',
  administrator: 'Administrador',
  groupLinkedHeader: 'Vinculat',
  firmaElectronica: 'Signatura electrònica',
  comunicacionCertificada: 'Comunicació certificada',
  publicacionWeb: 'Publicació web',
  groupVisibility: 'Visibilitat de grup',
  passwordPolicy: 'Política de contrasenyes',
  frontalPolicy: 'Política de sessió',
  groupData: 'Dades del grup',
  groupsPerPage: 'Grups per pàgina',
  groupsFound: 'S\'han trobat',
  cancel: 'Cancel·la',
  description: 'Descripció',
  subaccountChanged: 'Subcompte canviat',
  processContinueTitle: 'Teniu un procés pendent d\'enviament',
  processContinueSubtitle: 'Voleu recuperar el procés o iniciar un de nou?',
  newProcess: 'Nou procés',
  recover: 'Recupera',
  newShipping: 'Nou enviament',
  toContinueFillFields: 'Per continuar, ompliu correctament els camps',
  passwordRequired: 'Contrasenya requerida',
  userRequired: 'Usuari requerit',
  fieldAlert: 'El camp %%fields%% s\'omplirà automàticament des dels destinataris',
  fieldAlertPlural: 'Els camps %%fields%% s\'ompliran automàticament des dels destinataris',
  fieldNumberPhone: 'telèfon',
  fieldCountryCode: 'codi de país',
  fieldEmail: 'correu electrònic',
  fieldName: 'nom',
  fieldSurname: 'cognoms',
  and: 'i',
  certificate: 'Certificat',
  country: 'País',
  continueEditing: 'Edita les dades de l\'enviament',
  second: 'segon',
  seconds: 'segons',
  nextRefreshAvailableAt: 'Propera actualització disponible a',
  fastSearch: 'Cerca ràpida',
  update: 'Actualitza',
  process: 'Procés',
  transactionId: 'Id de la transacció',
  transactionClientId: 'Referència de client',
  createdDate: 'Creada',
  updatedAt: 'Actualitzada',
  state: 'Estat',
  noGroupAssigned: 'Sense grup seleccionat',
  contact_country_code: 'Codi de país',
  contact_email: 'Correu electrònic',
  contact_id: 'Referència de client',
  contact_name: 'Nom',
  contact_phone: 'Telèfon',
  contact_surname: 'Cognoms',
  contact_complete_name: 'Nom complet',
  certificateValidDownload: 'Certificat descarregat correctament',
  certificateFailDownload: 'No s\'ha pogut descarregar el Certificat',
  graphicBarValidDownload: 'Gràfic descarregat correctament',
  graphicBarFailDownload: 'No s\'ha pogut descarregar el gràfic',
  queued: 'La transacció està pendent de ser processada.',
  new_communication: 'La transacció s\'ha generat correctament.',
  orchestrator_new_communications_communication_received: 'La transacció s\'ha processat correctament.',
  commstracker_generate_url_generated: 'S\'ha generat l\'enllaç de la URL abreujada.',
  commstracker_generate_url_click: 'S\'ha obert l\'enllaç de la transacció.',
  communication_send_sms_sent: 'S\'ha dipositat el missatge de text en el centre de servei d\'enviament del proveïdor. Aquest últim s\'encarregarà de gestionar l\'enviament al destinatari final.',
  communication_send_sms_not_sent: 'El missatge de text no s\'ha pogut dipositar en el centre de servei d\'enviament del proveïdor.',
  communication_send_sms_delivered: 'El missatge de text s\'ha entregat al destinatari.',
  communication_send_sms_not_delivered: 'El missatge de text no s\'ha pogut entregar al destinatari.',
  generate_otp_code_otp_generated: 'S\'ha generat una clau d\'ús únic (OTP) per al seu ús.',
  generate_certificate_generated: 'S\'ha generat el certificat de finalització de la transacció, que recull el contingut de la comunicació i les evidències associades als esdeveniments que s\'han produït durant el procés.',
  communication_send_email_delivered: 'El missatge s\'ha entregat al proveïdor del servei de correu electrònic (ESP) del destinatari.',
  communication_send_email_complaint: 'El destinatari del missatge ha marcat el correu electrònic rebut com a no desitjat.',
  communication_send_email_queued: 'El missatge de correu electrònic s\'ha posat en cua.',
  communication_send_email_deferred: 'El missatge de correu electrònic s\'ha posat en cua i s\'entregarà quan sigui possible.',
  communication_send_email_bounce: 'El missatge de correu electrònic ha rebotat.',
  communication_send_email_sent: 'El missatge de correu electrònic s\'ha dipositat en el servei d\'enviament del proveïdor. Aquest últim s\'encarregarà de gestionar l\'enviament al destinatari final.',
  communication_send_email_sender_not_allowed: 'El missatge de correu electrònic no s\'ha pogut enviar perquè el remitent no està autoritzat.',
  communication_send_email_open: 'El destinatari ha obert el missatge de correu electrònic.',
  communication_send_email_click: 'El destinatari del missatge de correu electrònic ha fet clic a l\'enllaç.',
  communication_send_email_block: 'L\'adreça de correu electrònic del destinatari es troba a la llista d\'exclusions. El missatge no s\'ha enviat.',
  communication_send_email_unsubscribe: 'El destinatari del missatge de correu electrònic vol donar-se de baixa de la llista de distribució.',
  communication_send_email_spam: 'El missatge de correu electrònic s\'ha identificat com a no desitjat.',
  communication_send_email_hard_blacklisted: 'L\'adreça de correu electrònic del destinatari es troba a la llista d\'exclusions. El missatge no s\'ha enviat.',
  communication_send_email_complaint_blacklisted: 'L\'adreça de correu electrònic del destinatari es troba a la llista de queixes. El missatge no s\'ha enviat.',
  document_upload_s3_uploaded: 'S\'ha emmagatzemat de forma segura el certificat de finalització de la transacció, que recull el contingut de la comunicació i les evidències associades als esdeveniments que s\'han produït durant el procés.',
  communication_send_telegram_delivered: 'El missatge de text s\'ha entregat al destinatari.',
  communication_send_telegram_not_delivered: 'El missatge de text no s\'ha pogut entregat',
  web_framework_generated: 'S\'ha generat la interfície gràfica web perquè el destinatari pugui interactuar.',
  web_framework_solicitud_otp: 'S\'ha sol·licitat una clau d\'ús únic (OTP).',
  web_framework_signable_document_loaded: 'S\'ha carregat a la interfície web un document perquè sigui signat.',
  web_framework_otp_simple_signature_ok: 'S\'ha signat la documentació mitjançant la clau d\'ús únic.',
  web_framework_commited: 'S\'ha completat el procés a la interfície web.',
  web_framework_document_signed: 'S\'ha signat la documentació.',
  web_framework_form_based_identification: 'S\'ha completat el pas d\'autenticació a través de formulari.',
  web_framework_certificate_based_identification: 'S\'ha realitzat un accés amb certificat personal de forma satisfactòria',
  web_framework_document_based_identification: 'S\'ha identificat en el formulari de forma correcta.',
  web_framework_certificate_based_identification_failed: 'Ha fallat el procés d\'identificació mitjançant certificat.',
  web_framework_document_based_identification_failed: 'Ha fallat el procés d\'identificació mitjançant document.',
  web_framework_form_based_identification_failed: 'Ha fallat el procés d\'identificació mitjançant formulari.',
  web_framework_web_framework_expired: 'La transacció ha caducat.',
  web_framework_documentation_downloaded: 'El destinatari ha descarregat la documentació.',
  web_framework_electronic_notificacion_rejected: 'El destinatari ha rebutjat la notificació electrònica.',
  web_framework_gdpr_formulary_committed: 'El destinatari ha omplert el formulari de consentiment.',
  web_framework_sepa_formulary_committed: 'El destinatari ha omplert el formulari SEPA.',
  web_framework_requested_otp_code: 'S\'ha sol·licitat una clau d\'ús únic (OTP).',
  web_framework_entered_wrong_otp_code: 'La clau d\'ús únic (OTP) introduïda pel destinatari és incorrecta.',
  web_framework_entered_timedout_otp_code: 'La clau d\'ús únic ha caducat.',
  web_framework_entered_correct_otp_code: 'La clau d\'ús únic (OTP) introduïda pel destinatari és correcta.',
  web_framework_electronic_notificacion_accepted: 'El destinatari ha acceptat la notificació electrònica.',
  web_framework_signature_rejected: 'El destinatari ha rebutjat la sol·licitud de signatura electrònica.',
  web_framework_request_otp_code_too_early: 'El destinatari ha sol·licitat la clau d\'ús únic (OTP) abans del temps estipulat per poder regenerar-la',
  web_framework_capture_data_commited_no_response: 'S\'ha intentat fer un enviament del formulari sense dades a les respostes.',
  web_framework_capture_data_commited: 'L\'enviament de dades s\'ha realitzat correctament',
  web_framework_document_previewed: 'El destinatari ha previsualitzat la documentació,',
  web_framework_document_simply_signed: 'La documentació s\'ha signat electrònicament,',
  document_upload_alastria_uploaded: 'El hash del certificat de finalització s\'ha pujat al node de CertySign a la Xarxa Blockchain d\'Alastria.',
  publish_downloadable_file_generated: 'S\'ha generat la documentació per a la seva publicació a la interfície web.',
  publish_downloadable_file_open: 'S\'ha obert la documentació per a la seva publicació a la interfície web.',
  document_sign_signed: 'S\'ha signat el certificat',
  rpc_callback_published: 'S\'ha publicat el retorn de la trucada RPC',
  loop_add_added: 'S\'ha afegit la transacció al bucle de reintents.',
  loop_add_tick: 'S\'ha realitzat un intent addicional',
  loop_add_timeout: 'S\'ha esgotat el límit de reintents',
  loop_cancel_canceled: 'El bucle s\'ha cancel·lat',
  identification_create_identification_generated: 'S\'ha creat el procés mitjançant el qual l\'usuari s\'identificarà a la transacció.',
  identification_create_identification_ok: 'S\'ha identificat de forma correcta',
  test_load_processed: 'S\'ha processat la prova de càrrega',
  instance_delete_deleted: 'La instància corresponent a la transacció s\'ha esborrat.',
  unzip_file_extracted: 'S\'ha descomprimit el fitxer.',
  communicalia_consent_accepted: 'El destinatari de la comunicació ha acceptat el consentiment.',
  communicalia_consent_declined: 'El destinatari de la comunicació ha rebutjat el consentiment.',
  communicalia_consent_no_data: 'El destinatari de la comunicació no ha respost.',
  robinson_list_accepted: 'El destinatari no es troba a la llista Robinson',
  robinson_list_declined: 'El destinatari es troba donat d\'alta a la llista Robinson',
  robinson_list_no_data: 'El destinatari es troba donat d\'alta a la llista Robinson',
  publish_embeddable_file_generated: 'S\'ha generat el fitxer incrustat per a la interfície web.',
  document_upload_sftp_uploaded: 'S\'ha realitzat la càrrega de la documentació a través d\'SFTP.',
  file_delete_deleted: 'S\'ha esborrat l\'arxiu.',
  disable_framework_web_framework_web_disabled: 'S\'ha desactivat la interfície web associada a la transacció.',
  generate_onespan_transaction_transaction_create: 'Se ha creat la transacció de signatura electrònica amb OneSpan',
  generate_onespan_transaction_transaction_create_error: 'S\'ha produït un error en crear la transacció de signatura electrònica a OneSpan.',
  generate_onespan_transaction_transaction_complete: 'S\'ha completat la transacció de signatura electrònica a OneSpan.',
  generate_onespan_transaction_transaction_expire: 'La transacció de signatura electrònica a OneSpan ha caducat.',
  generate_onespan_transaction_transaction_decline: 'La transacció de signatura electrònica de OneSpan ha estat rebutjada.',
  generate_onespan_transaction_email_bounce: 'El missatge de correu electrònic corresponent a la transacció de signatura electrònica de OneSpan ha rebotat.',
  generate_onespan_transaction_transaction_opt_out: 'El destinatari ha optat per no signar la transacció.',
  generate_onespan_transaction_transaction_delete: 'La transacció de signatura electrònica de OneSpan ha estat eliminada.',
  generate_onespan_transaction_transation_desactivate: 'La transacció de signatura electrònica de OneSpan ha estat desactivada.',
  generate_onespan_transaction_kba_failure: 'La transacció s\'ha bloquejat per motius de seguretat en l\'accés o procés.',
  generate_onespan_transaction_transaction_sent: 'La transacció de signatura electrònica de OneSpan ha estat enviada.',
  generate_onespan_transaction_transaction_sent_error: 'L\'enviament de la transacció de signatura electrònica de OneSpan ha fallat.',
  generate_onespan_transaction_package_trash: 'La transacció s\'ha mogut a la paperera de OneSpan',
  get_onespan_files_files_onespan_generated: 'S\'han generat els fitxers necessaris per a OneSpan',
  communication_send_postal_generated: 'S\'ha generat l\'enviament de la comunicació postal.',
  communication_send_postal_sent_to_factory: 'L\'enviament ha estat carregat a la plataforma de comunicació postal.',
  communication_send_postal_printed: 'La documentació associada a l\'enviament postal s\'ha imprès.',
  communication_send_postal_pre_admission_requested: 'L\'enviament de la comunicació s\'està preregistrant telemàticament al web de l\'operador postal.',
  communication_send_postal_pre_admitted: 'L\'operador postal ha registrat telemàticament l\'enviament de la comunicació abans del seu dipòsit.',
  communication_send_postal_sent_to_logistic_operator: 'L\'enviament de la comunicació s\'està dipositant a l\'operador postal.',
  communication_send_postal_on_the_way_to_logistic_operator: 'L\'enviament està en camí de l\'oficina postal més propera al destinatari de la comunicació.',
  communication_send_postal_received_by_logistic_operator: 'L\'enviament està a l\'oficina postal més propera al destinatari de la comunicació.',
  communication_send_postal_not_received_by_logistic_operator: 'L\'enviament de la comunicació no ha arribat a l\'oficina postal.',
  communication_send_postal_on_delivery: 'L\'enviament de la comunicació postal es troba en camí.',
  communication_send_postal_delivered_to_recipient: 'L\'enviament de la comunicació postal s\'ha entregat al destinatari.',
  communication_send_postal_returned_to_postal_office: 'L\'enviament es troba a l\'oficina postal i el destinatari de la comunicació té l\'avís a la seva bústia',
  communication_send_postal_delivered_into_postal_office: 'L\'enviament de la comunicació s\'ha entregat al destinatari a l\'oficina postal.',
  communication_send_postal_generic_delivered: 'L\'enviament de la comunicació postal s\'ha entregat al destinatari.',
  communication_send_postal_returned: 'No s\'ha pogut realitzar l\'entrega de l\'enviament i la comunicació postal s\'ha retornat al seu origen.',
  communication_send_postal_canceled: 'L\'enviament de la comunicació postal s\'ha cancel·lat.',
  communication_send_postal_lost: 'L\'enviament de la comunicació postal s\'ha extraviat.',
  communication_send_postal_expired: 'L\'enviament de la comunicació postal s\'ha retornat al seu origen perquè el destinatari no l\'ha recollit durant els 15 dies hàbils posteriors a l\'avís deixat a la bústia.',
  communication_send_postal_rejected: 'El destinatari ha rebutjat l\'enviament de la comunicació postal.',
  communication_send_postal_stolen: 'L\'enviament de la comunicació postal s\'ha sostret o robat.',
  communication_send_postal_outside_sicer: 'L\'enviament de la comunicació no es troba enregistrat a l\'operador postal.',
  communication_send_postal_bad_postal_address: 'L\'adreça postal de l\'enviament no és correcta.',
  communication_send_postal_passed_away: 'El destinatari de la comunicació postal és mort.',
  communication_send_postal_other: 'Altres.',
  communication_send_postal_no_information: 'No consta informació de l\'enviament de la comunicació a l\'operador postal.',
  communication_send_postal_received_pee: 'S\'ha rebut la Prova Electrònica d\'Entrega (EE) de l\'enviament.',
  communication_send_postal_certificate_returned: 'S\'ha generat el certificat de devolució amb la Prova d\'Impossibilitat d\'entrega de l\'operador postal.',
  communication_send_postal_unknown: 'El destinatari de l\'enviament de la comunicació postal és desconegut a l\'adreça postal indicada.',
  zip_files_path_zipped: 'S\'han comprimit un o diversos documents',
  intromission_api_retries_muted: 'S\'han desactivat els reintents de la transacció',
  intromission_api_retries_unmuted: 'S\'han activat els reintents de la transacció',
  intromission_api_instance_canceled: 'La transacció s\'ha cancel·lat.',
  antivirus_filtered: 'L\'antivirus ha filtrat la transacció perquè s\'han pujat recursos potencialment perillosos.',
  //API messages
  user_operation_error_creating_user: 'S\'ha produït un error en crear l\'usuari',
  user_operation_user_already_exists: 'L\'usuari ja existeix',
  user_operation_user_created: 'Usuari creat amb èxit',
  user_operation_user_updated: 'Usuari actualitzat amb èxit',
  user_operation_no_exists: 'L\'usuari no existeix',
  user_operation_password_changed: 'La contrasenya s\'ha actualitzat',
  user_operation_pass_incorrect: 'La combinació d\'usuari i contrasenya és incorrecta',
  user_operation_password_expired: 'La contrasenya ha caducat',
  user_operation_logout: 'Sessió tancada correctament',
  user_operation_blocked_user: 'L\'usuari està bloquejat',
  user_operation_wrong_subdomain: 'El nom d\'usuari o la contrasenya no és correcte',
  user_operation_max_pass_tries: 'Usuari bloquejat, número d\'intents excedits',
  user_operation_deleted: 'Usuari esborrat correctament',
  user_operation_error_deleted: 'Error en esborrar l\'usuari',
  user_has_been_disabled: 'Usuari desactivat',
  user_operation_error_user_not_found: 'Si l\'adreça de correu electrònic indicat existeix, rebreu un correu electrònic breu amb les indicacions per canviar la vostra contrasenya',
  user_operation_remember_password_email: 'Si l\'adreça de correu electrònic indicat existeix, rebreu un correu electrònic breu amb les indicacions per canviar la vostra contrasenya',
  email_sent_to_support: 'Sol·licitud enviada correctament',
  user_operation_error_change_password_min_days: 'No es pot canviar la contrasenya perquè no ha passat el mínim de dies requerit',
  user_operation_error_passwords_mismatch: 'Les contrasenyes no coincideixen',
  user_operation_error_password_has_been_used_before: 'La contrasenya s\'ha utilitzat amb anterioritat. Trieu una de nova',
  user_or_group_no_exists: 'Usuari o grup no existeix',
  user_error_already_active: 'L\'usuari ja està actiu',
  activation_email_resend: 'S\'ha reenviat el correu electrònic d\'activació',
  group_operation_error_creating_group: 'S\'ha produït un error en crear el grup',
  group_operation_group_already_exists: 'El grup ja existeix',
  group_operation_group_created: 'Grup creat amb èxit',
  group_operation_group_updated: 'Grup :group_name: actualitzat amb èxit',
  //Terms and conditions
  termsConditionsDPOTitle: 'Delegat de Protecció de Dades',
  termsConditionsTitular: 'Titular',
  termsConditionsNIF: 'NIF',
  termsConditionsDomicilioSocial: 'Domicili Social',
  termsConditionsDatosRegistrales: 'Dades Registrals',
  termsConditionsContacto: 'Contacte',
  termsConditionsDPOEmailTitle: 'Correu electrònic Customer Comms',
  termsConditionsDPOEmail: 'Correu electrònic del delegat de Protecció de Dades',
  termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (en endavant, \'CCOMMS\') en la seva condició de titular i responsable de la present APP, en compliment de les disposicions de l\'article 10 de la Llei 34/2002, de 11 de juliol, de Serveis de la Societat de la Informació i de Comerç Electrònic, posa a disposició dels usuaris la següent informació general d\'aquesta APP:',
  termsConditionsObjetoTitulo: 'OBJECTE',
  termsConditionsObjetoParrafo1: 'Les presents Condicions Generals d\'Ús (en endavant, les \'CGU\') regulen l\'ús de l\'Aplicació CertySign (en endavant, l\'APP) que CCOMMS posa a disposició del usuaris que hi accedeixin a fi de procedir a poder dur a terme la sol·licitud realitzada.',
  termsConditionsObjetoParrafo2: 'Per tant, les presents CGU s\'aplicaran tant a l\'activitat promocional i de subministrament d\'informació que s\'efectua a través de l\'APP com a la prestació dels serveis oferts a l\'APP, de manera que les CGU regiran en tot moment tant la simple navegació per l\'APP com la contractació de serveis en el marc de l\'APP, tot i que aquestes últimes activitats se sotmetran tant a les CGU descrites a continuació com a les Condicions Generals de Contractació aplicables, i les Condicions Particulars que, si escau, poguessin existir.',
  termsConditionsUsuariosTitulo: 'USUARIS',
  termsConditionsUsuariosParrafo1: 'L\'accés a l\'APP i/o l\'ús de l\'APP atribueix a la persona que el realitza la condició d\'usuari que accepta, des d\'aquest mateix moment, plenament i sense cap reserva, les presents CGU i les Condicions Particulars que, si s\'escauen, complementen, modifiquen o substitueixen les CGU en relació amb determinats serveis i continguts de l\'APP, i que obliguen a respectar les restriccions d\'ús de la pàgina esmentada establertes per CCOMMS en cada moment.',
  termsConditionsAccesoAPPTitulo: 'ACCÉS A L\'APP',
  termsConditionsAccesoAPPParrafo1: 'L\'accés i ús de l\'APP té caràcter restringit i per poder accedir-hi caldrà que l\'usuari es registri prèviament.',
  termsConditionsUsoAPPTitulo: 'ÚS DE L\'APP',
  termsConditionsUsoAPPParrafo1: 'L\'usuari es compromet a utilitzar l\'APP, i els seus serveis i continguts sense contravenir la legislació vigent, la bona fe, els usos acceptats generalment i l\'ordre públic. Així mateix, queda prohibit l\'ús de l\'APP amb objectius il·lícits o lesius contra CCOMMS o qualsevol tercer o que, de qualsevol forma, puguin causar un perjudici o impedir el funcionament normal de l\'APP.',
  termsConditionsResposabilidadTitulo: 'RESPONSABILITAT DE L\'USUARI PER DANYS O PERJUDICIS',
  termsConditionsResposabilidadParrafo1: 'L\'ús de l\'APP es realitzarà sota la responsabilitat única i exclusiva de l\'usuari. Aquesta responsabilitat s\'estendrà a l\'ús per part de l\'usuari o qualsevol tercer de qualsevol contrasenya o clau similar assignada per a l\'accés a l\'APP o a qualsevol dels seus serveis.',
  termsConditionsResposabilidadParrafo2: 'Sense perjudici de la disposició anterior, CCOMMS es reserva el dret a denegar o inhabilitar l\'accés a l\'APP en qualsevol moment i sense necessitat d\'avís previ, o a restringir-hi l\'accés a aquells usuaris que incompleixin aquestes CGU, o les normes establertes per CCOMMS o els seus col·laboradors, o que puguin pertorbar el bon funcionament, imatge, credibilitat i/o prestigi de CCOMMS o els seus col·laboradors.',
  termsConditionsResposabilidadParrafo3: 'En aquest sentit, CCOMMS es reserva la facultat de cancel·lar o restringir l\'accés a l\'APP a determinats Usuaris en cas d\'observar qualssevol conductes que, segons CCOMMS, resultin contràries a aquestes Condicions d\'Ús, la llei, o les normes establertes per CCOMMS o els seus col·laboradors, o que puguin pertorbar el bon funcionament, imatge, credibilitat i/o prestigi de CCOMMS o els seus col·laboradors. Així mateix, CCOMMS podrà exigir responsabilitat als Usuaris pels danys i perjudicis derivats del mal ús de l\'APP.',
  termsConditionsResposabilidadParrafo4: 'CCOMMS no garanteix la licitud, fiabilitat i utilitat dels continguts que els usuaris transmetin durant la utilització de l\'APP, ni tampoc la seva veracitat o exactitud.',
  termsConditionsResposabilidadParrafo5: 'CCOMMS no controla l\'ús que els usuaris fan de l\'APP ni garanteix que sigui conforme a les presents Condicions Generals.',
  termsConditionsResposabilidadParrafo6: 'CCOMMS no controla ni garanteix l\'absència de virus informàtics en els serveis prestats per tercers a través de l\'APP que puguin produir alteracions en el seu sistema informàtic (programari i maquinari) o en els documents i fitxers electrònics emmagatzemats al seu sistema informàtic.',
  termsConditionsPropiedadIntelectualTitulo: 'PROPIETAT INTEL·LECTUAL',
  termsConditionsPropiedadIntelectualParrafo1: 'Qualsevol ús o modificació del Material o dels Continguts per a qualsevol altre objectiu que no sigui l\'autoritzat a les Condicions Generals es considerarà una violació de les lleis internacionals del "copyright", que protegeixen els drets d\'autor.',
  termsConditionsPropiedadIntelectualParrafo2: 'Respecte dels continguts, es prohibeix:',
  termsConditionsPropiedadIntelectualListaELemento1: 'La seva reproducció, còpia, distribució, modificació o manipulació de qualsevol forma i amb qualsevol finalitat, excepte si es disposa de l\'autorització prèvia per escrit dels seus titulars legítims o es troba permesa per la llei.',
  termsConditionsPropiedadIntelectualListaELemento2: 'Qualsevol vulneració dels drets de CCOMMS o dels seus titulars legítims sobre els esmentats continguts.',
  termsConditionsPropiedadIntelectualListaELemento3: 'La seva utilització per a tot tipus d\'objectius comercials o publicitaris diferents dels estrictament permesos.',
  termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS garanteix que els continguts, inclosos els de propietat intel·lectual, no són de caràcter il·lícit ni infringeixen la normativa vigent. Per tant, els continguts de l\'APP no tindran caràcter xenòfob, pornogràfic, discriminatori o racista, ni fomentaran la violència de qualsevol altra forma.',
  termsConditionsPropiedadIntelectualParrafo4: 'A més, CCOMMS adoptarà les mesures legals que consideri oportunes per impedir qualsevol tipus de conducta contrària a la llei o immoral.',
  termsConditionsModificacionUnilateralTitulo: 'MODIFICACIÓ UNILATERAL',
  termsConditionsModificacionUnilateralParrafo1: 'CCOMMS podrà modificar unilateralment i sense avís previ, sempre ho consideri oportú, l\'estructura i disseny de l\'APP, així com modificar o eliminar els serveis, continguts i condicions d\'accés i/o ús de l\'APP.',
  termsConditionsExclusionGarantiasTitulo: 'EXCLUSIÓ DE GARANTIES I RESPONSABILITAT',
  termsConditionsExclusionGarantiasParrafo1: 'CCOMMS no atorga cap garantia ni es fa responsable en cap cas pel que fa als danys i perjudicis de qualsevol tipus que poguessin produir:',
  termsConditionsExclusionGarantiasListaELemento1: 'L\'incompliment de la llei, la moral, els bons costums i l\'ordre públic, o l\'ús dels productes i/o serveis oferts de forma no diligent o incorrecta, o amb objectius o efectes il·lícits o contraris a les disposicions de les presents CGU o de les condicions aplicables en cada cas.',
  termsConditionsExclusionGarantiasListaELemento2: 'Per l\'adquisició o contractació dels serveis, béns o productes que es puguin adquirir o contractar a tercers a través d\'un accés a l\'APP de CCOMMS, especialment en aquells casos en què el procés de compra o contractació es realitza directament a l\'aplicació del tercer i tot i que apareguin distintius o un \'frame\' amb els elements gràfics de l\'aplicació (branding).',
  termsConditionsExclusionGarantiasListaELemento3: 'La manca de disponibilitat, manteniment i funcionament efectiu de l\'APP i/o dels seus serveis o continguts.',
  termsConditionsExclusionGarantiasListaELemento4: 'La manca d\'utilitat, adequació o validesa de l\'APP i/o dels seus serveis o continguts per satisfer necessitats, activitats o resultats concrets, o expectatives dels usuaris.',
  termsConditionsExclusionGarantiasListaELemento5: 'L\'existència de virus, programes maliciosos o lesius en els continguts.',
  termsConditionsExclusionGarantiasListaELemento6: 'La recepció, obtenció, emmagatzemament, difusió o transmissió dels continguts per part dels usuaris.',
  termsConditionsExclusionGarantiasListaELemento7: 'La il·licitud o manca de veracitat, exactitud, fiabilitat, pertinença, actualitat i exhaustivitat dels continguts i la informació transmesos o posats a disposició del usuaris, incloent-hi la informació i els serveis prestats per tercers o pels usuaris a través de l\'APP.',
  termsConditionsExclusionGarantiasListaELemento8: 'L\'incompliment per part de tercers de les seves obligacions o compromisos en relació amb els serveis prestats als usuaris a través de l\'APP.',
  termsConditionsExclusionGarantiasListaELemento9: 'Dels continguts, arxius, informació, publicitat, opinions, conceptes i imatges que no depenguin de l\'APP ni siguin gestionades per CCOMMS. Tampoc es responsabilitza del mal ús que es realitzi dels continguts de l\'APP. La responsabilitat exclusiva recaurà sobre la persona que accedeixi a aquests continguts i els utilitzi.',
  termsConditionsExclusionGarantiasListaELemento10: 'De l\'ús indegut de les claus d\'accés dels usuaris per a l\'accés als continguts de l\'APP que les requereixin i de les conseqüències de qualsevol tipus derivades del mal ús per part dels usuaris, la seva pèrdua i el seu ús indegut per part de tercers no autoritzats.',
  termsConditionsExclusionGarantiasListaELemento11: 'La incapacitat de qualsevol usuari o la suplantació de la personalitat d\'un tercer efectuada per l\'usuari.',
  termsConditionsExclusionGarantiasListaELemento12: 'Dels continguts facilitats pels usuaris.',
  termsConditionsProteccionDatosTitulo: 'PROTECCIÓ DE DADES',
  termsConditionsProteccionDatosParrafo1: 'Podeu consultar la informació detallada sobre Protecció de Dades a la nostra pàgina de',
  termsConditionsLeyJurisdiccionTitulo: 'LLEI APLICABLE I JURISDICCIÓ',
  termsConditionsLeyJurisdiccionParrafo1: 'Totes les Condicions, Generals i Particulars, inserides a la present APP es regeixen per la normativa espanyola. Tots els conflictes, controvèrsies o situacions derivades de les Condicions Generals i particulars se sotmetran als Jutjats i Tribunals de la Vila de Madrid, i les parts renunciaran al seu fur',
  termsConditionsProteccionDatosTitle: 'Consulta informació de Protecció de Dades',
  termsConditionsProteccionDatos: 'Protecció de Dades',
  //Privacy Policies
  privacyPoliciesConditionsTitle: 'Protecció de dades de MailTeck & Customer Communications Tecknalia',
  privacyPoliciesConditionsParrafo1: 'De conformitat amb les disposicions del Reglament (UE) 2016/679 del Parlament Europeu i del Consell, de 27 d\'abril de 2016, relatiu a la protecció de les persones físiques pel que fa al tractament de dades personals i la lliure circulació d\'aquestes dades (RGPD), i de la Llei Orgànica 3/2018, de 5 de desembre, de Protecció de Dades Personals i garantia dels drets digitals (LOPD-GDD) i altres normes vigents sobre protecció de dades, s\'informa els usuaris de l\'APP que han manifestat de manera voluntària, lliure, clara i inequívoca el seu consentiment per al tractament de les seves dades personals en acceptar la casella de \'Política de protecció de dades\' que les seves dades seran tractades, en funció de l\'empresa en què s\'hagin donat d\'alta, per l\'empresa MAILTECK, S.A. o per l\'empresa CUSTOMER COMMUNICATIONS TECKNALIA, S.L., i que ambdues empreses es denominaran, de forma conjunta, \'GRUP MAILCOMMS\'.',
  privacyPoliciesConditionsResponsable1Title: 'Responsable 1',
  privacyPoliciesConditionsResponsable2Title: 'Responsable 2',
  privacyPoliciesConditionsFinalidadTitle: 'Finalitat',
  privacyPoliciesConditionsLegitimacionTitle: 'Legitimació',
  privacyPoliciesConditionsDestinatariosTitle: 'Destinataris',
  privacyPoliciesConditionsDerechosTitle: 'Drets',
  privacyPoliciesConditionsInformacionAdicionalTitle: 'Informació addicional',
  privacyPoliciesConditionsPoliticaInformacion1Title: 'Política de la informació 1',
  privacyPoliciesConditionsPoliticaInformacion2Title: 'Política de la informació 2',
  privacyPoliciesConditionsIdentidadTitle: 'Identitat',
  privacyPoliciesConditionsNIFTitle: 'NIF',
  privacyPoliciesConditionsDireccionTitle: 'Adreça',
  privacyPoliciesConditionsTelefonoTitle: 'Telèfon',
  privacyPoliciesConditionsCorreoTitle: 'Correu',
  privacyPoliciesConditionsDPDTitle: 'Contacte amb el DPD',
  privacyPoliciesConditionsDPDCustomerCommsTitle: 'Delegat de Protecció de Dades de Customer Comms',
  privacyPoliciesConditionsDPDMailteckTitle: 'Delegat de Protecció de Dades de MailTeck',
  privacyPoliciesConditionsFinalidadIntro: 'Gestionar i tramitar els serveis contractats',
  privacyPoliciesConditionsFinalidadParrafo1: 'GRUP MAILCOMMS tractarà les dades facilitades com a part de l\'execució d\'un contracte que heu subscrit o que la vostra empresa ha subscrit a fi de gestionar i tramitar els que s\'hi pacten, a més de donar suport a l\'aplicació CertySign.',
  privacyPoliciesConditionsFinalidadParrafo2: 'GRUP MAILCOMMS només tractarà les dades facilitades amb els objectius descrits anteriorment i aquestes dades no es tractaran ulteriorment de manera incompatible amb els objectius esmentats. Les dades personals proporcionades es conservaran mentre es mantingui la relació contractual.',
  privacyPoliciesConditionsLegitimacionIntro: 'Execució del contracte',
  privacyPoliciesConditionsLegitimacionParrafo1: 'Heu facilitat les dades, o les ha facilitat la vostra empresa, a través dels canals establerts per poder donar-vos accés a l\'aplicació CertySign com a conseqüència del contracte de prestació de serveis subscrit.',
  privacyPoliciesConditionsDestinatariosIntro: 'No se cedeixen a tercers',
  privacyPoliciesConditionsDestinatariosParrafo1: 'Per exigència legal, quan ho exigeixi alguna norma.',
  privacyPoliciesConditionsDerechosIntro: 'Podeu exercir els seus drets en la forma i els terminis previstos legalment.',
  privacyPoliciesConditionsDerechosElem1ListaTitulo: 'Confirmació general:',
  privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Qualsevol persona té dret a obtenir confirmació sobre si GRUP MAILCOMMS està tractant dades personals relacionades amb ella.',
  privacyPoliciesConditionsDerechosElem2ListaTitulo: 'Drets ARCO:',
  privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'Les persones interessades tenen dret a accedir a les seves dades personals, així com a sol·licitar la rectificació de les dades inexactes o, si escau, sol·licitar la seva supressió quan, entre altres motius, les dades ja no siguin necessàries per als objectius amb què es van recopilar.',
  privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Limitació del tractament:',
  privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'En determinades circumstàncies, els interessats podran sol·licitar la limitació del tractament de les seves dades. En aquests casos, les conservarem per a l\'exercici o la defensa de reclamacions.',
  privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Oposició al tractament:',
  privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'En determinades circumstàncies o per motius relacionats amb la seva situació particular, els interessats es podran oposar al tractament de les seves dades. Per tant, el GRUP MAILCOMMS deixarà de tractar les dades, excepte per motius legítims imperiosos, o per a l\'exercici o la defensa de possibles reclamacions.',
  privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Portabilitat de les dades:',
  privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'En determinades circumstàncies i per motius relacionats amb la seva situació particular, els interessats podran sol·licitar rebre les dades personals facilitades en el formulari de contacte en un format estructurat d\'ús comú i llegible per màquina, així com transmetre aquestes dades a un altre responsable del tractament sense impediments.',
  privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Reclamació davant organisme públic:',
  privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'En cas que considereu vulnerat el vostre dret a la protecció de dades personals, podreu interposar una reclamació davant l\'Agència Espanyola de Protecció de Dades',
  privacyPoliciesConditionsDerechosAEPDTitle: 'AEPD',
  privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Canal per exercir els drets:',
  privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Els interessats podran exercir els drets que la normativa els reconeix davant i en contra de cadascun dels responsables, davant l\'adreça habilitada com a DPO a l\'apartat "Responsable".',
  privacyPoliciesConditionsInformacionAdicionalIntro: 'Més informació sobre Protecció de Dades.',
  privacyPoliciesConditionsInformacionAdicionalLista1: 'Les dades personals s\'han obtingut dels canals que les empreses del GRUP MAILCOMMS han establert per poder gestionar i els serveis que heu contractat o que ha contractat la vostra empresa.',
  privacyPoliciesConditionsInformacionAdicionalLista2: 'Les categories de dades que es tractaran seran les dades facilitades per al desenvolupament de cada servei contractat.',
  privacyPoliciesConditionsInformacionAdicionalLista3: 'GRUP MAILCOMMS no tracta dades especialment protegides.',
  privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTecK, S.A. Política de Seguretat i Privacitat de la Informació basada en la norma ISO 27001 -> Més informació.',
  privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Customer Communication Tecknalia, S.L. Política de Seguretat i Privacitat de la Informació basada en la norma ISO 27001 -> Més informació.',
  privacyPoliciesConditionsPoliticaInformacionTituloH: 'ANNEX I: POLÍTICA DEL SISTEMA DE GESTIÓ DE SEGURETAT I PRIVACITAT DE LA INFORMACIÓ.',
  privacyPoliciesConditionsPoliticaInformacionP1: 'Aquesta Política de Seguretat i Privacitat de la Informació mostra el compromís de la Direcció, dota a l\'organització de recursos, responsabilitats i procediments que permetin garantir el compliment de la normativa vigent, així com la integritat, confidencialitat i disponibilitat de la informació i els sistemes, i és crucial per a la seguretat, privacitat i continuïtat de la nostra organització i els nostres clients.',
  privacyPoliciesConditionsPoliticaInformacionP2: 'El Grup MailTeck, conscient que la seguretat i privacitat de la informació relativa als nostres clients és un recurs de gran valor, ha establert un Sistema de Gestió de la Seguretat i Privacitat de la Informació d\'acord amb els requisits de les normes ISO/IEC 27001:2013 i ISO/IEC 27701:2019 per garantir els nivells de Seguretat requerits, la Continuïtat dels serveis prestats i el Compliment legal en el tractament de dades de caràcter personal, minimitzant així els riscs derivats d\'aquesta gestió.',
  privacyPoliciesConditionsPoliticaInformacionCCTP2: 'Custommer Comms, conscient que la seguretat i privacitat de la informació relativa als nostres clients és un recurs de gran valor, ha establert un Sistema de Gestió de la Seguretat i Privacitat de la Informació d\'acord amb els requisits de les normes ISO/IEC 27001:2013 i ISO/IEC 27701:2019 per garantir els nivells de Seguretat requerits, la Continuïtat dels serveis prestats i el Compliment legal pel que fa al tractament de dades de caràcter personal, minimitzant així els riscs derivats d\'aquesta gestió.',
  privacyPoliciesConditionsPoliticaInformacionP3: 'L\'objectiu de la Política de Seguretat i Privacitat és fixar el marc d\'actuació necessari per protegir els recursos d\'informació davant amenaces, internes o externes, deliberades o accidentals, a fi de garantir el compliment de la confidencialitat, i la integritat i disponibilitat de la informació.',
  privacyPoliciesConditionsPoliticaInformacionP4: 'L\'eficàcia i aplicació del Sistema de Gestió de la Seguretat i Privacitat de la Informació és responsabilitat directa del Comitè de Seguretat i Privacitat de la Informació, que és responsable de l\'aprovació, difusió i compliment de la present Política de Seguretat i Privacitat. En el seu nom i representació s\'ha nomenat un responsable del Sistema de Gestió de la Seguretat i Privacitat de la Informació, que posseeix l\'autoritat suficient per desenvolupar un paper actiu en el Sistema de Gestió de la Seguretat i Privacitat de la Informació, i supervisa la seva implantació, desenvolupament i manteniment.',
  privacyPoliciesConditionsPoliticaInformacionP5: 'Addicionalment, i per donar compliment a la normativa vigent de protecció de dades, s\'ha nomenat un delegat de Protecció de Dades (DPO) amb l\'objectiu de garantir la seguretat i privacitat de les dades de caràcter personal, especialment tenint en compte l\'activitat de la companyia.',
  privacyPoliciesConditionsPoliticaInformacionP6: 'El Comitè de Seguretat i Privacitat de la Informació procedirà a desenvolupar i aprovar la metodologia d\'anàlisi de riscs utilitzada en el Sistema de Gestió de la Seguretat i Privacitat de la Informació.',
  privacyPoliciesConditionsPoliticaInformacionP7: 'Tota persona que desenvolupi una activitat que pugui, de forma directa o indirecta, veure\'s afectada pels requisits del Sistema de Gestió de la Seguretat i Privacitat de la Informació està obligada al compliment estricte de la Política de Seguretat i Privacitat.',
  privacyPoliciesConditionsPoliticaInformacionP8: 'Grup MailTeck implantarà totes les mesures necessàries per complir amb la normativa aplicable en matèria de seguretat i privacitat en general i de seguretat informàtica, relativa a la política informàtica, a la seguretat d\'edificis i instal·lacions i al comportament de treballadors i terceres persones associades a Grup MailTeck en l\'ús de sistemes informàtics i el tractament de dades personals. Les mesures necessàries per garantir la seguretat i privacitat de la informació mitjançant l\'aplicació de normes, procediments i controls hauran de permetre garantir la confidencialitat, integritat i disponibilitat de la informació, essencials per:',
  privacyPoliciesConditionsPoliticaInformacionCCTP8: 'Custommer Comms implantarà totes les mesures necessàries per complir amb la normativa aplicable en matèria de seguretat i privacitat en general i de seguretat informàtica, relativa a la política informàtica, a la seguretat d\'edificis i instal·lacions i al comportament de treballadors i terceres persones associades a Custommer Comms en l\'ús de sistemes informàtics i el tractament de dades personals. Les mesures necessàries per garantir la seguretat i privacitat de la informació mitjançant l\'aplicació de normes, procediments i controls hauran de permetre garantir la confidencialitat, integritat i disponibilitat de la informació, essencials per:',
  privacyPoliciesConditionsPoliticaInformacionLista1: 'Complir amb la legislació, reglamentació i normativa vigent en matèria de privacitat i dels sistemes d\'informació, i amb tots els requisits contractuals en matèria de protecció de dades, així com els que consideri oportuns a efectes d\'assolir una millora contínua de l\'organització.',
  privacyPoliciesConditionsPoliticaInformacionLista2: 'Garantir la confidencialitat de les dades gestionades per Grup MailTeck.',
  privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'Garantir la confidencialitat de les dades gestionades per Custommer Comm.',
  privacyPoliciesConditionsPoliticaInformacionLista3: 'Garantir la disponibilitat dels sistemes d\'informació, tant en els serveis oferts als clients com en la gestió interna.',
  privacyPoliciesConditionsPoliticaInformacionLista4: 'Garantir la capacitat de resposta davant situacions d\'emergència, restablint el funcionament dels serveis crítics en el menor període de temps possible.',
  privacyPoliciesConditionsPoliticaInformacionLista5: 'Evitar alteracions indegudes en la informació.',
  privacyPoliciesConditionsPoliticaInformacionLista6: 'Promoure la conscienciació i formació en seguretat i privacitat de la informació.',
  privacyPoliciesConditionsPoliticaInformacionLista7: 'Impulsar la millora contínua del sistema de gestió de seguretat i privacitat de la informació i participar en aconseguir-la.',
  cookiesPoliciesIntroText: 'Aquest lloc web utilitza galetes per millorar la vostra experiència mentre hi navegueu. Podeu canviar aquesta configuració en qualsevol moment. Les galetes classificades com a necessàries s\'emmagatzemen en el vostre navegador i són essencials per al funcionament de les funcions bàsiques del lloc web. Les galetes de tercers s\'emmagatzemaran en el vostre navegador només amb el vostre consentiment i ens ajuden a analitzar i comprendre com s\'utilitza aquest lloc web. Podeu optar per no rebre aquestes galetes, però l\'exclusió voluntària d\'alguna d\'aquestes galetes pot fer que algunes funcions deixin d\'estar disponibles. Per obtenir informació sobre eliminar les galetes, si us plau, consulteu la funció d\'ajuda del vostre navegador.',
  cookiesPoliciesNecessaryCookies: 'Galetes necessàries',
  cookiesPoliciesNecessaryCookiesText: 'Aquest tipus de galetes són absolutament essencials perquè el lloc web funcioni correctament. Aquesta categoria només inclou galetes que garanteixen funciones bàsiques i característiques de seguretat del lloc web, i no recopilen ni emmagatzemen cap informació personal sobre els usuaris del lloc web.',
  cookiesPoliciesUnnecessaryCookies: 'Galetes no necessàries',
  cookiesPoliciesUnnecessaryCookiesText: 'Les galetes que poden no ser particularment necessàries perquè el lloc web funcioni i s\'utilitzen específicament per recopilar dades personals de l\'usuari a través d\'anàlisis, anuncis i altres continguts integrats es denominen galetes no necessàries. És obligatori obtenir el consentiment de l\'usuari abans d\'executar aquestes galetes en el vostre lloc web.',
  cookiesPoliciesGoogleAnalyticsCookies: 'Galetes de Google Analytics',
  cookiesPoliciesGoogleAnalyticsCookiesText: 'Per obtenir més informació respecte a aquest tipus de galetes, podeu consultar el document d\'Ús de les galetes de Google Analytics en llocs web',
  cookiesPoliciesGoogleAnalyticsCheckboxText: 'Mantenir galetes actives',
  cookiesNotificationBarText: 'Utilitzem galetes, tant pròpies com de tercers, per mesurar les visites, garantir la funcionalitat del lloc web i analitzar la navegació pel lloc web. A continuació podeu acceptar les galetes o configurar/rebutjar el seu ús. Podeu obtenir més informació sobre la titularitat del web, el responsable del tractament de les galetes i les seves dades de contacte a la nostra',
  cookiesNotificationBarConfig: 'Configura',
  cookiesPoliciesModalIntro: 'Introducció',
  cookiesPoliciesModalIntroP1: 'Aquest lloc web utilitza galetes i/o tecnologies similars que emmagatzemen i recuperen informació quan navegueu per millorar i optimitzar l\'experiència d\'usuari.',
  cookiesPoliciesModalIntroP2: 'En general, aquestes tecnologies poden servir per a finalitats molt diverses com, per exemple, reconèixer algú com a usuari, obtenir informació sobre hàbits de navegació, o personalitzar la forma en què se mostra el contingut.',
  cookiesPoliciesModalIntroP3: 'De conformitat amb el Reial Decret Llei 13/2012 i la seva afectació a l\'article 22.2 de la Llei de Serveis de la Societat de la Informació i Comerç Electrònic, mitjançant la present Política s\'us informa sobre les diferents galetes que CUSTOMER COMMUNICATIONS TECKNALIA, S.L. recopila a través dels diferents mitjans o canals de comunicació, pròpies o de tercers, exemptes o no del deure d\'informar, i els diferents mitjans a través dels quals les podeu rebutjar, permetre o bloquejar.',
  cookiesPoliciesModalIntroP4: 'A continuació es detalla informació sobre: què són les "Galetes", quina tipologia utilitza aquest lloc web, com es poden desactivar en un navegador i com es pot bloquejar específicament la instal·lació de Galetes de tercers.',
  cookiesPoliciesModalResponsibility: 'Qui és el responsable de l\'ús de les galetes en aquest lloc web?',
  cookiesPoliciesModalResponsibilityP1: 'El responsable de l\'ús de les galetes és CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (en endavant, \'CCOMMS\'), amb NIF número B-86414000 i domicili social a Leganés (Madrid), Avenida de la Recomba, 14, degudament inscrita en el Registre Mercantil de Madrid, al Tom 29.801, Foli 98, Secció 8a, Full número M-536210.',
  cookiesPoliciesModalResponsibilityP2: 'Correu electrònic del delegat de Protecció de Dades.',
  cookiesPoliciesModalWhatIsCookie: 'Què és una galeta?',
  cookiesPoliciesModalWhatIsCookieP1: 'Una galeta és una dada, fitxer o dispositiu d\'emmagatzemament i recuperació de dades (DARD) que un navegador web emmagatzema de forma automàtica en el dispositiu (smartphone, tauleta o televisió connectada) d\'un usuari quan aquest últim accedeix a pàgines web o comptes de xarxes socials, o rep un correu electrònic.',
  cookiesPoliciesModalWhatIsCookieP2: 'Les galetes permeten emmagatzemar i recuperar de forma automatitzada dades sobre l\'ús que s\'ha fet dels mitjans digitals esmentats i de l\'equip o dispositiu des del qual s\'accedeix a aquests mitjans.',
  cookiesPoliciesModalWhatIsCookieP3: 'Una galeta no és més que dades que s\'emmagatzemen a l\'ordinador de l\'usuari i, per tant, no és programari ni fragments de codi; només és un conjunt de dades. Així doncs, en principi les galetes no poden transmetre i executar virus, ni instal·lar programari maliciós, com ara troians o programes d\'espionatge.',
  cookiesPoliciesModalCookieTypes: 'Quins tipus de galetes hi ha al lloc web i la seva finalitat',
  cookiesPoliciesModalCookiesInstall: 'Quines galetes es poden instal·lar al web de CertySign?',
  cookiesPoliciesModalCookiesInstallP1: 'L\'accés al web de CertySign implica que es poden instal·lar les galetes pròpies i/o de tercers que se detallen en el següent quadre:',
  cookiesPoliciesModalBrowserConfigCookies: 'Com configurar les galetes en el navegador',
  cookiesPoliciesModalBrowserConfigCookiesP1: 'Teniu l\'opció de no rebre galetes o de rebre informació sobre la seva fixació mitjançant la configuració del vostre navegador a aquest efecte. Podeu gestionar totes les galetes, excepte les de Flash, des del vostre navegador, normalment a la secció \'Opcions\' o \'Preferències\'.',
  cookiesPoliciesModalBrowserConfigCookiesP2: 'Podeu desactivar-les del tot i que el vostre navegador no permeti que se n\'instal·li cap (impossibilitant així que podeu fer ús de totes les funcions del lloc web), o només les que provinguin de determinats webs, fet que us permetrà poder gaudir de les funcions avançades dels webs que respectin la privacitat de les vostres dades i excloure aquelles que no ho facin.',
  cookiesPoliciesModalBrowserConfigCookiesP3: 'En los següents enllaços us facilitem els accessos a les principals pàgines de suport:',
  cookiesPoliciesModalBrowserConfigCookiesP4: 'Així mateix, l\'Usuari pot oposar-se a l\'ús de determinades galetes mitjançant els següents serveis:',
  cookiesPoliciesModalBrowserConfigCookiesLI1: 'Bloqueig de galetes a Chrome',
  cookiesPoliciesModalBrowserConfigCookiesLI2: 'Bloqueig de galetes a Safari',
  cookiesPoliciesModalBrowserConfigCookiesLI3: 'Bloqueig de galetes a Firefox',
  cookiesPoliciesModalBrowserConfigCookiesLI4: 'Bloqueig de galetes a Internet Explorer',
  cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
  cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
  cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
  cookiesPoliciesModalUnavailableCookies: 'Què passa si es deshabiliten les galetes?',
  cookiesPoliciesModalUnavailableCookiesP1: 'Si rebutja les galetes podrà seguir navegant pel nostre Lloc Web, tot i que l\'ús d\'alguns dels seus serveis podrà ser limitat i, per tant, la vostra experiència podrà resultar menys satisfactòria.',
  cookiesPoliciesModalUnavailableCookiesP2: 'El rebuig, bloqueig o no permissibilitat de les galetes descrites a la present Política pot afectar a la correcta navegació pels continguts reproduïts en el lloc web.',
  cookiesPoliciesModalAcceptCookies: 'Acceptació de la política de galetes',
  cookiesPoliciesModalAcceptCookiesP1: 'Si premeu el botó \'ACCEPTA GALETES\' entendrem que accepteu l\'ús de galetes.',
  cookiesPoliciesModalAcceptCookiesP2: 'No obstant això, amb cada inici de sessió es mostrarà la Política de galetes a la part inferior de qualsevol pàgina del portal amb l\'objectiu que en sigueu conscient.',
  cookiesPoliciesModalUpgradeCookies: 'Actualitzem la nostra política de galetes?',
  cookiesPoliciesModalUpgradeCookiesP1: 'És possible que la informació proporcionada en aquesta Política de Galetes es pugui modificar a causa d\'actualitzacions o canvis en la política de tractament de galetes. Així mateix, l\'Agència Espanyola de Protecció de Dades podria dictar instruccions i/o recomanacions que la fessin variar.',
  cookiesPoliciesModalUpgradeCookiesP2: 'Per això us recomanem que reviseu aquesta política cada cop que accediu al nostre Lloc Web amb l\'objectiu d\'estar ben informat sobre com i perquè utilitzem les galetes.',
  cookiesPoliciesModalRecipientCookies: 'Qui són els destinataris de la informació i com afecta l\'ús de galetes a la protecció de dades personals?',
  cookiesPoliciesModalRecipientCookiesP1: 'Per obtenir més informació sobre el tractament de les vostres dades, podeu consultar la ',
  cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Política de Protecció de Dades',
  cookiesPoliciesModalContactInfoCookies: 'Informació de contacte',
  cookiesPoliciesModalContactInfoCookiesP1: 'Per a qualsevol dubte, aclariment i/o suggeriment sobre les galetes, si us plau, poseu-vos en en contacte a través del correu electrònic ',
  cookiesPoliciesModalDatatableCookieType: 'Tipus de Galeta',
  cookiesPoliciesModalDatatableTreatmentTerm: 'Termini del Tractament',
  cookiesPoliciesModalDatatableTypeAnalytics: 'Analítiques',
  cookiesPoliciesModalDatatableTypeSelf: 'Pròpies',
  cookiesPoliciesModalDatatableTypeAnalyticsD: 'Cada cop que un Usuari visita un Servei, una eina d\'un proveïdor extern genera una Galeta analítica a l\'ordinador de l\'Usuari. Aquesta Galeta, que només es genera en la visita, servirà en properes visites a CCOMMS per identificar el visitant de forma anònima.',
  cookiesPoliciesModalDatatableTypeAnalyticsF: 'Permetre la identificació anònima dels Usuaris navegants a través de la \'Galeta\' (identifica navegadors i dispositius, no persones) i, per tant, el recompte aproximat del número de visitants i la seva tendència en el temps. Identificar de forma anònima els continguts més visitats i més atractius per als Usuaris. Saber si l\'Usuari que hi està accedint és nou o repeteix visita. Excepte si l\'Usuari decideix enregistrar-se a CCOMMS, la \'Galeta\' mai anirà associada a cap dada de caràcter personal que el pugui identificar directament. Aquestes Galetes només s\'utilitzaran amb propòsits estadístics que ajudin a l\'optimització i millora de l\'experiència dels Usuaris en el lloc web.',
  cookiesPoliciesModalDatatableTypeAnalyticsT: 'Persistents',
  cookiesPoliciesModalDatatableTypeSelfD: 'Aquest tipus de Galetes permeten ampliar la informació mostrada a cada Usuari anònim en els Serveis de CCOMMS.',
  cookiesPoliciesModalDatatableTypeSelfF: 'Per exemple, s\'emmagatzema la durada o freqüència de visualització de les diferents seccions, la interacció amb aquestes seccions, o els patrons de navegació i/o comportaments de l\'Usuari.',
  cookiesPoliciesModalDatatableTypeSelfT: 'Persistents',
  eventInfo: 'Informació de l\'esdeveniment',
  manual: 'Manual',
  guia: 'Guia',
  supportLoggedPhonePrefferedMessage: '(Suport d\'usuari ja connectat - Preferit Telèfon) ',
  supportLoggedNotPhoneMessage: 'No marca telèfon',
  supportLoggedUserMessage: '(Suport d\'usuari ja connectat) ',
  supportNotLoggedUserWebMessage: '(Inici de sessió - Suport via web) L\'usuari sol·licita assistència per recuperar la contrasenya',
  supportNotLoggedUserNotAvailableMessage: 'No disponible',
  supportNotLoggedUserC2CMessage: '(Inici de sessió - Suport via C2C) L\'usuari sol·licita assistència telefònica per recuperar la contrasenya',
  processA4NotSupported: 'La mida del document no ha de superar el format A4, les 389 pàgines ni els 15mb.',
  cookiesGAMessage: 'Mesura interna amb Google Analytics. Aquestes galetes emmagatzemen un identificador únic per reconèixer l\'usuari quan visiti el lloc web en futures ocasions.',
  cookiesGAFGenericMessage: 'Mesura interna amb Google Analytics.',
  cookiesCookieMessage: 'Galeta',
  cookiesNecessaryCookieDescription: 'Aquesta galeta és necessària i s\'utilitza per establir la preferència de l\'usuari respecte de la política de galetes.',
  documentHtmlRule: 'Tipus de fitxer acceptat - HTML',
  rulesFilesSizePostal: 'L\'arxiu ha de pesar com a màxim 15Mb',
  rulesFilesSizeEmail: 'L\'arxiu ha de pesar com a màxim 14Mb',
  rulesFilesSizeMultiple: 'La mida màxim total dels arxius és 14Mb',
  rulesFilesSizeHtml: 'L\'arxiu ha de pesar com a màxim {fileSize}Mb',
  rulesFilesExtensionHtml: 'L\'arxiu ha de ser un HTML',
  group_cannot_be_empty: 'El grup :group_name: no pot estar buit',
  language: 'Idioma',
  defaultLanguage: 'Idioma per defecte',
  transactionDownloadPee: 'Visualitza PEE',
  transactionTracking: 'Seguimiento de envío',
  documentOrder: 'Establir l’ordre de fitxers',
  yes: 'Sí',
  no: 'No',
  notifications: 'Notificacions',
  activateNotifications: 'Activar',
  deactivateNotifications: 'Desactivar',
  selectCategoryNotifications: 'Seleccionar acció per a notificacions',
  activateCategoryNotifications: 'Activar totes',
  deactivateCategoryNotifications: 'Desactivar totes',
  customCategoryNotifications: 'Personalitzar',
  enabledNotifications: 'Notificacions activades',
  disabledNotifications: 'Notificacions desactivades',
  customNotifications: 'Notificacions personalitzades',
  selectNotifications: 'Seleccionar configuració de notificacions',
  enabledNotificationsDescriptionText: 'Cada esdeveniment realitzat generarà una notificació. Podreu triar entre rebre un correu electrònic per cada una d’elles, o un informe diari amb les notificacions agrupades.',
  disabledNotificationsDescriptionText: 'No rebreu cap notificació relacionada amb els estats dels enviaments realitzats.',
  customNotificationsDescriptionText: 'Podreu seleccionar de quins processos voleu rebre notificacions sobre els seu estat i de quins processos no.',
  enabledNotificationsConfigurationText: 'Configuració',
  enabledNotificationsConfigurationRealTime: 'Notificacions en temps real',
  enabledNotificationsConfigurationGrouped: 'Notificacions agrupades',
  selectEnabledNotifications: 'Seleccionar tipus de notificacions',
  enabledNotificationsRealTimeDescriptionText: 'Rebeu un correu electrònic al moment per cada esdeveniment realitzat.',
  enabledNotificationsGroupedDescriptionText: 'Rebreu un correu electrònic en l’horari seleccionat amb l’informe de tots els esdeveniments realitzats durant el dia anterior.',
  enabledNotificationsScheduleRangeDescriptionText: 'Notificar durant l’horari següent.',
  enabledNotificationsScheduleDescriptionText: 'Notificar només en l’horari següent.',
  chooseNotificationsRange: 'Trieu com i quan voleu rebre les notificacions.',
  enabledNotificationsCustomText: 'Personalització de notificacions',
  realTimeNotificationsDescription: 'Les notificacions s’envien en temps real. Per cada esdeveniment realitzat, s’envia un correu electrònic per avisar del seu estat.',
  languageAndTimezone: 'Idioma',
  defaultTimeZone: 'Franja horària per defecte',
  user_process_notifications_updated: 'Notificacions actualitzades',
  changeSectionConfirmation: 'Canvis pendents de guardar',
  changeSectionConfirmationText: 'Heu realitzat canvis que estan pendents de ser desats. Si desitgeu guardar-los ara, feu clic en',
  groupedNotificationsDescription: 'Les notificacions s’envien, mitjançant un informe adjunt que conté un llistat amb totes les transaccions i l’últim esdeveniment registrat en cadascuna d’elles, en l’horari seleccionat.',
  groupedNotificationsReportTime: 'A quina hora voleu rebre l’informe',
  discard: 'Descartar',
  cost_center_updated: 'Centre de cost actualitzat amb èxit',
  loginInfo: 'Informació d’inici de sessió',
  lastLoginSuccess: 'Últim inici de sessió',
  lastLoginError: 'Últim intent d’accés fallit',
  noRecord: 'Sense registre',
  //New translations 221130
  charLimitLength: 'Camp ha de tenir {limit} caràcters o menys',
  requiredLastName: 'Cognoms requerits',
  optional: 'Opcional',
  availableVariables: 'Variables disponibles',
  twoFactorText: 'L’usuari requereix doble factor d’autenticació. Per iniciar sessió, introdueixi el codi que pot visualitzar en la seva aplicació de gestió d’accessos multifactor.',
  mfa_configuration_required: 'L’usuari requereix doble factor d’autenticació. Per configurar-lo, llegeixi el QR des de la seva aplicació de gestió d’accessos multifactor. Posteriorment, introdueixi el codi en el quadre inferior',
  code: 'Codi',
  codeRequired: 'El codi és requerit per iniciar sessió',
  mfa_code_invalid: 'El codi no és correcte',
  communicationContent: 'Contingut de la comunicació',
  uploadFiles: 'Incloure documents',
  modifyFiles: 'Modificar documents',
  fileResume: '{nfiles} arxiu(s) ({size})MB en total',
  moreThan1City: 'El codi postal correspon a més d’una població, consulti el desplegable o editi el camp manualment',
  shipmentDataSectionDescription: 'En aquesta secció s’afegeixen els continguts de la comunicació. Els documents han de ser de tipus PDF i mida A4',
  zipCodeFormat: 'Ha d’introduir un codi postal vàlid',
  deleteRecipient: 'Eliminar aquest destinatari',
  mobileAddRecipientDescription: 'Més d’un destinatari?',
  errorFilesTotalSizeExceeded: 'La mida total dels fitxers no pot superar els 15 MB',
  fileExtensionNotAccepted: 'L’extensió del fitxer no és vàlida. (Les extensions acceptades son= {acceptedExtensions})',
  nameAndSurnameLength: 'El nom i els cognoms no poden superar els 99 caràcters',
  batchModeTitle: 'Enviament per lots',
  batchModeCSVStep: 'Destinataris',
  batchModeAttachmentsStep: 'Fitxers requerits',
  batchModeDownloadSampleCSV: 'Baixar fitxer exemple',
  batchModeExtraCSVStepIntroduction: 'En primer lloc, ha de pujar un fitxer <strong>CSV</strong> o <strong>XLS</strong> amb les dades dels enviaments. A continuació, pot baixar un fitxer d’exemple que inclou els camps necessaris.',
  batchModeExtraCSVStepIntroduction2: 'Una vegada tingui preparat el fitxer, pot afegir-lo fent clic o bé arrossegant-lo a la zona habilitada per a això. Recordi que el fitxer <strong>no pot contenir línies buides ni errors</strong> en cap dels destinataris.',
  batchModeExtraCSVStepIntroduction3: 'Una vegada validada l’estructura del fitxer, s’habilitarà el botó <strong>Continuar</strong> i podrà seguir amb el procés.',
  batchModeExtraFilesStepIntroduction: 'En aquest pas s’afegiran els recursos necessaris per realitzar l’enviament a cada destinatari. <br><br>S’han detectat un total de <b>{count}</b> fitxers. Pot afegir-los fent clic o bé arrossegant-los a la zona habilitada per a això. <br><br>Recordi que la suma de fitxers de cada enviament no pot superar els <b>{fileSizeLimit} MB</b> i l’enviament té una mida màxima general de <b>{totalSizeLimit} GB</b>.',
  extraFilesTableHeaderFileName: 'Nom de fitxer',
  extraFilesTableHeaderStatus: 'Estat',
  extraFilesTableStatusNotFound: 'Pendent de pujar',
  extraFilesTableStatusProcessing: 'Processant',
  extraFilesTableStatusUploaded: 'OK',
  extraFilesTableStatusUploadedCorrectly: 'Processat',
  extraFilesTableHeaderActions: 'Accions',
  batchModePreviewStep: 'Previsualitzar i enviar',
  batchModeStep1RemoveFile: 'Esborrar fitxer',
  batchModeCSVTableTitle: 'El fitxer <strong>{filename}</strong> conté <strong>{nOfRecipients}</strong> destinataris.',
  batchModeCSVTableSubtitle: 'A continuació, disposa de la previsualització dels primers destinataris del fitxer, faci clic en Veure més per ampliar informació.',
  batchModeCSVTableSubtitleNoData: 'El fitxer seleccionat no conté cap destinatari.',
  batchModeNoDataTitle: 'Cap fitxer seleccionat. Una vegada afegit, disposarà dels primers destinataris del fitxer.',
  dropZoneText: 'Faci clic o arrossegui aquí els seus fitxers',
  dropZoneTextSingle: 'Faci clic o arrossegui aquí el seu fitxer',
  batchModeDialogTitle: 'Agregar destinataris',
  batchModeDialogManualColTitle: 'De forma manual',
  batchModeDialogManualColDescription: 'Si desitja realitzar un o més enviaments de forma individual, triï aquesta opció.',
  batchModeDialogBatchColTitle: 'Enviament per lots',
  batchModeDialogBatchColDescription: 'Si vol realitzar enviaments mitjançant un fitxer csv o xls amb diversos destinataris i documents d’una forma senzilla, triï aquesta opció.',
  batchModeDialogBatchBtnManual: 'Enviament individual',
  batchModeDialogBatchBtnBatch: 'Enviament per lots',
  batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> fitxers pendents d’afegir',
  batchModeResourceTableAllFilesAdded: 'S’han afegit tots els fitxers necessaris',
  // batchModeResourceTableStatus: 'Estat actual= <b>$currentFiles$</b>/$nOfRequiredFiles$',
  batchModeResourceTableExpandText: 'Faci clic per veure més detalls',
  batchModeResourceTableFilenameText: 'Nom de fitxer',
  batchModeResourceTableFoundOnNRows: 'destinataris afectats',
  batchModeResourceTableErrorFilenameInvalid: 'Un o més dels fitxers que s’han intentat afegir no es corresponen amb els fitxers esperats.',
  batchModeResourceTableErrorFileSizeLimit: 'La mida del fitxer és superior a {size} MB',
  batchModeResourceTableErrorTotalFileSizeLimit: 'El total dels fitxers pujats no pot superar {size} GB',
  batchModeResourceTableInfoFileReplaced: 'S’ha reemplaçat el fitxer {filename}.',
  seeMore: 'Veure’n més',
  seeLess: 'Veure’n menys',
  pending: 'Pendent',
  batchModePreviewText: 'Una vegada sigui processat, podrà visualitzar les transaccions en la vista corresponent i rebrà un correu electrònic amb la informació de cada enviament, així com l’identificador de transacció associada a cada destinatari.',
  batchModePreviewText1: 'En el cas que no pugui realitzar-se algun dels enviaments, al correu electrònic s’especificarà quins d’ells no han estat processats amb el motiu de l’error.',
  csvError: 'No s’ha pogut processar el fitxer',
  csvErrorDetailStructure: 'L’estructura del fitxer és errònia.',
  csvErrorDetailIncorrectFields: 'Els camps no coincideixen amb les dades necessàries del procés',
  csvErrorDetailIncorrectExtensions: 'Hi ha extensions de fitxer invàlides a les files següents:',
  batchModeStep2SpaceLeft: 'Espai utilitzat',
  batchModeStep2PendingFiles: 'Fitxers pendents',
  launchDataKoBatchMode: 'Les dades proporcionades no són vàlides',
  launchDataKoBatchModeStep1: 'No s’ha seleccionat cap fitxer',
  launchDataOkBatchModeStep1: 'L’estructura del fitxer és correcta, pot continuar amb el procés',
  launchDataKoBatchModeStep2: 'Afegeixi els fitxers necessaris',
  launchDataOkBatchModeStep2: 'S’han afegit els fitxers necessaris, pot continuar amb el procés',
  launchDataOkBatchMode: 'Les dades són correctes, pot continuar amb el procés',
  batchModeProcessLaunchedStep1: 'Iniciant el procés de registre',
  batchModeProcessLaunchedStep2: 'Pujant recursos necessaris per als enviaments',
  batchModeProcessLaunchedStep3: 'Pujant fitxer de destinataris',
  batchModeProcessLaunchedStep4: 'Procés de registre finalitzat',
  transactionsProcessedBatchMode: 'Enviament per lots registrat correctament',
  transactionProcessingBatchMode: 'Processant enviament per lots…',
  transactionProcessingBatchModeError: 'Error en el processament de l’enviament per lots',
  transactionProcessingBatchModeErrorDescription: 'Hi ha hagut un error en el processament del lot, si us plau posi’s en contacte amb suport.',
  csvErrorDialogDescription: 'Recordi que ha de pujar un fitxer <b>CSV</b> o <b>XLS</b> i que aquest <strong>no pot contenir línies buides ni errors</strong> en cap dels destinataris.',
  extraFileError: 'No s’ha pogut afegir el fitxer',
  extraFileErrorDialogDescription: 'Recordi que el límit de mida per transacció és de <b>{fileSizeLimit} MB</b> i l’enviament complet del lot té una mida màxima de <b>{totalSizeLimit} GB</b>.',
  extraFileErrorTransactionSizeLimit: 'S’ha superat la mida màxima per a l’enviament en els registres següents = {rows}',
  htmlEditorInfoParagraph1: 'En aquest pas, es personalitzarà el contingut del correu electrònic que serà enviat a cadascun dels destinataris.',
  htmlEditorInfoParagraph3: 'Pot seleccionar un mode d’edició:',
  htmlEditorInfoBullet1: '<span>Selecció de plantilla</span>. Seleccioni una plantilla i realitzi els ajustaments necessaris des de l’editor.',
  htmlEditorInfoBullet2: '<span>HTML personalitzat</span>. Pugi un fitxer HTML personalitzat i realitzi els ajustaments necessaris des de l’editor.',
  htmlEditorButtonUploadFile: 'PUJAR HTML',
  htmlEditorDragAndDrop: 'Faci clic aquí o arrossegui els fitxers adjunts al correu electrònic.',
  batchModeExtraCSVStepIntroductionVariables: 'Recordi que té a la seva disposició les variables següents per al seu ús en els camps de transacció:',
  batchModeContactId: 'Referència de destinatari',
  batchModeContactName: 'Nom de destinatari',
  batchModeContactSurname: 'Cognoms de destinatari',
  htmlEditorTemplateSelected: 'Plantilla seleccionada',
  htmlSubjectError: 'L’assumpte té més de <span>{subjectLimit}</span> caràcters',
  htmlEditorInfoParagraph4: 'Recordeu que la mida màxima total dels arxius és de <b>{totalSizeLimit} Mb</b>.',
  backToProcess: 'Torneu a processos',
  infoAndHelp: 'Informació i ajuda',
  modelEmailHtmlTemplateSelector: 'Seleccioneu la plantilla',
  modelEmailEmailBodyNotHtml: "L’arxiu seleccionat no és html",
  templateSelectorDefaultTemplate: 'Per defecte',
  templateSelectorCustomTemplate: 'Personalitzada',
  templateSelectorNoLogoTemplate: 'Sense logotip',
  filesExcedingSizeLimit: 'Arxius que superen el límit de mida',
  emailAttachmentsSectionTitle: 'Fitxers adjunts del correu electrònic',
  emailContent: 'Contingut del correu electrònic',
  emailSubject: 'Assumpte del correu electrònic',
  multipleFileExtensionError: "Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})",
  backLostChangesHTMLText: "Si torna enrere, perdre els canvis en la plantilla de correu electrònic així com els fitxers adjunts. <br>Està segur de <b>tornar</b> al pas anterior?",
  backLostChangesHTMLConfirmBack: "Tornar al pas anterior",
  backLostChangesHTMLConfirmContinue: "Continuar amb l'enviament",
  filePasswordProtectedOrDamage: "Fitxer no vàlid. Comproveu que el fitxer no estigui malmès o protegit amb contrasenya i torneu-ho a provar",
  processStepperDigitalSignatureConfigurationTitle: 'Configuració',
  processStepperDigitalSignatureConfigurationDescription: 'Personalització de la cerimònia de signatura',
  processStepperDigitalSignatureSignersTitle: 'Signants',
  processStepperDigitalSignatureSignersDescription: 'Configuració dels signants i experiència de signatura',
  processStepperDigitalSignatureDocumentsTitle: 'Documents',
  processStepperDigitalSignatureDocumentsDescription: 'Selecció de documents i interacció dels signants',
  processStepperDigitalSignatureSummaryTitle: 'Confirmació i enviament',
  processStepperDigitalSignatureSummaryDescription: 'Revisió i confirmació de la cerimònia de signatura',
  transactionName: 'Nom de la transacció',
  transactionInterveners: 'Intervinents de la transacció',
  more: 'més',
  finished: 'Finalitzada',
  eventsInfo: 'Informació dels esdeveniments',
  transactionInvalidAlert: 'Durant la verificació de les dades d’entrada de la transacció, s’han detectat possibles errors que podrien impedir que s\'executi correctament. Previsiblement, s\'ha bloquejat la transacció. Haureu de llançar una nova transacció amb les dades d’entrada correctes.',
  transactionEntryData: 'Dades d’entrada de la transacció',
  goToSignature: 'Anar a la signatura',
  intervenersNotDetected: 'No s’han detectat intervinents',
  transactionEventsRegistered: 'Esdeveniments registrats en la transacció',
  aboutEvents: 'Sobre els esdeveniments',
  notEvents: 'No s’han registrat esdeveniments en la transacció.',
  notEventsRegistered: 'No s\'han registrat esdeveniments en la transacció. Si la transacció ha estat creada recentment, és possible que encara s\'estigui generant.',
  downloadCertificate: 'Descarregar certificat',
  notValid: 'No vàlida',
  transactionPending: 'En curs',
  refresh: 'Actualitzar',
  creation: 'Creació',
  transactionDigitalSignatureInfo: 'Informació de la transacció de signatura',
  reminders: 'Recordatoris',
  input: {
    clear: 'Netejar'
  },
  intervenerStartCeremony: 'Per començar el torn de signatura d’aquest intervinent, s’obrirà una nova pestanya. Recordeu que, si en la transacció hi hagués ordre de signatura, els intervinents previs han de haver completat els documents.',
  details: 'Detalls',
  front_renderer_builder_transaction_opened: 'Transacció de signatura visitada',
  front_renderer_builder_transaction_finished: 'La transacció de signatura ha estat completada per tots els intervinents',
  front_renderer_builder_intervener_signed: 'Un intervinent ha completat la signatura dels documents associats',
  front_renderer_builder_transaction_created: 'La transacció de signatura ha estat creada correctament',
  front_renderer_builder_transaction_invalid: 'La transacció de signatura conté errors i no s\'ha pogut crear correctament',
  front_renderer_builder_intervener_finished: 'Intervinent ha completat totes les signatures',
  certificate_builder_generated: 'S\'ha generat el certificat de finalització de la transacció, que recull el contingut de la comunicació i les evidències associades als esdeveniments ocorreguts en el procés.',
  transactionFilesParagraph: 'Llistat de fitxers que conté la transacció',
  transactionDigitalProcessed: 'Transacció "{transactionName}" processada amb èxit',
  signatureType: 'Tipus de signatura',
  faceToFace: 'Presencial',
  remote: 'Remota',
  transactionWarning: 'Amb errors',
  cancelTransaction: 'Cancelar transacció',
  identifyTransactionPlataformCommunications: 'Identifica la transacció a la plataforma i en les comunicacions',
  placeholderTransactionName: 'Nom de la transacció',
  determineMethodAccessSigning: 'Determina el mètode d\'accés a la cerimònia de signatura',
  transactionType: 'Tipus de transacció',
  descriptionRemoteSignature: '<p><b>La signatura electrònica remota</b> permet als signants accedir a la cerimònia de signatura des de qualsevol lloc i dispositiu mitjançant els canals de comunicació disponibles.</p>',
  descriptionPresentialSignature: '<p><b>La signatura presencial</b> permet als signants accedir des del dispositiu emprat per llançar la transacció.</p>',
  setSignatureReminders: 'Estableix els recordatoris per accedir a la cerimònia de signatura',
  transactionReminder: 'Recordatoris de la transacció',
  transactionRedirectToTransactionDetail: 'Veure la transacció',
  transaccion_cancelada: 'Transacció cancel·lada',
  cancelled: 'Cancel·lada',
  digital_certificate: 'Cert.',
  cert: 'Cert.',
  nif: 'DNI/NIF',
  sms_otp: 'SMS OTP',
  email_otp: 'Email OTP',
  video_id: 'Identificació per document',
  ama: 'AMA Portugal',
  lastUpdate: 'Última actualització',
  cancelTransactionAdvertisement: 'La cancel·lació de la transacció no és reversible. Recordeu que el procés de cancel·lació pot trigar uns minuts. Esteu segurs que voleu cancel·lar la transacció?',
  transaction_cancelled: 'Transacció cancel·lada',
  transaction_not_cancelled: 'No s\'ha pogut cancel·lar la transacció',
  signed: 'Signatura completada',
  addReminder: 'Afegir recordatori',
  sendingNewReminder: 'Afegeix un recordatori, establint els dies que han de passar entre el primer enviament o el recordatori anterior',
  confirm: 'Confirmar',
  sinceCreation: 'des de la creació',
  sinceReminderNumber: 'des del recordatori nº',
  reminderLimitReached: 'Heu arribat al límit de recordatoris',
  reminderLimitReachedZero: 'No es poden afegir recordatoris',
  customTextComplementSignatureAccessInformation: 'Text informatiu que inclou els correus electrònics d\'accés a la signatura',
  globalMessageInterveners: 'Missatge global als intervinents',
  exampleInformSignatory: 'Introduïu el contingut del missatge',
  days: 'dies',
  infoReminder: '<p>Els recordatoris de la cerimònia de signatura són les comunicacions enviades als signants posteriorment a la creació. El límit ve donat per la caducitat de la transacció o, en el cas que n\'hi hagués, els límits del propi procés.</p>',
  infoReminderDays: '<p>Els dies indicats en el primer recordatori es comptaran <b>des de la creació de la transacció</b>, mentre que els dies indicats en els següents recordatoris es comptaran des de l\'enviament del recordatori anterior.</p>',
  determineDaysSigningProcessRemainActive: 'Defineix els dies que romandrà activa la cerimònia de signatura',
  transactionExpiration: 'Caducitat de la transacció',
  orderOfSignature: 'Ordre de signatura',
  signatoriesAdded: 'afegits a la transacció',
  infoTransactionWithoutSignatures: 'La transacció encara no té signants. Podeu començar a afegir-los des del botó inferior.',
  intervenersLimitReached: 'Heu arribat al límit de signants',
  addSigner: 'Afegir signant',
  signerEdition: 'Configuració de signant',
  nameSurname: 'Nom i cognoms / Raó social',
  placeholderSignerName: 'Joan Exemple Exemple',
  identificationType: 'Tipus d\'identificació',
  withoutIdentification: 'Sense identificació',
  securitySignature: 'Mètode de confirmació',
  shippingChannel: 'Canal d\'enviament',
  completetionEmail: 'Correu electrònic de finalització',
  personalizedMessage: 'Missatge personalitzat del correu electrònic inicial',
  numberDocument: 'Nº Document',
  contactMethod: 'Mètode de contacte',
  notIndicated: 'No indicat',
  identificationSignature: 'Identificació/Signatura',
  documents: 'Document/s',
  infoTransactionWithoutDocuments: 'La transacció encara no té documents. Podeu començar a afegir-los des del botó inferior.',
  documentsLimitReached: 'Heu arribat al límit de documents',
  addDocument: 'Afegir document',
  requirementsAddDocument: 'Heu de tenir en compte que els documents han de complir els següents requisits:',
  addDocumentError: 'No s\'ha pogut afegir el document',
  documentNameRepeated: 'El nom del document que intenteu afegir ja existeix a la transacció.',
  documentDetail: 'Detalls del document',
  documentActions: 'Accions',
  withoutActions: 'Sense accions',
  positionSignature: 'Posicionar firmes',
  deleteDocument: 'Eliminar document',
  size: 'Mida',
  totalFileSizeExceeded: 'El total dels fitxers pujats no pot superar {size&Unit}',
  minCharacters: 'Mínim {min} caràcters',
  maxCharacters: 'Màxim {max} caràcters',
  expirationDays: 'Ha de ser més gran que 0 i menor que {max} dies',
  expirationMustBeLongerThanReminders: 'La caducitat ha de ser més gran que els recordatoris',
  reminderRuleAvailableDaysInput: 'Ha de ser més gran que 0 i menor que {max} dies',
  remoteDigitalSignature: 'Signatura electrònica remota',
  presentialSignature: 'Signatura presencial',
  none: 'Cap',
  noneMasc: 'Cap',
  certificateDigital: 'Certificat digital',
  alertAbandonProcess: 'Si abandoneu ara el procés, perdreu les dades introduïdes. Esteu segurs que voleu sortir?',
  amaPT: 'Ama Portugal',
  graphometricSignature: 'Signatura grafomètrica',
  signature: 'Signatura',
  documentSignatures: 'Signatures del document',
  closeDocument: 'Tancar document',
  selectSignersActions: 'Seleccioneu un signant per conèixer les accions disponibles sobre el document',
  sendSignedDocuments: 'Enviar documents signats',
  notSendSignedDocuments: 'No enviar documents signats',
  documentLimitSizeAlert: 'La mida del document no pot superar els 12 MB',
  documentFormatAlert: 'Els documents han de tenir format PDF i no estar xifrats o corromputs',
  documentLimitCountAlert: 'El límit de documents per a la transacció és de ',
  signersAdded: 'Signant/s',
  sendTransaction: 'Enviar cerimònia de signatura',
  sendTransactionInfo: 'A la part superior, a mode de resum, teniu disponibles les dades introduïdes durant la configuració. Un cop revisades, podeu procedir amb l\'enviament fent clic al botó inferior',
  transactionOkMessage: 'La transacció s\'ha processat amb èxit; en els pròxims minuts podreu veure els esdeveniments relacionats amb la mateixa des de la vista "Detalls de la transacció". Podeu accedir-hi fent clic al botó inferior',
  transactionsOkMessage: 'Les transaccions s\'han processat amb èxit; en els pròxims minuts podran visualitzar-se a la vista "Transaccions". Podeu accedir-hi fent clic al botó inferior',
  goToPresentialCeremony: 'Començar cerimònia de signatura presencial',
  interactionType: 'Tipus d\'interacció',
  signDocuments: 'Signar documents',
  acceptDocuments: 'Acceptar documents',
  requireCompleteReading: 'Revisió completa del document',
  availableActions: 'Accions disponibles',
  signerAcceptDocument: 'El signant ha d\'acceptar aquest document',
  signaturesNotAvailable: 'Les signatures no estan disponibles si el signant té activada l\'"Acceptació dels documents"',
  selectSigners: 'Seleccionar signants',
  selectSignersDescription: 'Seleccioneu els signants de la transacció que han de participar al document',
  action: 'acció',
  singleClickSignatureInfoText: 'S\'ha activat l\'acceptació del document, per tant, es signarà amb un sol clic.',
  requireScrollToBottomInfoText: 'La revisió completa del document està activada',
  page: 'Pàgina',
  interveners: 'Intervinents',
  "face-to-face": 'Presencial',
  expiration: 'Expiració',
  signatureStatus: 'Estat de firma',
  remaining: 'Restant/s',
  completed: 'Completada',
  contact: 'Contacte',
  events: 'esdeveniments',
  notSpecified: 'No especificat',
  intervener: 'Intervinent',
  reference: 'Referència',
  expired: 'Expirada',
  documentViewerDialogSubtitle: 'Seleccioneu els signants que interactuaran amb el document, així com les accions a realitzar',
  front_renderer_builder_intervener_identification_success: 'L\'intervinent s\'ha identificat correctament en la cerimònia de signatura',
  front_renderer_builder_signature_otp_generated: 'S\'ha generat el codi d\'accés a la transacció',
  front_renderer_builder_signature_otp_sent: 'S\'ha enviat al dispositiu de l\'intervinent el codi d\'accés a la transacció',
  front_renderer_builder_transaction_expired: 'La transacció ja no és accessible perquè ha caducat',
  batches: 'Enviaments per lots',
  view_batches: 'Enviaments per lots',
  open: 'Obrir',
  selectProcess: 'Seleccioneu un procés',
  transactionsSearchPlaceHolder: 'Cerca per ID de transacció o intervinent',
  batchesDescription: 'En aquesta secció es mostren els enviaments per lots realitzats a la plataforma',
  scheduleTime: 'Data d\'enviament',
  schedule: 'Estat/Enviament',
  totalRecords: 'Registres',
  showTransactions: 'Veure transaccions',
  cancelBatch: 'Cancel·lar enviaments',
  cancelBatchConfirmText: 'Esteu segur que voleu cancel·lar l\'enviament i totes les transaccions associades?',
  selectDateFilter: 'Filtre per data',
  stateFilterPlaceholder: 'Filtre per estat',
  processFilterPlaceholder: 'Filtre per procés',
  batchSearchTooltip: 'Els camps disponibles en la cerca són',
  batchSearchTooltipFirstItem: 'ID de l\'enviament per lots',
  batchSearchTooltipSecondItem: 'Correu electrònic de l\'usuari remitent',
  batchSearchTooltipThirdItem: 'Nom del fitxer d\'enviament',
  processed: 'Processat',
  batchCancelled: 'Cancel·lat',
  batchDetail: 'Detalls de l\'enviament per lots',
  batchInfo: 'Informació de l\'enviament',
  batchInfoSectionIntroductionText: 'Si ho desitgeu, podeu seleccionar un nom identificatiu que s\'utilitzarà al llarg de tot el procés. Si ometeu aquest camp, s\'utilitzarà el nom del procés.',
  batchNamePlaceholder: 'Nom predeterminat (nom del procés)',
  shipmentScheduling: 'Programació d\'enviament',
  scheduleShipmentIntroduction: 'Podeu programar l\'enviament per a una data i hora concretes. Si no seleccioneu cap data, l\'enviament es farà de manera immediata.',
  shippingDate: 'Data d\'enviament',
  time: 'Hora',
  selectDate: 'Data',
  selectTime: 'Hora',
  scheduledShipmentIncompleteDate: 'Heu de seleccionar una data i hora per programar l\'enviament',
  scheduleShipment: 'Programar enviament',
  edit: 'Editar',
  digitalSignatureEmptyStepMessage: 'No hi ha cap camp disponible per editar, premeu el botó <b>Continuar</b> per continuar amb el procés.',
  shipmentScheduled: 'Enviament programat per a',
  scheduled: 'Programat',
  sentTransactions: 'Transaccions enviades',
  sent: 'Enviades',
  pendingTransactions: 'Transaccions programades',
  see: 'Veure',
  backToTransactions: 'Tornar a transaccions enviades',
  shipmentDate: 'Data d\'enviament',
  selectProcessFirst: 'Per veure els estats disponibles, seleccioneu primer un procés',
  scheduledShipment: 'Enviament',
  scheduledPastShipment: 'Enviat',
  errors: 'Errors',
  checkEmailInfoErrors: 'Podeu revisar el correu electrònic amb el resultat del procés per conèixer les transaccions errònies i els motius.',
  nextMinutes: 'En els propers minuts'


}