<template>
  <div>
    <section-title
      v-if="showTitle"
      :text="$vuetify.lang.t('$vuetify.shipmentScheduling')"
      class="mt-4"
      icon="event"
      idName="batch-mode"
    />
    <p class="cts-font-size-4 pt-4 mb-4" v-html="$vuetify.lang.t('$vuetify.scheduleShipmentIntroduction')"></p>

    <div class="d-flex justify-space-between align-end">
      <div class="d-flex flex-column">
        <switch-with-label
          v-model="scheduleBatch"
          class="cts-checkbox"
        />
      </div>

      <date-time-picker
        v-if="this.internalValue.scheduleBatch"
        :days-limit="30"
        :model-value="scheduledAtTimestamp"
        :time-label="$vuetify.lang.t('$vuetify.time')"
        class="pl-4"
        @changeTimestamp="scheduledAtTimestamp = $event"
      />
    </div>

  </div>
</template>

<script>
import SectionTitle from "@/components/sections/processes/processView/sectionTitle.vue";
import DateTimePicker from "@/components/structures/inputs/DateTimePicker.vue";
import SwitchWithLabel from "@/components/structures/step_launcher/switchWithLabel.vue";
import vModelMixin from '@/mixins/v-model-mixin'

export default {
  name: "sectionDataPreviewScheduleShipment",
  components: {
    DateTimePicker,
    SwitchWithLabel,
    SectionTitle
  },
  mixins: [vModelMixin],

  props: {
    showTitle: {
      type: Boolean,
      default: () => true
    }
  },

  data() {
    return {
      scheduleBatch: false,
      scheduledAtTimestamp: null,
    };
  },

  mounted() {
    this.internalValue = {scheduleBatch: false, scheduledAtTimestamp: null};
  },

  watch: {
    scheduleBatch(val) {
      this.internalValue.scheduleBatch = val;
      this.scheduledAtTimestamp = null
    },

    scheduledAtTimestamp(val) {
      this.internalValue.scheduledAtTimestamp = val;
    },
  }
}
</script>
