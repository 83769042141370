export default {
  badge: 'Tafel',
  close: 'Schließen',
  dataIterator: {
    noResultsText: 'Die Suche hat keine Treffer ergeben.',
    loadingText: 'Lädt...'
  },
  dataTable: {
    itemsPerPageText: 'Zeilen per Seite:',
    ariaLabel: {
      sortDescending: ': Absteigende Reihenfolge. Klicken zum Entfernen der Reihenfolge.',
      sortAscending: ': Aufsteigende Reihenfolge. Klicken zum Sortieren in absteigender Reihenfolge.',
      sortNone: ': Ohne Reihenfolge. Klicken zum Sortieren in aufsteigender Reihenfolge.',
      activateAscending: 'Aufstieg aktivieren',
      activateDescending: 'Abstieg aktivieren',
      activateNone: 'Alles deaktivieren'
    },
    sortBy: 'Ordnen nach'
  },
  dataFooter: {
    itemsPerPageText: 'Elemente pro Seite:',
    itemsPerPageAll: 'Alle',
    nextPage: 'Nächste Seite',
    prevPage: 'Vorherige Seite',
    firstPage: 'Erste Seite',
    lastPage: 'Letzte Seite',
    pageText: '{0}-{1} von {2}'
  },
  datePicker: {
    itemsSelected: '{0} ausgewählt',
    prevMonthAriaLabel: 'Vorheriger Monat',
    nextMonthAriaLabel: 'Nächster Monat'
  },
  noDataText: 'Keine Daten verfügbar',
  carousel: {
    prev: 'Voriges Bild',
    next: 'Nächstes Bild',
    ariaLabel: {
      delimiter: 'Karussell Seite {0} von {1}'
    }
  },
  calendar: {
    moreEvents: '{0} mehr'
  },
  fileInput: {
    counter: '{0} Dateien',
    counterSize: '{0} Dateien (insgesamt {1})'
  },
  timePicker: {
    am: 'AM',
    pm: 'PM'
  },
  home: 'Anfang',
  templates: 'Flüsse',
  accounts: 'Konten',
  processes: 'Vorgänge',
  transactions: 'Transaktionen',
  statistics: 'Statistiken',
  support: 'Support',
  contacts: 'Kontakte',
  administration: 'Verwaltung',
  search: 'Suche',
  appearance: 'Stile',
  users: 'Benutzer',
  alastria: 'Alastria',
  billing: 'Fakturierung',
  account: 'Mein Konto verwalten',
  admin: 'Verwaltung',
  demos: 'Demos',
  selectedSubaccount: 'Ausgewähltes Unterkonto',
  withoutSubaccount: 'Ohne Unterkonten',
  groups: 'Gruppen',
  filterDate: 'Filter Datum',
  subaccount: 'Unterkonto',
  subaccounts: 'Unterkonten',
  costCenters: 'Kostenstellen',
  subaccountsAssigned: 'Zugewiesene Unterkonten',
  defaultCostCenter: 'Standard-Kostenstelle',
  selectedCostCenter: 'Ausgewählte Kostenstelle',
  availableCostCenters: 'Verfügbare Kostenstellen',
  changeDefaultCostCenter: 'Standardkostenstelle ändern',
  costCenterDialogText: 'Sollten Sie es wünschen, können Sie die für die Transaktion ausgewählte Kostenstelle ändern. Dies wird keine Auswirkungen auf die Standardkostenstelle haben, sondern betrifft nur die Auswahl für diese Transaktion.',
  costCenterDialogTextAccount: 'Sofern gewünscht, können Sie die Standardkostenstelle ändern. Dies legt die ausgewählte Kostenstelle als Standard fest',
  costCenterSelectedTitle: 'Ausgewählte Kostenstelle',
  activeSubaccount: 'Aktives Unterkonto',
  user: 'Benutzer:',
  userData: 'Benutzerdaten',
  userCECOS: 'Kostenstellen',
  userCECO: 'Kostenstelle',
  userCodeCECO: 'Code',
  userCECOPrimary: 'Standard',
  userCECOCodeTitle: 'Kostenstellen-Code',
  password: 'Passwort',
  passwordchange: 'Passwort ändern',
  oldpassword: 'Aktuelles Passwort',
  newpassword: 'Neues Passwort',
  updatePassword: 'Passwort aktualisieren',
  repeatpassword: 'Passwort wiederholen',
  passwordrequirements: 'Das Passwort muss mindestens 8 Zeichen haben und mindestens 3 der zusätzlichen Vorgaben erfüllen:',
  passwordMinOneLowercase: 'Mindestens 1 Großbuchstabe.',
  passwordMinOneUppercase: 'Mindestens 1 Kleinbuchstabe.',
  passwordMinOneNumber: 'Mindestens 1 Zahl.',
  passwordMinOneSpecialChar: 'Mindestens 1 Sonderzeichen.',
  passwordMinEightChars: 'Mindestens 8 Zeichen.',
  passwordCompares: 'Vergleich des erforderlichen Passworts.',
  passwordFieldError: 'Fehler bei der Eingabe, bitte Vorgaben prüfen.',
  passwordrequirementsMoreThanEightChars: 'Das Passwort muss mindestens 8 Zeichen lang sein:',
  notequalpasswords: 'Die Passwörter stimmen nicht überein:',
  samepasswordthanolder: 'Das neue Passwort ist mit dem vorherigen identisch:',
  emptyOldPassword: 'Das vorherige Passwort wurde nicht eingegeben:',
  role: 'Rolle',
  lastConnection: 'Letzte Verbindung',
  languageSelector: 'Sprache auswählen',
  logout: 'Sitzung schließen',
  others: 'Andere',
  continue: 'Weiter',
  continueWSend: 'Mit Sendung fortfahren',
  sendRecoveryForm: 'Anfrage senden',
  restartFilters: 'Filter neu festlegen',
  back: 'Zurück',
  backToMethods: 'Wiederherstellungsmethoden',
  moreInfo: 'Weitere Informationen',
  activateuser: 'Benutzer aktivieren',
  exit: 'Verlassen',
  platformVersion: 'Plattform-Version',
  termsAndConditionsCompliance: 'Bedingungen und Voraussetzungen',
  privacyPoliciesCompliance: 'Datenschutzrichtlinien',
  cookiesPoliciesCompliance: 'Cookie-Richtlinien',
  cookiesPoliciesCertySign: 'Cookies bei CertySign',
  alertSizeFiles: 'Die maximale Dateigröße beträgt 8 MB.',
  login: 'Sitzung starten',
  noSubaccountsMessage: 'Es sind keine Unterkonten vorhanden.',
  telephone: 'Telefonnummer',
  name: 'Vorname',
  lastName: 'Nachname',
  email: 'E-Mail',
  omit: 'Ausblenden',
  id: 'Kundenreferenz',
  clear: 'Säubern',
  add: 'Hinzufügen',
  recipients: 'Empfänger',
  shipmentData: 'Sendedaten',
  prevAndSend: 'Vorschau und Sendung',
  previewFileSize: 'Der Anhang darf höchstens 15 MB umfassen.',
  rulesFilesSize: 'Die maximale Dateigröße beträgt 15 MB.',
  sectionDataFieldsDescription: 'Unten finden Sie die verschiedenen Felder, die für die Übermittlung der Transaktion an die Empfänger erforderlich sind. Die Kontaktfelder werden automatisch ausgefüllt, beginnend mit den Daten des Empfängers.',
  sectionDataFieldsNotAditionalFields: 'Dieser Vorgang hat keine zusätzlichen Felder. Schließen Sie die Übermittlung Ihrer Transaktion bitte über die Vorschau ab.',
  sectionPreviewSubtitle: 'Unten können Sie zwischen den Empfängern navigieren, um jede Sendung einzeln zu prüfen.',
  manual_version: 'Zeigt die neueste Version des Benutzerhandbuchs an.',
  view_login: 'Sitzung starten',
  view_transactions: 'Transaktionen',
  view_transaction: 'Transaktionsdetails',
  view_process: 'Vorgang starten',
  view_processes: 'Vorgänge',
  view_statistics: 'Statistiken',
  view_support: 'Support und Hilfe',
  view_administration: 'Verwaltung',
  view_groups: 'Gruppen',
  view_group: 'Gruppendetails',
  view_users: 'Benutzer',
  view_account: 'Mein Konto',
  view_remember_password: 'Passwort erinnern',
  view_change_password: 'Passwort ändern',
  view_activate_user: 'Benutzer aktivieren',
  view_new_password: 'Neues Passwort',
  view_contacts: 'Kontakte',
  view_user: 'Benutzerdaten',
  view_withoutsubaccounts: '',
  view_forbidden: '',
  view_root: '',
  view_error500: 'Fehler',
  searchAgain: 'Erneut suchen',
  recipient: 'Empfänger',
  sender: 'Absender',
  namesender: 'Name des Absenders',
  subject: 'Betreff',
  emailbody: 'Inhalt der E-Mail',
  resourcespreview: 'Vorschau der Ressourcen',
  attach: 'Anhängen',
  actions: 'Aktionen',
  changePassword: 'Passwort aktualisieren',
  sessionClosed: 'Sitzung korrekt geschlossen',
  noPermissionMessage: 'Es sind keine Zugangsberechtigungen für diesen Abschnitt vorhanden.',
  download: 'Herunterladen',
  viewDocument: 'Dokumente anzeigen',
  accept: 'Annehmen',
  reject: 'Ablehnen',
  change: 'Ändern',
  addVariables: 'Variable hinzufügen',
  resources: 'Ressourcen',
  launchDataKo: 'Die angegebenen Daten sind für die Sendung ungültig.',
  launchDataOk: 'Die Daten sind korrekt, sie können mit der Sendung fortfahren.',
  addNewRecipient: 'Neuen Empfänger hinzufügen',
  transactionRecipients: 'Transaktionsempfänger',
  noRecipientsSelected: 'Kein Empfänger ausgewählt',
  recipientsSelected: 'Es wurden ausgewählt',
  recipientSelected: 'Es wurde ausgewählt',
  preview: 'Vorschau',
  summary: 'Zusammenfassung',
  transaction_summary: 'Zusammenfassung der Sendung',
  PDFpreview: 'PDF-Vorschau',
  previewing: 'Vorschau:',
  notAvailable: 'Nicht verfügbar',
  hide: 'Verstecken',
  send: 'Senden',
  showFile: 'Datei anzeigen',
  error500Message: 'Ein Fehler ist aufgetreten. Wenn das Problem fortbesteht, können Sie den Support kontaktieren über',
  thisForm: 'dieses Formular',
  error401Message: 'Die Sitzung ist abgelaufen.',
  withoutData: 'Ohne Daten',
  notInformed: 'Nicht informiert',
  loginTitleText: 'Sitzung starten',
  changePasswordTitleText: 'Passwort ändern',
  activateUserTitleText: 'Benutzer aktivieren',
  homeWelcomeMessageTitle: 'Willkommen bei CertySign',
  homeWelcomeMessageSubtitle: 'Umgebung für Transaktionsverwaltungen und zertifizierte Zustellung',
  changePasswordUserTitleText: 'Neues Passwort festlegen',
  activateUserSubtitleText: 'Um den Benutzer zu aktivieren, muss ein neues Passwort eingegeben werden.',
  changePasswordUserSubtitleText: 'Um ein neues Passwort festzulegen, füllen Sie bitte die Felder aus.',
  activateUserTitleTextWithoutHash: 'URL ungültig',
  activateUserSubtitleTextWithoutHash: 'Die benutzte URL ist ungültig. Wenn Sie möchten, können Sie über die Option „Passwort vergessen“ auf dem Anmeldebildschirm eine neue URL anfordern.',
  goToLogin: 'Sitzung starten',
  activeFilters: 'Aktive Filter',
  rememberPassword: 'Passwort vergessen?',
  rememberPasswordTitleText: 'Passwort wiederherstellen',
  supportContact: 'Kontakt mit Support',
  rememberPasswordSubTitleText: 'Wählen Sie eine Methode aus, um Ihr Passwort wiederherzustellen.',
  rememberPasswordEmailResponse: 'Wenn der bereitgestellte Benutzer bereits registriert ist, erhalten Sie eine E-Mail mit Anweisungen zur Änderung des Passworts.',
  rememberPasswordWebResponse: 'Wenn die eingegebenen Daten korrekt sind, wird sich unser Support-Team per E-Mail mit Ihnen in Verbindung setzen und Ihnen Informationen zur Änderung des Passworts bereitstellen.',
  rememberPasswordPhoneResponse: 'Wenn die eingegebenen Daten korrekt sind, wird sich unser Support-Team telefonisch mit Ihnen in Verbindung setzen und Ihnen Informationen zur Änderung des Passworts bereitstellen.',
  expiredPasswordResponse: 'Ihr Passwort ist abgelaufen, in Kürze erhalten Sie eine E-Mail mit Anweisungen zur Festlegung eines neuen Passworts.',
  rememberPasswordSupportEmail: 'E-Mail zur Wiederherstellung',
  rememberPasswordSupportEmailSubtitle: 'Wir werden Ihnen eine E-Mail schicken, mit der Sie Ihr Passwort wiederherstellen können.',
  rememberPasswordSupportEmailText: 'Bitte füllen Sie das Formular mit Ihrer bei CertySign registrierten E-Mail-Adresse aus.',
  rememberPasswordSupportWeb: 'Internet-Support',
  rememberPasswordSupportWebSubtitle: 'Senden Sie eine E-Mail an das technische Supportteam von CertySign.',
  rememberPasswordSupportWebText: 'Geben Sie Ihre Daten ein, und das technische Support-Team wird so schnell wie möglich reagieren.',
  emailSupportServiceHours: 'Geschäftszeiten',
  rememberPasswordSupportPhone: 'Telefon-Support',
  rememberPasswordSupportPhoneSubtitle: 'Melden Sie sich telefonisch beim technischen Supportteam von CertySign.',
  rememberPasswordSupportPhoneText: 'Geben Sie Ihre Daten ein, und das technische Support-Team wird so schnell wie möglich reagieren.',
  phoneSupportServiceHours: 'Geschäftszeiten des Telefon-Supports:',
  rememberPasswordNotSupportBySchedule: 'Option deaktiviert, da außerhalb der Geschäftszeiten',
  rememberPasswordMondayToFriday: 'Montags bis Freitags:',
  rememberPasswordSaturdaySundayAndOthers: 'Samstags, Sonntags und an Feiertagen:',
  rememberPasswordNotIncludedDays: '(Ohne Feiertage)',
  supportFinalSendMessage: 'Ihre Anfrage wurde an unsere Support-Abteilung gesendet.',
  goBackToMethods: 'Eine andere Methode auswählen',
  rememberPasswordContactName: 'Vor- und Nachname',
  goToC2C: 'Anruf anfordern',
  messageDescription: 'Nachricht',
  company: 'Unternehmen',
  legalCheckboxText: 'Ich akzeptiere die Nutzungsbedingungen der Website und die Datenschutzbestimmungen.',
  selectHelps: 'Wie können wir Ihnen helfen?',
  selectHelpsTooltip: 'Support und Hilfe',
  helpManual: 'Leitfaden ansehen',
  helpGuide: 'Kurzübersicht anzeigen',
  helpSupport: 'Kontakt mit Support',
  helpSupportPhonePreffered: 'Telefonischer Kontakt bevorzugt',
  helpSupportPhoneSchedule: 'Telefonische Sprechzeiten, an Werktagen',
  requiredEmail: 'E-Mail erforderlich',
  requiredPhone: 'Telefonnummer erforderlich',
  requiredName: 'Nachname erforderlich',
  requiredEmailFormat: 'E-Mail-Format nicht korrekt',
  requiredPhoneFormat: 'Format der Telefonnummer nicht korrekt',
  requiredTextarea: 'Nachricht erforderlich',
  tag_signature: 'Elektronische Signatur',
  tag_email: 'E-Mail',
  tag_sms: 'SMS',
  tag_cert_email: 'E-Mail-Zertifikat',
  tag_landing: 'Website',
  tag_biometric_signature: 'Biometrische Signatur',
  tag_postal: 'Post',
  processesViewListTitle: 'Vorgänge',
  processesViewListDescription: 'Liste aller in CertySign verfügbaren Vorgänge',
  tags: 'Etiketten',
  searchs: 'Suchen',
  processInfo: 'Vorgangsinformationen',
  processesCategoriesFilter: 'Kategorienfilter',
  processesTagFilter: 'Etikettenfilter',
  processesTagOthers: 'Andere',
  processesSearch: 'Vorgänge suchen',
  searchTransactions: 'Transaktionen abrufen',
  searchTransaction: 'Transaktion abrufen',
  deleteFilters: 'Filter entfernen',
  withoutResults: 'Keine Ergebnisse',
  withoutProcessResults: 'Gemäß ihren Berechtigungen oder den festgelegten Suchkriterien sind keine Vorgänge zum Start vorhanden.',
  allProcesses: 'Alle Vorgänge',
  emailNotValid: 'Die E-Mail-Adresse ist ungültig.',
  phoneNotValid: 'Die Telefonnummer ist ungültig.',
  phoneAtLeastNineChars: 'Die Telefonnummer muss mindestens 9 Zeichen lang sein.',
  doesntExist: 'Existiert nicht',
  dontExist: 'Existieren nicht',
  requiredField: 'Feld notwendig',
  errorField: 'Fehlerhaftes Feld',
  numberField: 'Zahlenfeld',
  fiveCharsField: 'Das Feld muss 5 Zeichen beinhalten.',
  CPvalidField: 'PLZ stimmt nicht mit Region überein.',
  delete: 'Löschen',
  save: 'Speichern',
  file: 'Datei',
  loading: 'Lädt',
  countrySelector: 'Land auswählen',
  provinceSelector: 'Region auswählen',
  andNumberMore: 'und {count} mehr',
  selectFile: 'Datei auswählen',
  selectFileHtml: 'Html-Datei auswählen',
  selectFiles: 'Dateien auswählen',
  allTransactions: 'Alle Transaktionen',
  transactionsPerPage: 'Transaktionen pro Seite',
  transactionsSend: 'Sie wurden gesendet',
  transactionslower: 'Transaktionen',
  transactionWithoutId: 'ID-Zuweisung ausstehend',
  withoutCertificated: 'Zertifikat nicht verfügbar',
  filter: 'Filtern',
  transactionsProcessFilter: 'Nach Vorgang filtern',
  processDataPreviewText: 'Im Folgenden finden Sie die Zusammenfassung der neuen Transaktion, einschließlich der Anzahl der erforderlichen Sendungen und der zugehörigen Kostenstelle.',
  processDataPreviewTextWithoutCC: 'Im Folgenden finden Sie die Zusammenfassung der neuen Transaktion, in der die erforderliche Anzahl von Sendungen angegeben ist.',
  transactionsLinkedGroupsFilter: 'Filter nach Gruppe',
  transactionsDateFilter: 'Filter nach Datum',
  numberOfTransactions: 'Anzahl an Sendungen',
  associatedCECO: 'Zugehörige Kostenstelle',
  transactionsCategoryFilter: 'Filter nach Kategorie',
  transactionsStatusFilter: 'Filter nach Status',
  transactionState: 'Status der Transaktion',
  transactionSummary: 'Zusammenfassung der Transaktion',
  transactionGeneralData: 'Information zur Transaktion',
  transactionData: 'Daten der Transaktion',
  files: 'Dateien',
  transactionContact: 'Kontaktdaten',
  transaction: 'Transaktion',
  transactionEvents: 'Zertifizierbare Transaktionsereignisse',
  transactionWithoutEvents: 'Keine Ereignisse für die Transaktion registriert',
  transactionProgress: 'Fortschritt der Transaktion',
  transactionSubtitle: 'Statusinformation der Transaktion',
  transactionDetails: 'Tansaktionsdetails',
  transactionEventInfo: 'Informationen zum Ereignis',
  eventMeaningPrepend: 'Was bedeutet das Ereignis',
  eventMeaningAppend: '?',
  eventMeaning: 'Was bedeutet dieses Ereignis?',
  transactionLaunchedDate: 'Datum der Erstellung',
  transactionLastEvent: 'Letztes erhaltene Ereignis',
  transactionLastEventDate: 'Datum des letzten Ereignisses',
  transactionContactName: 'Vor- und Nachname',
  transactionContactMobilephone: 'Mobiltelefonnummer',
  transactionContactEmail: 'E-Mail',
  transactionContactID: 'Ausweisnummer',
  advancedFilters: 'Erweiterte Filter',
  group: 'Gruppen',
  date: 'Datum',
  hideFilters: 'Filter verstecken',
  processName: 'Name des Vorgangs',
  usernameTransactionDetail: 'Absender Benutzer',
  recipientsNumber: 'Anzahl der Empfänger',
  invalidTransaction: 'Daten ungültig',
  dataTableShowRecipients: 'Anzeige der Sendung <span class="font-weight-medium">:page:</span> von <span class="font-weight-medium">{total}</span>',
  transactionRedirectToProcesses: 'Zu Vorgänge gehen',
  transactionRedirectToTransactions: 'Zu Transaktionen gehen',
  transactionRedirectToNewSend: 'Neue Sendung',
  transactionProcessed: 'Transaktion erfolgreich durchgeführt',
  transactionsProcessed: 'Transaktionen erfolgreich durchgeführt',
  transactionError: 'Transaktion ungültig durchgeführt',
  transactionsError: 'Transaktionen ungültig durchgeführt',
  transactionProcessing: 'Verarbeitung der Transaktion...',
  transactionLaunchedMessage: 'Die Transaktion wurde erfolgreich durchgeführt, sowie die Sendungen verarbeitet werden, werden sie sichtbar im Abschnitt',
  transactionsLaunchedMessage: 'Die Transaktionen wurden erfolgreich durchgeführt, sowie die Sendungen verarbeitet werden, werden sie sichtbar im Abschnitt',
  transactionDownloadFile: 'Zertifikat ansehen',
  transactionProcessingMessage: 'Das System bearbeitet die Transaktion, bitte warten Sie einen Moment',
  transactionsProcessingMessage: 'Das System bearbeitet die Transaktion, bitte warten Sie einen Moment',
  transactionsErrorMessage: 'Bei der Verarbeitung der Transaktionen der verschiedenen Empfänger wurden in mindestens einer Transaktion ungültige Daten festgestellt. Im Folgenden finden Sie die vollständige Liste der Transaktionen der Sendung',
  transactionErrorMessage: 'Während des Transaktionsvorgangs wurden ungültige Daten festgestellt',
  recoverProcessQuestion: 'Wenn Sie einen neuen Vorgang starten, verlieren Sie den Fortschritt',
  recoverProcessQuestionAppend: 'Möchten Sie einen neuen Vorgang starten?',
  statisticsTransactionsMultipleTitle: 'Getätigte Transaktionen zwischen dem <span class="font-weight-bold">{start_day}. {start_month}</span> und <span class="font-weight-bold">{end_day}. {end_month}</span>',
  statisticsTransactionsOneTitle: 'Getätigte Transaktionen vom {start_day}. {start_month}',
  statisticsTotalTransactionsTitle: 'Gesamtzahl Transaktionsart',
  statisticsNoResults: 'Keine Ergebnisse für die angegebenen Filter',
  statisticsProcessTitle: 'Vorgang:',
  total: 'Gesamt',
  totalTransactions: 'Summe Transaktionen',
  totalTransactionsByProcesses: 'Aufschlüsselung der Transaktionen nach Verfahren',
  accountDescription: 'Kontoeinstellungen',
  accountTitle: 'Konto',
  contactsDescription: 'Auflistung und Verwaltung der Kontakte',
  contactsTitle: 'Kontakte',
  transactionsDescription: 'Auflistung und Verwaltung der Transaktionen',
  transactionsTitle: 'Transaktionen',
  statisticsDescription: 'Grafische Analyse Ihrer Transaktionen',
  statisticsTitle: 'Statistiken',
  supportDescription: 'In diesem Abschnitt finden Sie Hilfe durch den technischen Support oder das Benutzerhandbuch.',
  supportManualDescription: 'In diesem Abschnitt können Sie die Dokumentation für die Nutzung der Plattform einsehen.',
  supportManualTitle: 'Handbuch und Leitfaden einsehen',
  supportManualTitleOnlyManual: 'Handbuch einsehen',
  supportManualTitleOnlyGuide: 'Leitfaden einsehen',
  administrationDescription: 'Verwaltung der Plattformkonten',
  administrationTitle: 'Verwaltung',
  userActive: 'Benutzer aktiv',
  userInactive: 'Benutzer deaktiviert',
  userStateTitle: 'Benutzerstatus',
  groupsTitle: 'Berechtigungsgruppen',
  groupsDescription: 'Verwaltung der Gruppen des Kontos Konfiguration von Parametern und Berechtigungen jeder Gruppe',
  groupsAssignation: 'Zugeteilte Gruppe',
  groupInheritFrom: 'Vererben von Berechtigungen von',
  groupDataTitle: 'Gruppeninformation',
  groupUsersPermissionsTitle: 'Berechtigungen, Benutzer und Vorgänge',
  groupPermissionsTabsTitle: 'Berechtigungen',
  groupProcessesAndSubaccounts: 'Unterkonten und Vorgänge',
  groupLinked: 'Verknüpfte Gruppen',
  groupPermissionsOwnerText: 'Diese Gruppe ist der Eigentümer des Kontos und verfügt über alle ihr zugewiesenen Berechtigungen.',
  groupPermissionsOwnerSubaccountProcessesText: 'Diese Gruppe ist der Eigentümer des Kontos und verfügt über alle Unterkonten und alle Vorgänge.',
  groupAssociatedProcesses: 'Verknüpfte Vorgänge',
  groupAssociatedSubaccounts: 'Verknüpfte Unterkonten',
  newGroup: 'Neue Gruppe',
  groupName: 'Gruppenname',
  editGroup: 'Gruppe wird bearbeitet',
  groupDescription: 'Beschreibung',
  groupMinDaysToChange: 'Mindestgültigkeitsdauer des Passworts',
  groupTabUsersNameTitle: 'Vor- und Nachname',
  groupTabPermissionSectionTitle: 'Abschnitte',
  groupTabPermissionGroupTitle: 'Gruppen',
  groupTabPermissionUsersTitle: 'Benutzer',
  usersSearch: 'Benutzer suchen',
  subaccountWithoutProcesses: 'Unterkonto ohne verknüpfte Vorgänge',
  selectCostCenter: 'Wählen Sie eine Kostenstelle',
  adminGroupInfoDescription: 'In diesem Abschnitt können Sie die Beschreibung, die Sitzungsrichtlinien und die Passwörter der Plattform bearbeiten.',
  adminGroupInfoUsers: 'In diesem Abschnitt können Sie die mit dieser Gruppe verknüpften Benutzer verwalten.',
  adminGroupInfoPerms: 'In diesem Abschnitt können Sie die Berechtigungen in Bezug auf die Funktionen verwalten, die der Benutzer auf der Plattform ausführen kann.',
  adminGroupInfoProcesses: 'In diesem Abschnitt können Sie den Zugriff des Benutzers auf Prozesse und Unterkonten verwalten. Dieser Zugang gewährt die Berechtigung zum Starten, Anzeigen von Transaktionen und Statistiken der zugewiesenen Prozesse.',
  adminGroupInfoLinked: 'In diesem Abschnitt können Sie die Beaufsichtigung von anderen Gruppen verwalten. Aktivierte Gruppen tauschen Informationen über gestartete Transaktionen und Statistiken mit der aktuellen Gruppe aus.',
  passwordsQuantity: 'Anzahl der gespeicherten Passwörter',
  passwordExpiration: 'Tage bis zum Ablauf des Passworts',
  loginRetriesQuantity: 'Anzahl der erneuten Anmeldeversuche',
  loginRetriesTime: 'Minuten bis zum erneuten Anmeldeversuch',
  sessionExpiration: 'Minuten bis zum Ablauf der Sitzung',
  processAlertExpirationQuestion: 'Möchten Sie fortfahren?',
  processAlertExpirationPhrase: 'Aus Sicherheitsgründen wird die Sitzung in Kürze ablaufen.',
  processAlertExpiration: 'Verfallswarnung',
  continueSession: 'Weiter',
  usersTitle: 'Benutzer',
  activate: 'Aktiviert',
  deactivate: 'Deaktiviert',
  usersPerPage: 'Benutzer pro Seite',
  usersDescription: 'Verwaltung von Benutzer der Konten',
  userInfoTitle: 'Benutzerinformation',
  userPermissionTitle: 'Art der zugewiesenen Genehmigung',
  newUser: 'Neuer Benutzer',
  editUser: 'Bearbeitet Benutzer',
  groupTitle: 'Gruppenname',
  administrator: 'Administrator',
  groupLinkedHeader: 'Verknüpft',
  firmaElectronica: 'Elektronische Signatur',
  comunicacionCertificada: 'Zertifizierte Mitteilung',
  publicacionWeb: 'Web-Veröffentlichung',
  groupVisibility: 'Gruppensichtbarkeit',
  passwordPolicy: 'Passwort-Richtlinie',
  frontalPolicy: 'Sitzungs-Richtlinie',
  groupData: 'Gruppendaten',
  groupsPerPage: 'Gruppen pro Seite',
  groupsFound: 'Es wurden gefunden',
  cancel: 'Abbrechen',
  description: 'Beschreibung',
  subaccountChanged: 'Unterkonto geändert',
  processContinueTitle: 'Es besteht ein ausstehender Sendungsvorgang',
  processContinueSubtitle: 'Möchten Sie den Vorgang wiederherstellen oder einen neuen starten?',
  newProcess: 'Neuer Vorgang',
  recover: 'Wiederherstellen',
  newShipping: 'Neue Sendung',
  toContinueFillFields: 'Zum Fortfahren füllen Sie bitte die Felder aus.',
  passwordRequired: 'Passwort erforderlich',
  userRequired: 'Benutzer erforderlich',
  fieldAlert: 'Das Feld %%fields%% wird automatisch seitens der Empfänger ausgefüllt.',
  fieldAlertPlural: 'Die Felder %%fields%% werden automatisch seitens der Empfänger ausgefüllt.',
  fieldNumberPhone: 'Telefonnummer',
  fieldCountryCode: 'Ländercode',
  fieldEmail: 'E-Mail',
  fieldName: 'Vorname',
  fieldSurname: 'Nachname',
  and: 'und',
  certificate: 'Zertifikat',
  country: 'Land',
  continueEditing: 'Sendedaten bearbeiten',
  second: 'Sekunde',
  seconds: 'Sekunden',
  nextRefreshAvailableAt: 'Nächstes Update verfügbar in',
  fastSearch: 'Schnelle Suche',
  update: 'Aktualisieren',
  process: 'Vorgang',
  transactionId: 'Transaktions-ID',
  transactionClientId: 'Kundenreferenz',
  createdDate: 'Erstellt',
  updatedAt: 'Aktualisiert',
  state: 'Status',
  noGroupAssigned: 'Ohne ausgewählte Gruppe',
  contact_country_code: 'Ländercode',
  contact_email: 'E-Mail',
  contact_id: 'Kundenreferenz',
  contact_name: 'Vorname',
  contact_phone: 'Telefonnummer',
  contact_surname: 'Nachname',
  contact_complete_name: 'Vollständiger Name',
  certificateValidDownload: 'Zertifikat korrekt heruntergeladen',
  certificateFailDownload: 'Das Zertifikat konnte nicht heruntergeladen werden.',
  graphicBarValidDownload: 'Grafik korrekt heruntergeladen',
  graphicBarFailDownload: 'Die Grafik konnte nicht heruntergeladen werden.',
  //events
  queued: 'Die Transaktion ist wird noch bearbeitet.',
  new_communication: 'Die Transaktion wurde erfolgreich abgeschlossen.',
  orchestrator_new_communications_communication_received: 'Die Transaktion wurde erfolgreich abgeschlossen.',
  commstracker_generate_url_generated: 'Der verkürzte URL-Link wurde generiert.',
  commstracker_generate_url_click: 'Die Transaktionsverknüpfung wurde geöffnet.',
  communication_send_sms_sent: 'Die Textnachricht wurde im Versanddienstleistungszentrum des Anbieters hinterlegt. Dieser wiederum kümmert sich um die Zustellung an den Endempfänger.',
  communication_send_sms_not_sent: 'Die Textnachricht konnte nicht im Versanddienstleistungszentrum des Anbieters hinterlegt werden.',
  communication_send_sms_delivered: 'Die Textnachricht konnte an den Empfänger zugestellt werden.',
  communication_send_sms_not_delivered: 'Die Textnachricht konnte nicht an den Empfänger zugestellt werden.',
  generate_otp_code_otp_generated: 'Ein Einmalpasswort (OTP) ist generiert worden.',
  generate_certificate_generated: 'Die Bescheinigung über den Abschluss der Transaktion wurde erstellt, die den Inhalt der Mitteilung und die mit den Ereignissen des Prozesses verbundenen Nachweise enthält.',
  communication_send_email_delivered: 'Die Nachricht wurde an den E-Mail-Dienstanbieter (ESP) des Empfängers zugestellt.',
  communication_send_email_complaint: 'Der Empfänger der Nachricht hat die empfangene E-Mail als Spam markiert.',
  communication_send_email_queued: 'Die E-Mail-Nachricht wurde angepasst.',
  communication_send_email_deferred: 'Die E-Mail-Nachricht wurde angepasst und wirf schnellstmöglich zugestellt.',
  communication_send_email_bounce: 'Die E-Mail-Nachricht wurde zurückgesendet.',
  communication_send_email_sent: 'Die E-Mail-Nachricht wurde im Versanddienstleistung des Anbieters hinterlegt. Dieser wiederum kümmert sich um die Zustellung an den Endempfänger.',
  communication_send_email_sender_not_allowed: 'Die E-Mail-Nachricht konnte nicht gesendet werden, da der Absender nicht autorisiert ist.',
  communication_send_email_open: 'Die E-Mail-Nachricht wurde vom Empfänger geöffnet.',
  communication_send_email_click: 'Der Empfänger der E-Mail hat auf einen Link geklickt.',
  communication_send_email_block: 'Die E-Mail-Adresse des Empfängers befindet sich auf der Ausschlussliste, die Nachricht wurde nicht versendet.',
  communication_send_email_unsubscribe: 'Der Empfänger der E-Mail-Nachricht möchte sich aus der Verteilerliste austragen.',
  communication_send_email_spam: 'Die E-Mail-Nachricht wurde als Spam identifiziert.',
  communication_send_email_hard_blacklisted: 'Die E-Mail-Adresse des Empfängers befindet sich auf der Ausschlussliste, die Nachricht wurde nicht versendet.',
  communication_send_email_complaint_blacklisted: 'Die E-Mail-Adresse des Empfängers befindet sich auf der Beschwerdenliste, die Nachricht wurde nicht versendet.',
  document_upload_s3_uploaded: 'Die Bescheinigung über den Abschluss der Transaktion wurde auf sichere Weise gespeichert. Sie enthält den Inhalt der Mitteilung und die mit den Ereignissen des Prozesses verbundenen Nachweise.',
  communication_send_telegram_delivered: 'Die Textnachricht konnte an den Empfänger zugestellt werden.',
  communication_send_telegram_not_delivered: 'Die Textnachricht konnte nicht zugestellt werden.',
  web_framework_generated: 'Das grafische Web-Interface wurde entwickelt, um dem Empfänger die Interaktion zu ermöglichen.',
  web_framework_solicitud_otp: 'Es wurde ein Einmalpasswort (OTP) angefordert.',
  web_framework_signable_document_loaded: 'Ein Dokument wurde zur Unterzeichnung in das Web-Interface hochgeladen.',
  web_framework_otp_simple_signature_ok: 'Die Dokumentation wurde mit dem Einmalpasswort signiert.',
  web_framework_commited: 'Der Vorgang im Web-Interface wurde abgeschlossen.',
  web_framework_document_signed: 'Die Dokumentation wurde unterzeichnet.',
  web_framework_form_based_identification: 'Der Authentifizierungsschritt durch das Formular wurde abgeschlossen.',
  web_framework_certificate_based_identification: 'Der Zugang mit einem persönlichen Zertifikat wurde erfolgreich abgeschlossen.',
  web_framework_document_based_identification: 'Die Identifizierung des Formulars war erfolgreich.',
  web_framework_certificate_based_identification_failed: 'Der Vorgang der Identifizierung mit Zertifikat ist fehlgeschlagen.',
  web_framework_document_based_identification_failed: 'Der Vorgang der Identifizierung mit Dokument ist fehlgeschlagen.',
  web_framework_form_based_identification_failed: 'Der Vorgang der Authentifizierung mit Formular ist fehlgeschlagen.',
  web_framework_web_framework_expired: 'Die Transaktion ist abgelaufen.',
  web_framework_documentation_downloaded: 'Die Dokumentation wurde durch den Empfänger heruntergeladen.',
  web_framework_electronic_notificacion_rejected: 'Die elektronische Benachrichtigung wurde durch den Empfänger abgelehnt.',
  web_framework_gdpr_formulary_committed: 'Die Einverständniserklärung ist vom Empfänger ausgefüllt worden.',
  web_framework_sepa_formulary_committed: 'Die SEPA-Erklärung ist vom Empfänger ausgefüllt worden.',
  web_framework_requested_otp_code: 'Es wurde ein Einmalpasswort (OTP) angefordert.',
  web_framework_entered_wrong_otp_code: 'Das vom Empfänger eingegebene Einmalpasswort (OTP) ist ungültig.',
  web_framework_entered_timedout_otp_code: 'Das Einmalpasswort ist abgelaufen.',
  web_framework_entered_correct_otp_code: 'Das vom Empfänger eingegebene Einmalpasswort (OTP) ist korrekt.',
  web_framework_electronic_notificacion_accepted: 'Die elektronische Benachrichtigung wurde vom Empfänger angenommen.',
  web_framework_signature_rejected: 'Die elektronische Signatur wurde durch den Empfänger abgelehnt.',
  web_framework_request_otp_code_too_early: 'Das Einmalpasswort (OTP) wurde vom Empfänger vor der zur Generierung festgelegten Zeit angefordert.',
  web_framework_capture_data_commited_no_response: 'Es wurde versucht, das Formular zu senden, ohne dass die Antworten Daten enthalten.',
  web_framework_capture_data_commited: 'Die Daten wurden erfolgreich versendet.',
  web_framework_document_previewed: 'Die Vorschau der Dokumentation wurde durch den Empfänger aktiviert.',
  web_framework_document_simply_signed: 'Die Dokumentation wurde elektronisch unterzeichnet.',
  document_upload_alastria_uploaded: 'Der Hash des Abschlusszertifikats wurde auf den CertySign-Knoten ins Alastria Blockchain Network hochgeladen.',
  publish_downloadable_file_generated: 'Es wurde eine Dokumentation für die Veröffentlichung auf dem Web-Interface erstellt.',
  publish_downloadable_file_open: 'Es wurde eine Dokumentation für die Veröffentlichung auf dem Web-Interface geöffnet.',
  document_sign_signed: 'Das Zertifikat wurde unterzeichnet.',
  rpc_callback_published: 'Die Rückführung des RPC-Aufrufs wurde veröffentlicht.',
  loop_add_added: 'Die Transaktion wurde in die Wiederholungsschleife aufgenommen.',
  loop_add_tick: 'Es wurde ein zusätzlicher Versuch unternommen.',
  loop_add_timeout: 'Das Wiederholungslimit ist ausgeschöpft.',
  loop_cancel_canceled: 'Die Wiederholungsschleife wurde abgebrochen.',
  identification_create_identification_generated: 'Das Verfahren zur Identifizierung des Nutzers bei der Transaktion wurde erstellt.',
  identification_create_identification_ok: 'Die Identifizierung war erfolgreich.',
  test_load_processed: 'Der Belastungstest wurde durchgeführt.',
  instance_delete_deleted: 'Die der Transaktion entsprechende Instanz wurde gelöscht.',
  unzip_file_extracted: 'Die Datei wurde dekomprimiert.',
  communicalia_consent_accepted: 'Der Empfänger der Mitteilung hat die Zustimmung akzeptiert.',
  communicalia_consent_declined: 'Der Empfänger der Mitteilung hat seine Zustimmung verweigert.',
  communicalia_consent_no_data: 'Der Empfänger der Mitteilung hat nicht geantwortet.',
  robinson_list_accepted: 'Der Empfänger steht nicht auf Robinson-Liste.',
  robinson_list_declined: 'Der Empfänger ist als angemeldet auf Robinson-Liste angegeben.',
  robinson_list_no_data: 'Der Empfänger ist als angemeldet auf Robinson-Liste angegeben.',
  publish_embeddable_file_generated: 'Die eingebettete Datei für das Web-Interface wurde erstellt.',
  document_upload_sftp_uploaded: 'Die Dokumentation wurde über SFTP hochgeladen.',
  file_delete_deleted: 'Die Datei wurde gelöscht.',
  disable_framework_web_framework_web_disabled: 'Das mit der Transaktion verbundene Web-Interface wurde deaktiviert.',
  generate_onespan_transaction_transaction_create: 'Die Transaktion der elektronischen Signatur wurde mit OneSpan erstellt.',
  generate_onespan_transaction_transaction_create_error: 'Bei der Erstellung der Transaktion der elektronischen Signatur in OneSpan ist ein Fehler aufgetreten.',
  generate_onespan_transaction_transaction_complete: 'Die Transaktion der elektronischen Signatur wurde in OneSpan abgeschlossen.',
  generate_onespan_transaction_transaction_expire: 'Die Transaktion der elektronischen Signatur in OneSpan ist abgelaufen.',
  generate_onespan_transaction_transaction_decline: 'Die Transaktion der elektronischen Signatur in OneSpan wurde abgelehnt.',
  generate_onespan_transaction_email_bounce: 'Die E-Mail-Nachricht, die der OneSpan-Transaktion zur elektronischen Signatur entspricht, wurde zurückgesendet.',
  generate_onespan_transaction_transaction_opt_out: 'Der Empfänger hat sich entschlossen, die Transaktion nicht zu signieren.',
  generate_onespan_transaction_transaction_delete: 'Die Transaktion der elektronischen Signatur in OneSpan wurde gelöscht.',
  generate_onespan_transaction_transation_desactivate: 'Die Transaktion der elektronischen Signatur in OneSpan wurde deaktiviert.',
  generate_onespan_transaction_kba_failure: 'Die Transaktion wurde aus Gründen der Zugangs- oder Bearbeitungssicherheit blockiert.',
  generate_onespan_transaction_transaction_sent: 'Die Transaktion der elektronische Signatur in OneSpan wurde versendet.',
  generate_onespan_transaction_transaction_sent_error: 'Die Sendung der Transaktion der elektronische Signatur in OneSpan ist fehlgeschlagen.',
  generate_onespan_transaction_package_trash: 'Die Transaktion wurde in den Papierkorb von OneSpam befördert.',
  get_onespan_files_files_onespan_generated: 'Die notwendigen Dateien für OneSpan wurden generiert.',
  communication_send_postal_generated: 'Die Sendung der Pos-Mitteilung wurde generiert.',
  communication_send_postal_sent_to_factory: 'Die Sendung wurde in die Postkommunikationsplattform hochgeladen.',
  communication_send_postal_printed: 'Die mit der postalischen Sendung verbundene Dokumentation wurde gedruckt.',
  communication_send_postal_pre_admission_requested: 'Der Versand der Mitteilung wird auf der Website des Postbetreibers telematisch vorregistriert.',
  communication_send_postal_pre_admitted: 'Der Versand der Mitteilung wurde vom Postbetreiber vor ihrer Hinterlegung telematisch registriert.',
  communication_send_postal_sent_to_logistic_operator: 'Die Mitteilung wird beim Postbetreiber hinterlegt.',
  communication_send_postal_on_the_way_to_logistic_operator: 'Die Sendung ist auf dem Weg vom nächstgelegenen Postamt zum Empfänger der Mitteilung.',
  communication_send_postal_received_by_logistic_operator: 'Die Sendung befindet sich in dem Postamt, das dem Empfänger der Mitteilung am nächsten liegt.',
  communication_send_postal_not_received_by_logistic_operator: 'Die Sendung der Mitteilung ist nicht im Postamt eingetroffen.',
  communication_send_postal_on_delivery: 'Die Sendung der postalischen Mitteilung ist unterwegs.',
  communication_send_postal_delivered_to_recipient: 'Die Sendung der postalischen Mitteilung wurde an den Empfänger zugestellt.',
  communication_send_postal_returned_to_postal_office: 'Die Sendung befindet sich im Postamt und die Meldung an den Empfänger befindet sich in dessen Briefkasten.',
  communication_send_postal_delivered_into_postal_office: 'Die Mitteilung wurde dem Empfänger auf dem Postamt zugestellt',
  communication_send_postal_generic_delivered: 'Die Sendung der postalischen Mitteilung wurde an den Empfänger zugestellt.',
  communication_send_postal_returned: 'Es war nicht möglich, die Sendung zuzustellen, und die postalische Mitteilung wurde an den Absender zurückgeschickt.',
  communication_send_postal_canceled: 'Die Sendung der postalischen Mitteilung wurde storniert.',
  communication_send_postal_lost: 'Die Sendung der postalischen Mitteilung wurde verloren.',
  communication_send_postal_expired: 'Die postalische Mitteilung wurde an den Absender zurückgeschickt, weil sie nicht innerhalb von 15 Arbeitstagen nach dem Einwurf der Ankündigung in den Briefkasten vom Empfänger abgeholt wurde.',
  communication_send_postal_rejected: 'Die Sendung der postalischen Mitteilung wurde vom Empfänger storniert.',
  communication_send_postal_stolen: 'Die Sendung der postalischen Mitteilung wurde abgefangen oder gestohlen.',
  communication_send_postal_outside_sicer: 'Die Sendung der Mitteilung ist nicht beim Postbetreiber registriert.',
  communication_send_postal_bad_postal_address: 'Die Zustelladresse ist nicht korrekt.',
  communication_send_postal_passed_away: 'Der Empfänger der Sendung der postalischen Mitteilung ist verstorben.',
  communication_send_postal_other: 'Andere.',
  communication_send_postal_no_information: 'Der Postbetreiber verfügt über keine Informationen zur Sendung der Mitteilung.',
  communication_send_postal_received_pee: 'Der elektronische Zustellnachweis (PEE) für die Sendung ist eingegangen.',
  communication_send_postal_certificate_returned: 'Die Rücksendebescheinigung wurde mit dem Unzustellbarkeitsnachweis des Postbetreibers erstellt.',
  communication_send_postal_unknown: 'Der Empfänger der Mitteilung ist unter der angegebenen Postanschrift unbekannt.',
  zip_files_path_zipped: 'Es wurden ein oder mehrere Dokumente komprimiert.',
  intromission_api_retries_muted: 'Die Transaktionswiederholungen wurden deaktiviert.',
  intromission_api_retries_unmuted: 'Die Transaktionswiederholungen wurden aktiviert.',
  intromission_api_instance_canceled: 'Die Transaktion wurde storniert.',
  antivirus_filtered: 'Das Antivirenprogramm hat die Transaktion gefiltert, weil potenziell gefährliche Ressourcen hochgeladen worden sind.',
  //API messages
  user_operation_error_creating_user: 'Beim Erstellen des Benutzers ist ein Fehler ist aufgetreten.',
  user_operation_user_already_exists: 'Dieser Benutzer existiert bereits.',
  user_operation_user_created: 'Benutzer erfolgreich erstellt',
  user_operation_user_updated: 'Benutzer erfolgreich aktualisiert',
  user_operation_no_exists: 'Dieser Benutzer existiert nicht.',
  user_operation_password_changed: 'Das Passwort wurde aktualisiert.',
  user_operation_pass_incorrect: 'Die Kombination aus Benutzer und Passwort ist nicht korrekt.',
  user_operation_password_expired: 'Das Passwort ist abgelaufen.',
  user_operation_logout: 'Sitzung korrekt geschlossen',
  user_operation_blocked_user: 'Der Benutzer ist blockiert.',
  user_operation_wrong_subdomain: 'Der Benutzername oder das Passwort sind nicht korrekt.',
  user_operation_max_pass_tries: 'Benutzer blockiert, mögliche Anzahl an Versuchen überschritten',
  user_operation_deleted: 'Benutzer korrekt gelöscht',
  user_operation_error_deleted: 'Fehler beim Löschen des Benutzers',
  user_has_been_disabled: 'Benutzer deaktiviert',
  user_operation_error_user_not_found: 'Wenn die genannte E-Mail-Adresse existiert, werden Sie in Kürze eine E-Mail mit den Anweisungen zum Ändern des Passwortes erhalten.',
  user_operation_remember_password_email: 'Wenn die genannte E-Mail-Adresse existiert, werden Sie in Kürze eine E-Mail mit den Anweisungen zum Ändern des Passwortes erhalten.',
  email_sent_to_support: 'Anfrage korrekt gesendet',
  user_operation_error_change_password_min_days: 'Das Passwort kann nicht geändert werden, weil die erforderliche Mindestanzahl an Tagen nicht erreicht ist.',
  user_operation_error_passwords_mismatch: 'Die Passwörter stimmen nicht überein.',
  user_operation_error_password_has_been_used_before: 'Dieses Passwort wurde bereits genutzt, bitte wählen sie ein neues aus.',
  user_or_group_no_exists: 'Benutzer oder Gruppe existiert nicht.',
  user_error_already_active: 'Der Benutzer ist bereits aktiv.',
  activation_email_resend: 'Die Aktivierungs-E-Mail wurde erneut versendet.',
  group_operation_error_creating_group: 'Beim Erstellen des Gruppe ist ein Fehler ist aufgetreten.',
  group_operation_group_already_exists: 'Diese Gruppe existiert bereits.',
  group_operation_group_created: 'Gruppe erfolgreich erstellt',
  group_operation_group_updated: 'Gruppe :group_name: erfolgreich aktualisiert',
  //Terms and conditions
  termsConditionsDPOTitle: 'Datenschutzbeauftragter',
  termsConditionsTitular: 'Inhaber',
  termsConditionsNIF: 'Steueridentifikationsnummer',
  termsConditionsDomicilioSocial: 'Geschäftssitz',
  termsConditionsDatosRegistrales: 'Angaben zur Registrierung',
  termsConditionsContacto: 'Kontakt',
  termsConditionsDPOEmailTitle: 'E-Mail Customer Comms',
  termsConditionsDPOEmail: 'E-Mai-Adresse des Datenschutzbeauftragten',
  termsConditions1: 'CUSTOMER COMMUNICATIONS TECKNALIA, S.L., (im Folgenden CCOMMS) in seiner Eigenschaft als Eigentümer und Verantwortlicher dieser App, in Übereinstimmung mit den Bestimmungen des Artikels 10 des Gesetzes 34/2002 vom 11. Juli über die Dienste der Informationsgesellschaft und den Elektro-Handel, stellt den Benutzern die folgenden Informationen dieser App zur Verfügung:',
  termsConditionsObjetoTitulo: 'ZIEL',
  termsConditionsObjetoParrafo1: 'Diese Allgemeinen Nutzungsbedingungen (im Folgenden AGB) regeln die Nutzung der CertySign-Anwendung (im Folgenden APP), die CCOMMS den Nutzern zur Verfügung stellt, die auf diese zugreifen, um den gestellten Antrag ausführen zu können.',
  termsConditionsObjetoParrafo2: 'Die vorliegenden AGB gelten daher sowohl für die Werbetätigkeit und die Bereitstellung von Informationen, die über die APP erfolgen, als auch für die Erbringung der dort angebotenen Dienstleistungen, so dass sie jederzeit sowohl für das einfache Besuchen der APP als auch für die Beauftragung von Dienstleistungen im Rahmen der APP gelten, wobei die letztgenannten Tätigkeiten zusätzlich sowohl den nachstehend beschriebenen AGB als auch den geltenden Allgemeinen Vertragsbedingungen und den gegebenenfalls vorhandenen Besonderen Bedingungen unterliegen.',
  termsConditionsUsuariosTitulo: 'BENUTZER',
  termsConditionsUsuariosParrafo1: 'Der Zugriff auf und/oder die Nutzung der APP setzt voraus, dass der Nutzer von diesem Zeitpunkt an die vorliegenden AGB sowie die Besonderen Bedingungen, die gegebenenfalls die AGB in Bezug auf bestimmte Dienste und Inhalte der APP ergänzen, ändern oder ersetzen, in vollem Umfang und ohne Vorbehalte akzeptiert und verpflichtet ist, die von CCOMMS aufgestellten Nutzungsbeschränkungen dieser Seite jederzeit einzuhalten.',
  termsConditionsAccesoAPPTitulo: 'ZUGANG ZUR APP',
  termsConditionsAccesoAPPParrafo1: 'Der Zugang und die Nutzung der APP sind beschränkt, und der Nutzer muss sich zunächst registrieren, um darauf zugreifen zu können.',
  termsConditionsUsoAPPTitulo: 'NUTZUNG DER APP',
  termsConditionsUsoAPPParrafo1: 'Der Nutzer verpflichtet sich, die APP, ihre Dienste und Inhalte ohne Verstoß gegen geltendes Recht, Vertrauen, allgemein anerkannten Gebrauch und die öffentliche Ordnung zu nutzen. Ebenso ist es verboten, die APP für unerlaubte oder schädliche Zwecke gegen CCOMMS oder Dritte zu verwenden oder in irgendeiner Weise Schäden zu verursachen oder den normalen Betrieb der APP zu beeinträchtigen.',
  termsConditionsResposabilidadTitulo: 'HAFTUNG DES NUTZERS FÜR SCHÄDEN',
  termsConditionsResposabilidadParrafo1: 'Die Nutzung der APP liegt in der alleinigen und ausschließlichen Verantwortung des Nutzers. Diese Haftung bezieht sich auch, auf die Verwendung von Passwörtern oder Ähnlichem, die für den Zugang zur APP oder zu ihren Diensten vergeben wurden, durch den Nutzer oder Dritte.',
  termsConditionsResposabilidadParrafo2: 'Unter Vorbehalt dessen behält sich CCOMMS das Recht vor, jederzeit und ohne Vorankündigung den Zugang zur APP oder den eingeschränkten Zugang für diejenigen Nutzer zu verweigern oder zu sperren, die gegen die vorliegenden AGB, die von CCOMMS oder seinen Partnern aufgestellten Regeln verstoßen oder das ordnungsgemäße Funktionieren, das Image, die Glaubwürdigkeit und/oder das Ansehen von CCOMMS oder seinen Partnern schädigen könnten.',
  termsConditionsResposabilidadParrafo3: 'In diesem Sinne behält sich CCOMMS das Recht vor, bestimmten Nutzern den Zugang zur APP zu verweigern oder einzuschränken, wenn sie ein Verhalten an den Tag legen, das nach Ansicht von CCOMMS gegen die vorliegenden Nutzungsbedingungen, das Gesetz, die von CCOMMS oder seinen Partnern aufgestellten Regeln verstößt oder das ordnungsgemäße Funktionieren, das Image, die Glaubwürdigkeit und/oder das Ansehen von CCOMMS oder seinen Mitarbeitern schädigen könnte. Ebenso kann CCOMMS die Benutzer für Schäden haftbar machen, die durch die missbräuchliche Nutzung der APP entstehen.',
  termsConditionsResposabilidadParrafo4: 'CCOMMS übernimmt keine Garantie für die Zulässigkeit, die Zuverlässigkeit, den Nutzen, den Wahrheitsgehalt oder die Genauigkeit der Inhalte, die der Nutzer bei der Nutzung der APP übermittelt.',
  termsConditionsResposabilidadParrafo5: 'CCOMMS hat keine Kontrolle über die Nutzung der APP durch die Nutzer und garantiert nicht, dass diese im Einklang mit den vorliegenden Allgemeinen Nutzungsbedingungen erfolgt.',
  termsConditionsResposabilidadParrafo6: 'CCOMMS hat keine Kontrolle oder Garantie bezüglich der Abwesenheit von Computerviren in den von Dritten über die APP bereitgestellten Diensten, die Veränderungen in Ihrem Computersystem (Software und Hardware) oder in den elektronischen Dokumenten und Dateien, die in Ihrem Computersystem gespeichert sind, verursachen können.',
  termsConditionsPropiedadIntelectualTitulo: 'INTELLEKTUELLES EIGENTUM',
  termsConditionsPropiedadIntelectualParrafo1: 'Jegliche Nutzung oder Änderung des Materials oder des Inhalts zu einem anderen als dem in den Allgemeinen Geschäftsbedingungen genehmigten Zweck wird als Verletzung der internationalen Urheberrechtsgesetze zum Schutz der Urheberrechte betrachtet.',
  termsConditionsPropiedadIntelectualParrafo2: 'Verbote hinsichtlich des Inhalts:',
  termsConditionsPropiedadIntelectualListaELemento1: 'Seine Vervielfältigung, Kopie, Verbreitung, Änderung oder Manipulation in jeglicher Form und zu jeglichem Zweck, es sei denn, es liegt eine vorherige schriftliche Genehmigung der rechtmäßigen Eigentümer vor oder es ist gesetzlich zulässig.',
  termsConditionsPropiedadIntelectualListaELemento2: 'Jegliche Verletzung der Rechte von CCOMMS oder der rechtmäßigen Eigentümer.',
  termsConditionsPropiedadIntelectualListaELemento3: 'Seine Verwendung zu jeglichen Werbe- oder kommerziellen Zwecken, sollten diese von den ausdrücklich erlaubten Zwecken abweichen.',
  termsConditionsPropiedadIntelectualParrafo3: 'CCOMMS garantiert, dass die Inhalte, einschließlich des intellektuellen Eigentums, nicht rechtswidrig sind und nicht gegen geltendes Recht verstoßen. Folglich darf der Inhalt der APP nicht fremdenfeindlich, pornografisch, diskriminierend, rassistisch oder in irgendeiner Weise gewaltfördernd sein.',
  termsConditionsPropiedadIntelectualParrafo4: 'Darüber hinaus wird CCOMMS alle rechtlichen Maßnahmen ergreifen, die es für angemessen hält, um ungesetzliches oder unmoralisches Verhalten zu verhindern.',
  termsConditionsModificacionUnilateralTitulo: 'EINSEITIGE ÄNDERUNG',
  termsConditionsModificacionUnilateralParrafo1: 'CCOMMS kann die Struktur und das Design der APP einseitig und ohne vorherige Ankündigung ändern, wann immer dies für angemessen gehalten wird, sowie Dienste, Inhalte, Zugangs- und/oder Nutzungsbedingungen der APP ändern oder abschaffen.',
  termsConditionsExclusionGarantiasTitulo: 'GARANTIE- UND HAFTUNGSAUSSCHLUSS',
  termsConditionsExclusionGarantiasParrafo1: 'CCOMMS übernimmt keine Garantie und ist in keinem Fall verantwortlich für Schäden jeglicher Art, die durch Folgendes verursacht wurden:',
  termsConditionsExclusionGarantiasListaELemento1: 'Die Nichteinhaltung der Gesetze, der Moral und der guten Sitten sowie der öffentlichen Ordnung oder die Nutzung der angebotenen Produkte und/oder Dienstleistungen auf nicht sorgsame oder falsche Weise oder für Zwecke oder Wirkungen, die rechtswidrig sind oder im Widerspruch zu den Bestimmungen der vorliegenden AGB oder den gegebenenfalls geltenden Bedingungen stehen.',
  termsConditionsExclusionGarantiasListaELemento2: 'Für den Erwerb oder Abschluss von Dienstleistungen, Waren oder Produkten, die von Dritten über einen Zugang in der APP von CCOMMS erworben oder abgeschlossen werden können, insbesondere in den Fällen, in denen der Kauf- oder Vertragsabschlussprozess direkt in der App des Dritten erfolgt und auch wenn sie unverwechselbar oder ein „Frame“ mit den grafischen Elementen der App erscheinen (Branding).',
  termsConditionsExclusionGarantiasListaELemento3: 'Fehlende Verfügbarkeit, Wartung und effektives Funktionieren der APP und/oder ihrer Dienste oder Inhalte.',
  termsConditionsExclusionGarantiasListaELemento4: 'Mangelnder Nutzen, Angemessenheit oder Gültigkeit der APP und/oder ihrer Dienste oder Inhalte für die Erfüllung der Bedürfnisse, Aktivitäten oder spezifischen Ergebnisse oder Erwartungen der Nutzer.',
  termsConditionsExclusionGarantiasListaELemento5: 'Existenz von Viren, bösartigen oder schädlichen Programmen in Inhalten.',
  termsConditionsExclusionGarantiasListaELemento6: 'Entgegennahme, Einholung, Speicherung, Verbreitung oder Übermittlung von Inhalten durch Nutzer.',
  termsConditionsExclusionGarantiasListaELemento7: 'Die Rechtswidrigkeit oder mangelnde Richtigkeit, Genauigkeit, Zugehörigkeit, Aktualität und Vollständigkeit der Inhalte und Informationen, die den Nutzern übermittelt oder zur Verfügung gestellt werden, einschließlich der von Dritten oder von Nutzern über die APP bereitgestellten Informationen und Dienste.',
  termsConditionsExclusionGarantiasListaELemento8: 'Nichteinhaltung der Verpflichtungen oder Zusagen Dritter im Zusammenhang mit den Diensten, die den Nutzern über die APP geboten werden.',
  termsConditionsExclusionGarantiasListaELemento9: 'Von Inhalten, Dateien, Informationen, Werbung, Meinungen, Konzepten und Bildern, die weder von der APP abhängen, noch von CCOMMS verwaltet werden. Gehaftet wird auch nicht für den Missbrauch der Inhalte der APP, für den allein derjenige verantwortlich ist, der auf sie zugreift oder sie nutzt.',
  termsConditionsExclusionGarantiasListaELemento10: 'Die missbräuchliche Verwendung der Zugangscodes der verantwortlichen Benutzer für den Zugang zu den Inhalten des APP-Dienstes und die Folgen jeglicher Art, die sich aus der missbräuchlichen Verwendung durch die Benutzer, ihrem Verlust oder Vergessen und ihrer missbräuchlichen Verwendung durch unbefugte Dritte ergeben.',
  termsConditionsExclusionGarantiasListaELemento11: 'Die Unfähigkeit eines Benutzers oder Identitätsbetrug von Dritten durch den Benutzer.',
  termsConditionsExclusionGarantiasListaELemento12: 'Von Inhalten, die von den Benutzern bereitgestellt werden.',
  termsConditionsProteccionDatosTitulo: 'DATENSCHUTZ',
  termsConditionsProteccionDatosParrafo1: 'Ausführliche Informationen zum Datenschutz finden Sie auf unserer Website unter',
  termsConditionsLeyJurisdiccionTitulo: 'ANWENDBARES RECHT UND GERICHTSBARKEIT',
  termsConditionsLeyJurisdiccionParrafo1: 'Alle in dieser APP enthaltenen Allgemeinen und Besonderen Bedingungen unterliegen dem spanischen Recht. Alle Streitigkeiten, Kontroversen oder Situationen, die sich aus den Allgemeinen und Besonderen Bedingungen ergeben, werden den Gerichten der Stadt Madrid vorgelegt, und die Parteien verzichten ausdrücklich auf ihre eigene Zuständigkeit.',
  termsConditionsProteccionDatosTitle: 'Datenschutzinformationen einsehen',
  termsConditionsProteccionDatos: 'Datenschutz',
  //Privacy Policies
  privacyPoliciesConditionsTitle: 'Datenschutz von Mailteck und Customer Communications Tecknalia',
  privacyPoliciesConditionsParrafo1: 'Im Einklang mit den Bestimmungen der Verordnung (EU) 2016/679 des Europäischen Parlaments und des Rates vom 27. April 2016 zum Schutz natürlicher Personen bei der Verarbeitung personenbezogener Daten und zum freien Datenverkehr (RGPD) sowie dem Organgesetz 3/2018 vom 5. Dezember über den Schutz personenbezogener Daten und die Gewährleistung digitaler Rechte (LOPD-GDD) und anderen geltenden Datenschutzvorschriften werden die Nutzer der APP, die durch Akzeptieren des Feldes „Datenschutzpolitik“ freiwillig, klar und eindeutig ihre Zustimmung zur Verarbeitung personenbezogener Daten gegeben haben, darüber informiert, dass ihre Daten je nach dem Unternehmen, bei dem sie sich registriert haben, entweder von der Firma MAILTECK, S. A. oder von der Gesellschaft CUSTOMER COMMUNICATIONS TECKNALIA, S.L. verarbeitet werden, die gemeinsam als MAILCOMMS GROUP bezeichnet werden.',
  privacyPoliciesConditionsResponsable1Title: 'Verantwortlicher 1',
  privacyPoliciesConditionsResponsable2Title: 'Verantwortlicher 2',
  privacyPoliciesConditionsFinalidadTitle: 'Zweck',
  privacyPoliciesConditionsLegitimacionTitle: 'Legitimierung',
  privacyPoliciesConditionsDestinatariosTitle: 'Empfänger',
  privacyPoliciesConditionsDerechosTitle: 'Rechte',
  privacyPoliciesConditionsInformacionAdicionalTitle: 'Zusätzliche Informationen',
  privacyPoliciesConditionsPoliticaInformacion1Title: 'Informationspolitik 1',
  privacyPoliciesConditionsPoliticaInformacion2Title: 'Informationspolitik 2',
  privacyPoliciesConditionsIdentidadTitle: 'Identität',
  privacyPoliciesConditionsNIFTitle: 'Steuernummer',
  privacyPoliciesConditionsDireccionTitle: 'Adresse',
  privacyPoliciesConditionsTelefonoTitle: 'Telefonnummer',
  privacyPoliciesConditionsCorreoTitle: 'E-Mail',
  privacyPoliciesConditionsDPDTitle: 'Kontakt mit dem Datenschutzbeauftragten',
  privacyPoliciesConditionsDPDCustomerCommsTitle: 'Datenschutzbeauftragter',
  privacyPoliciesConditionsDPDMailteckTitle: 'Datenschutzbeauftragter von Mailteck',
  privacyPoliciesConditionsFinalidadIntro: 'Verwaltung und Abwicklung der vereinbarten Dienstleistungen',
  privacyPoliciesConditionsFinalidadParrafo1: 'GRUPO MAILCOMMS verarbeitet die zur Verfügung gestellten Daten im Rahmen der Ausführung eines von Ihnen oder Ihrem Unternehmen unterzeichneten Vertrags, um die darin vereinbarten Dienstleistungen zu verwalten und zu bearbeiten sowie Support für die Certysign-Anwendung bereitzustellen.',
  privacyPoliciesConditionsFinalidadParrafo2: 'GRUPO MAILCOMMS wird die zur Verfügung gestellten Daten nur für die oben beschriebenen Zwecke verarbeiten und nicht in einer Weise, die diesen Zwecken widerspricht. Die übermittelten personenbezogenen Daten werden für die Dauer des Vertragsverhältnisses aufbewahrt.',
  privacyPoliciesConditionsLegitimacionIntro: 'Ausführung des Vertrages',
  privacyPoliciesConditionsLegitimacionParrafo1: 'Die Daten wurden von Ihnen oder Ihrem Unternehmen über die etablierten Kanäle zur Verfügung gestellt, um Ihnen den Zugang zur Certysign-Anwendung zu ermöglichen, als Folge des abgeschlossenen Dienstleistungsvertrags.',
  privacyPoliciesConditionsDestinatariosIntro: 'Sie werden nicht an Dritte weitergegeben.',
  privacyPoliciesConditionsDestinatariosParrafo1: 'Aufgrund gesetzlicher Vorschriften, wenn dies gesetzlich vorgeschrieben wird.',
  privacyPoliciesConditionsDerechosIntro: 'Sie können Ihre Rechte in der gesetzlich vorgesehenen Weise und innerhalb der gesetzlich vorgesehenen Fristen ausüben.',
  privacyPoliciesConditionsDerechosElem1ListaTitulo: 'Allgemeine Bestätigung:',
  privacyPoliciesConditionsDerechosElem1ListaDescripcion: 'Jede Person hat das Recht, eine Bestätigung darüber zu erhalten, ob GRUPO MAILCOMMS sie betreffende personenbezogene Daten verarbeitet.',
  privacyPoliciesConditionsDerechosElem2ListaTitulo: 'ARCO-Rechte;',
  privacyPoliciesConditionsDerechosElem2ListaDescripcion: 'Die Betroffenen Personen haben das Recht, auf ihre persönlichen Daten zuzugreifen sowie ungenaue Daten zu korrigieren oder gegebenenfalls deren Löschung zu verlangen, wenn die Daten unter anderem für die Zwecke, für die sie erhoben wurden, nicht mehr erforderlich sind.',
  privacyPoliciesConditionsDerechosElem3ListaTitulo: 'Verarbeitungseinschränkungen',
  privacyPoliciesConditionsDerechosElem3ListaDescripcion: 'Unter bestimmten Umständen können betroffene Personen die Einschränkung der Verarbeitung ihrer Daten fordern. In diesem Fall werden wir die Daten nur zum Zweck der Geltendmachung oder Verteidigung von Ansprüchen aufbewahren.',
  privacyPoliciesConditionsDerechosElem4ListaTitulo: 'Widerspruch gegen die Verarbeitung:',
  privacyPoliciesConditionsDerechosElem4ListaDescripcion: 'Unter bestimmten Umständen und aus Gründen hinsichtlich ihrer persönlichen Situation, können die betroffenen Personen der Verarbeitung ihrer Daten widersprechen. Aus diesem Grund wird GRUPO MAILCOMMS die Verarbeitung der Daten einstellen, sollten keine zwingenden legitimen Gründe oder die Ausübung oder Verteidigung möglicher Ansprüche vorliegen.',
  privacyPoliciesConditionsDerechosElem5ListaTitulo: 'Übertragbarkeit der Daten:',
  privacyPoliciesConditionsDerechosElem5ListaDescripcion: 'Unter bestimmten Umständen und aus Gründen hinsichtlich ihrer persönlichen Situation können die betroffenen Personen den Erhalt der angegebenen persönlichen Daten in einem Kontaktformular in strukturiertem, allgemein gebräuchlichem und maschinenlesbarem Format anfordern und diese Daten ungehindert an einen anderen Verantwortlichen für die Verarbeitung übermitteln.',
  privacyPoliciesConditionsDerechosElem6ListaTitulo: 'Beschwerde bei einer öffentlichen Einrichtung:',
  privacyPoliciesConditionsDerechosElem6ListaDescripcion: 'Wenn Sie der Meinung sind, dass Ihr Recht auf den Schutz persönlicher Daten verletzt wurde, können Sie eine Beschwerde bei der spanischen Datenschutzbehörde einreichen.',
  privacyPoliciesConditionsDerechosAEPDTitle: 'AEPD',
  privacyPoliciesConditionsDerechosElem7ListaTitulo: 'Kanal zur Ausübung der Rechte:',
  privacyPoliciesConditionsDerechosElem7ListaDescripcion: 'Die betroffenen Personen können die in den Verordnungen anerkannten Rechte gegenüber jedem Verantwortlichen über die unter den im Abschnitt „Verantwortliche“ angegebenen und mit DSB gekennzeichneten Adressen ausüben.',
  privacyPoliciesConditionsInformacionAdicionalIntro: 'Weitere Datenschutzinformationen.',
  privacyPoliciesConditionsInformacionAdicionalLista1: 'Die persönlichen Daten stammen aus den Kanälen, die die Unternehmen der MAILCOMMS GROUP eingerichtet haben, um die von Ihnen oder Ihrem Unternehmen in Auftrag gegebenen Dienstleistungen zu verwalten.',
  privacyPoliciesConditionsInformacionAdicionalLista2: 'Die zu behandelnden Datenkategorien sind die Daten, die für die Durchführung jeder in Auftrag gegebenen Dienstleistung bereitgestellt werden.',
  privacyPoliciesConditionsInformacionAdicionalLista3: 'GRUPO MAILCOMMS verarbeitet keine besonders geschützten Daten.',
  privacyPoliciesConditionsPoliticaInformacionIntro: 'MailTecK, S.A. Informationssicherheits- und Datenschutzpolitik basierend auf der Norm ISO 27001 -> Mehr lesen.',
  privacyPoliciesConditionsPoliticaInformacio2nIntro: 'Kundenkommunikation Tecknalia, S.L. Informationssicherheits- und Datenschutzpolitik basierend auf der Norm ISO 27001 -> Mehr lesen.',
  privacyPoliciesConditionsPoliticaInformacionTituloH: 'ANHANG I: SYSTEMVERWALTUNGSPOLITIK FÜR INFORMATIONSSICHERHEIT UND DATENSCHUTZ',
  privacyPoliciesConditionsPoliticaInformacionP1: 'Diese Informationssicherheits- und des Datenschutzpolitik dokumentiert das Engagement der Geschäftsleitung, indem sie die Organisation mit Ressourcen, Verantwortlichkeiten und Verfahren ausstattet, um die Einhaltung der geltenden Vorschriften sowie die Integrität, Vertraulichkeit und Verfügbarkeit von Informationen und Systemen zu gewährleisten, was für die Sicherheit, Privatsphäre und die Kontinuität unserer Organisation sowie die unserer Kunden von entscheidender Bedeutung ist.',
  privacyPoliciesConditionsPoliticaInformacionP2: 'Grupo Mailteck ist sich bewusst, dass die Sicherheit und der Schutz der Daten unserer Kunden von großem Wert ist. Daher hat sie ein Verwaltungssystem für Informationssicherheit und Datenschutz gemäß den Anforderungen von ISO/IEC 27001:2013 und ISO/IEC 27701:2019 eingerichtet, um das erforderliche Sicherheitsniveau, die Kontinuität der erbrachten Dienstleistungen und die Einhaltung der gesetzlichen Vorschriften bei der Verarbeitung persönlicher Daten zu gewährleisten und so die Risiken zu minimieren, die sich aus dieser Verwaltung ergeben.',
  privacyPoliciesConditionsPoliticaInformacionCCTP2: 'Custommer Comms ist sich bewusst, dass die Sicherheit und der Schutz der Daten unserer Kunden von großem Wert ist. Daher hat sie ein Verwaltungssystem für Informationssicherheit und Datenschutz gemäß den Anforderungen von ISO/IEC 27001:2013 und ISO/IEC 27701:2019 eingerichtet, um das erforderliche Sicherheitsniveau, die Kontinuität der erbrachten Dienstleistungen und die Einhaltung der gesetzlichen Vorschriften bei der Verarbeitung persönlicher Daten zu gewährleisten und so die Risiken zu minimieren, die sich aus dieser Verwaltung ergeben.',
  privacyPoliciesConditionsPoliticaInformacionP3: 'Ziel des Sicherheits- und Datenschutzkonzepts ist es, den Handlungsrahmen festzulegen, der zum Schutz der Informationsressourcen vor internen oder externen, absichtlichen oder zufälligen Bedrohungen erforderlich ist, um die Einhaltung der Vertraulichkeit, Integrität und Verfügbarkeit von Informationen zu gewährleisten.',
  privacyPoliciesConditionsPoliticaInformacionP4: 'Die Wirksamkeit und Umsetzung des Verwaltungssystems für Informationssicherheit und Datenschutz unterliegt der direkten Verantwortung des Ausschusses für Informationssicherheit und Datenschutz, der für die Genehmigung, Verbreitung und Durchsetzung der vorliegenden Sicherheits- und Datenschutzpolitik zuständig ist. In ihrem Namen und in ihrem Auftrag wurde ein Beauftragter für das Informationssicherheits- und Datenschutzverwaltungssystem ernannt, der über ausreichende Befugnis verfügt, um eine aktive Rolle im Informationssicherheits- und Datenschutzverwaltungssystem zu spielen und dessen Umsetzung, Entwicklung und Pflege zu überwachen.',
  privacyPoliciesConditionsPoliticaInformacionP5: 'Um die Einhaltung der geltenden Datenschutzbestimmungen zu gewährleisten, wurde außerdem ein Datenschutzbeauftragter (DSB) ernannt, mit dem Ziel, die Sicherheit und den Schutz persönlicher Daten zu garantieren, besonders hinsichtlich der Tätigkeit des Unternehmens.',
  privacyPoliciesConditionsPoliticaInformacionP6: 'Der Ausschuss für Informationssicherheit und Datenschutz wird die im Verwaltungssystem für Informationssicherheit und Datenschutz verwendete Methode fü Risikoanalyse erarbeiten und genehmigen.',
  privacyPoliciesConditionsPoliticaInformacionP7: 'Jede Person, deren Tätigkeit direkt oder indirekt von den Anforderungen des Verwaltungssystems für Informationssicherheits- und Datenschutz betroffen sein kann, ist der Einhaltung des Sicherheits- und Datenschutzplans strikt verpflichtet.',
  privacyPoliciesConditionsPoliticaInformacionP8: 'Grupo Mailteck wird alle erforderlichen Maßnahmen ergreifen, um die geltenden Vorschriften zur Sicherheit und zum Schutz der Privatsphäre im Allgemeinen und zur IT-Sicherheit in Bezug auf die IT-Politik, die Sicherheit von Gebäuden und Einrichtungen sowie das Verhalten von Mitarbeitern und Dritten, die mit der Mailteck-Gruppe verbunden sind, bei der Nutzung von IT-Systemen und der Verarbeitung persönlicher Daten einzuhalten. Die Maßnahmen, die erforderlich sind, um die Sicherheit und den Schutz von Informationen durch die Anwendung von Standards, Verfahren und Kontrollen zu gewährleisten, müssen die Vertraulichkeit, Integrität und Verfügbarkeit von Informationen sicherstellen, unabdingbar für:',
  privacyPoliciesConditionsPoliticaInformacionCCTP8: 'Custommer Comms wird alle erforderlichen Maßnahmen ergreifen, um die geltenden Vorschriften in Bezug auf die Sicherheit und den Datenschutz im Allgemeinen und die Computersicherheit einzuhalten, hinsichtlich der Computerpolitik, der Sicherheit von Gebäuden und Anlagen sowie das Verhalten von Mitarbeitern und Dritten, die mit Custommer Comms bei der Nutzung von Computersystemen und der Verarbeitung persönlicher Daten verbunden sind. Die Maßnahmen, die erforderlich sind, um die Sicherheit und den Schutz von Informationen durch die Anwendung von Standards, Verfahren und Kontrollen zu gewährleisten, müssen die Vertraulichkeit, Integrität und Verfügbarkeit von Informationen sicherstellen, unabdingbar für:',
  privacyPoliciesConditionsPoliticaInformacionLista1: 'Einhaltung der geltenden Gesetze, Verordnungen und Normen zum Schutz der Privatsphäre und der Informationssysteme sowie aller vertraglichen Datenschutzanforderungen sowie derjenigen, deren Erfüllung im Hinblick auf eine kontinuierliche Verbesserung der Organisation für angemessen gehalten werden.',
  privacyPoliciesConditionsPoliticaInformacionLista2: 'Gewährleistung der Vertraulichkeit der von Grupo Mailteck verwalteten Daten.',
  privacyPoliciesConditionsPoliticaInformacionCCTLista2: 'Gewährleistung der Vertraulichkeit der von Custommer Comms verwalteten Daten.',
  privacyPoliciesConditionsPoliticaInformacionLista3: 'Sicherstellung der Verfügbarkeit von Informationssystemen, sowohl für die den Kunden angebotenen Dienstleistungen als auch für die interne Verwaltung.',
  privacyPoliciesConditionsPoliticaInformacionLista4: 'Sicherstellung der Reaktionsfähigkeit bei Notfällen zum raschen Wiederherstellen der Funktion kritischer Dienste.',
  privacyPoliciesConditionsPoliticaInformacionLista5: 'Vermeidung unzulässiger Änderungen von Informationen.',
  privacyPoliciesConditionsPoliticaInformacionLista6: 'Förderung des Bewusstseins und der Ausbildung bezüglich Informationssicherheit und Datenschutz.',
  privacyPoliciesConditionsPoliticaInformacionLista7: 'Förderung und Mitwirkung an der kontinuierlichen Verbesserung des Verwaltungssystems für Informationssicherheit und Datenschutz.',
  cookiesPoliciesIntroText: 'Diese Website verwendet Cookies, um Ihre Erfahrung beim Surfen auf der Website zu verbessern. Sie können diese Einstellungen jederzeit ändern. Als notwendig eingestufte Cookies werden in Ihrem Browser gespeichert und sind wesentlich für den Betrieb der Grundfunktionen der Website. Cookies von Dritten werden nur mit Ihrer Zustimmung in Ihrem Browser gespeichert und helfen uns zu analysieren und zu verstehen, wie diese Website genutzt wird. Sie haben die Möglichkeit, Cookies abzulehnen, dies kann jedoch dazu führen, dass einige Funktionen nicht mehr verfügbar sind. Informationen zum Löschen von Cookies entnehmen Sie bitte der Hilfefunktion Ihres Browsers.',
  cookiesPoliciesNecessaryCookies: 'Notwendige Cookies',
  cookiesPoliciesNecessaryCookiesText: 'Dieser Cookie-Typ ist für das korrekte Funktionieren der Website absolut notwendig. Zu dieser Kategorie gehören nur Cookies, die grundlegende Funktionen und Sicherheitsmerkmale der Website garantieren und keine persönlichen Informationen über die Nutzer der Website erfassen oder speichern.',
  cookiesPoliciesUnnecessaryCookies: 'Nicht notwendige Cookies',
  cookiesPoliciesUnnecessaryCookiesText: 'Cookies, die für das Funktionieren der Website nicht unbedingt erforderlich sind und speziell für die Erfassung persönlicher Daten des Benutzers durch Analysen, Werbung und andere eingebettete Inhalte verwendet werden, werden als nicht erforderliche Cookies bezeichnet. Es ist notwendig, die Zustimmung des Benutzers vor der Ausübung der Cookies auf Ihrer Website einzuholen.',
  cookiesPoliciesGoogleAnalyticsCookies: 'Google Analytics-Cookies',
  cookiesPoliciesGoogleAnalyticsCookiesText: 'Weitere Informationen über diese Art von Cookies finden Sie in dem Dokument „Verwendung von Google Analytics-Cookies auf Websites“',
  cookiesPoliciesGoogleAnalyticsCheckboxText: 'Cookies aktiviert lassen',
  cookiesNotificationBarText: 'Wir verwenden Cookies, sowohl eigene als auch Cookies von Dritten, um Besuche zu messen, die Funktionalität der Website zu gewährleisten und das Surfen auf der Website zu analysieren. Im Folgenden können Sie Cookies akzeptieren oder die Verwendung von Cookies einstellen/ablehnen. Weitere Informationen über die Inhaberschaft der Website, verantwortlich für die Verarbeitung von Cookies, und deren Kontaktdaten finden Sie in unserer',
  cookiesNotificationBarConfig: 'Einstellungen',
  cookiesPoliciesModalIntro: 'Einleitung',
  cookiesPoliciesModalIntroP1: 'Diese Website verwendet Cookies und/oder ähnliche Technologien, die beim Surfen Informationen speichern und einholen, um das Benutzererlebnis zu verbessern und zu optimieren.',
  cookiesPoliciesModalIntroP2: 'Im Allgemeinen können diese Technologien einer Vielzahl von Zwecken dienen, wie z. B. der Erkennung des Benutzers, der Erfassung von Informationen über Ihre Surfgewohnheiten oder der Personalisierung der Darstellung von Inhalten.',
  cookiesPoliciesModalIntroP3: 'In Übereinstimmung mit dem Königlichen Gesetzesdekret 13/2012 und seiner Auswirkung auf Artikel 22.2 des Gesetzes über die Dienste der Informationsgesellschaft und den elektronischen Handel informiert Sie diese Richtlinie über die verschiedenen Cookies, die CUSTOMER COMMUNICATIONS TECKNALIA, S.L. über die verschiedenen Kommunikationsmittel oder -kanäle, eigene oder fremde, von der Informationspflicht befreite oder nicht befreite, sammelt, sowie über die verschiedenen Mittel, mit denen Sie diese ablehnen, zulassen oder blockieren können.',
  cookiesPoliciesModalIntroP4: 'Im Folgenden finden Sie ausführliche Informationen darüber, was „Cookies“ sind, welche Art diese Website verwendet, wie sie in einem Browser deaktiviert werden können und wie Sie die Installation von Cookies Dritter gezielt blockieren können.',
  cookiesPoliciesModalResponsibility: 'Wer ist für die Verwendung von Cookies auf dieser Website verantwortlich?',
  cookiesPoliciesModalResponsibilityP1: 'Verantwortlich für die Verwendung von Cookies ist CUSTOMER COMMUNICATIONS TECKNALIA, S.L. (im Folgenden CCOMMS), mit der Steuernummer B-86414000 und Sitz in Leganés (Madrid), Avenida de la Recomba, 14, eingetragen im Handelsregister von Madrid in Band 29.801, Folio 98, Sektion 8ª, Blattnummer M-536210.',
  cookiesPoliciesModalResponsibilityP2: 'E-Mai-Adresse des Datenschutzbeauftragten.',
  cookiesPoliciesModalWhatIsCookie: 'Was ist ein Cookie?',
  cookiesPoliciesModalWhatIsCookieP1: 'Ein Cookie ist ein Wert, eine Datei oder eine Vorrichtung zur Speicherung und Abrufung von Daten, das ein Webbrowser automatisch auf dem Gerät eines Nutzers (Smartphone, Tablet oder angeschlossenes Fernsehgerät) speichert, wenn dieser auf Webseiten oder Konten in sozialen Netzwerken zugreift oder eine E-Mail erhält.',
  cookiesPoliciesModalWhatIsCookieP2: 'Cookies ermöglichen die Speicherung und den automatischen Abruf von Daten über die Nutzung der genannten digitalen Medien und der Geräte, von denen aus Sie auf sie zugreifen.',
  cookiesPoliciesModalWhatIsCookieP3: 'Bei einem Cookie handelt es sich lediglich um Daten, die auf dem Computer des Nutzers gespeichert werden, also nicht um Software, nicht um Code-Fragmente, sondern um reine Daten. Daher können Cookies im Prinzip keine Viren übertragen und ausführen oder Malware wie Trojaner oder Spyware installieren.',
  cookiesPoliciesModalCookieTypes: 'Welche Cookie-Typen gibt es auf der Website und mit welchem Zweck?',
  cookiesPoliciesModalCookiesInstall: 'Welche Cookies kann man auf der CertySign-Website installieren?',
  cookiesPoliciesModalCookiesInstallP1: 'Der Zugriff auf die CertySign-Website setzt voraus, dass die in der folgenden Tabelle aufgeführten Cookies, eigene und/oder von Dritten, gespeichert werden können:',
  cookiesPoliciesModalBrowserConfigCookies: 'Wie Sie Cookies im Browser konfigurieren',
  cookiesPoliciesModalBrowserConfigCookiesP1: 'Sie haben in Ihren Browsereinstellungen die Möglichkeit zu bestimmen, keine Cookies zu erhalten oder über deren Festlegung informiert zu werden. Alle Cookies, mit Ausnahme von Flash-Cookies, können von Ihrem Browser aus verwaltet werden, normalerweise im Abschnitt „Optionen“ oder „Einstellungen“.',
  cookiesPoliciesModalBrowserConfigCookiesP2: 'Sie können diese ganz deaktivieren, in diesem Fall ließe Ihr Browser die Installation nicht zu (wodurch Sie nicht alle Funktionen der Website nutzen können), oder nur jene von bestimmten Websites, sodass Sie die erweiterten Funktionen von Websites nutzen können, die den Schutz Ihrer Daten respektieren, und diejenigen ausschließen, die dies nicht tun.',
  cookiesPoliciesModalBrowserConfigCookiesP3: 'Die folgenden Links bieten Zugang zu den wichtigsten Support-Seiten:',
  cookiesPoliciesModalBrowserConfigCookiesP4: 'Ebenso kann der Benutzer die Verwendung bestimmter Cookies mit Hilfe der folgenden Dienste ablehnen:',
  cookiesPoliciesModalBrowserConfigCookiesLI1: 'Blockierung von Cookies in Chrome',
  cookiesPoliciesModalBrowserConfigCookiesLI2: 'Blockierung von Cookies in Safari',
  cookiesPoliciesModalBrowserConfigCookiesLI3: 'Blockierung von Cookies in Firefox',
  cookiesPoliciesModalBrowserConfigCookiesLI4: 'Blockierung von Cookies in Internet Explorer',
  cookiesPoliciesModalBrowserConfigCookiesLI5: 'http://www.criteo.com/deactivate-criteo-banners/',
  cookiesPoliciesModalBrowserConfigCookiesLI6: 'http://youronlinechoices.eu/',
  cookiesPoliciesModalBrowserConfigCookiesLI7: 'http://www.networkadvertising.org/choices/',
  cookiesPoliciesModalUnavailableCookies: 'Was geschieht bei der Deaktivierung von Cookies?',
  cookiesPoliciesModalUnavailableCookiesP1: 'Wenn Sie Cookies ablehnen, können Sie weiterhin auf unserer Website surfen, auch wenn dies die Nutzung einiger Dienste einschränken und Ihre Erfahrung dadurch weniger zufriedenstellend sein kann.',
  cookiesPoliciesModalUnavailableCookiesP2: 'Die Ablehnung, Blockierung oder Nichtzulassung der in dieser Richtlinie beschriebenen Cookies kann das korrekte Browsen der auf der Website wiedergegebenen Inhalte beeinträchtigen.',
  cookiesPoliciesModalAcceptCookies: 'Bestätigung der Cookie-Richtlinien',
  cookiesPoliciesModalAcceptCookiesP1: 'Durch das Klicken des Feldes „Cookies akzeptieren“ wird angenommen, dass Sie mit der Nutzung von Cookies einverstanden sind.',
  cookiesPoliciesModalAcceptCookiesP2: 'Mit jedem Einloggen wird die Cookie-Richtlinie jedoch am unteren Rand jeder Seite der Website angezeigt, damit diese zur Kenntnis genommen werden kann.',
  cookiesPoliciesModalUpgradeCookies: 'Aktualisieren wir unsere Cookie-Richtlinien?',
  cookiesPoliciesModalUpgradeCookiesP1: 'Es ist möglich, dass die in diesen Cookie-Richtlinien enthaltenen Informationen aufgrund von Aktualisierungen oder Änderungen der Cookie-Behandlungspolitik geändert werden, ebenso wie die spanische Datenschutzbehörde Anweisungen und/oder Empfehlungen herausgeben kann, die sich von diesen unterscheiden können.',
  cookiesPoliciesModalUpgradeCookiesP2: 'Wir empfehlen Ihnen daher, diese Richtlinie jedes Mal durchzulesen, wenn Sie auf unsere Website zugreifen, um sich genau darüber zu informieren, wie und zu welchem Zweck die Cookies verwendet werden.',
  cookiesPoliciesModalRecipientCookies: 'Wer sind die Empfänger der Informationen und wie wirkt sich die Verwendung von Cookies auf den Schutz persönlicher Daten aus?',
  cookiesPoliciesModalRecipientCookiesP1: 'Weitere Informationen über die Verarbeitung Ihrer Daten finden Sie in den',
  cookiesPoliciesModalRecipientCookiesLinkCCOMMS: 'Datenschutzrichtlinien',
  cookiesPoliciesModalContactInfoCookies: 'Kontaktinformationen',
  cookiesPoliciesModalContactInfoCookiesP1: 'Bei Fragen, Klärungen und/oder Vorschlägen zu Cookies, wenden Sie sich bitte per E-Mail an uns.',
  cookiesPoliciesModalDatatableCookieType: 'Cookie-Typ',
  cookiesPoliciesModalDatatableTreatmentTerm: 'Bearbeitungszeitraum',
  cookiesPoliciesModalDatatableTypeAnalytics: 'Analyse-Cookies',
  cookiesPoliciesModalDatatableTypeSelf: 'Eigene Cookies',
  cookiesPoliciesModalDatatableTypeAnalyticsD: 'Immer, wenn ein Benutzer einen Service in Anspruch nimmt, generiert ein Werkzeug eines externen Anbieters einen analytischen Cookie auf dem Benutzercomputer. Dieser Cookie, der nur beim Besuch generiert wird, dient CCOMMS bei zukünftigen Besuchen, um den Besucher anonym identifizieren zu können.',
  cookiesPoliciesModalDatatableTypeAnalyticsF: 'Es wird erlaubt, durch den „Cookie“ den Benutzer auf anonyme Weise zu identifizieren (identifiziert Browser und Geräte, nicht Personen) und damit eine ungefähre Zählung der Besucheranzahl und ihre Entwicklung im Laufe der Zeit. Anonyme Identifizierung der meistbesuchten und attraktivsten Inhalte für die Nutzer. Um zu wissen, ob der Benutzer, der auf die Website zugreift, ein neuer Besucher ist, oder bereits wiederholt besucht. Sofern der Nutzer nicht beschließt, sich bei CCOMMS zu registrieren, wird der „Cookie“ niemals mit persönlichen Daten verknüpft, die eine direkte Identifizierung des Nutzers ermöglichen. Besagte „Cookies“ werden nur zu statistischen Zwecken genutzt und zur Optimierung und Verbesserung der Benutzererfahrung auf der Website.',
  cookiesPoliciesModalDatatableTypeAnalyticsT: 'Permanente Cookies',
  cookiesPoliciesModalDatatableTypeSelfD: 'Dieser Cookie-Typ erlaubt es, die Informationen zu erweitern, die jedem anonymen Benutzer in den CCOMMS-Diensten angezeigt werden.',
  cookiesPoliciesModalDatatableTypeSelfF: 'Gespeichert werden unter anderem die Dauer oder Häufigkeit des Besuchs der verschiedenen Sektionen, die Interaktion mit ihnen oder die Browsing-Muster und/oder Verhaltensweisen des Benutzers.',
  cookiesPoliciesModalDatatableTypeSelfT: 'Permanente Cookies',
  eventInfo: 'Informationen zum Ereignis',
  manual: 'Handbuch',
  guia: 'Leitfaden',
  supportLoggedPhonePrefferedMessage: '(Benutzer-Support bereits eingeloggt - Telefon bevorzugt)',
  supportLoggedNotPhoneMessage: 'Wählt Telefon nicht an',
  supportLoggedUserMessage: '(Benutzer-Support bereits eingeloggt)',
  supportNotLoggedUserWebMessage: '(Login - Internet-Support) Der Benutzer fordert Unterstützung zur .Wiederherstellung des Passwortes',
  supportNotLoggedUserNotAvailableMessage: 'Nicht verfügbar',
  supportNotLoggedUserC2CMessage: '(Login - C2C-Support) Der Benutzer fordert telefonische Unterstützung zur Wiederherstellung des Passwortes.',
  processA4NotSupported: 'Die Dokumente sollten A4-Format haben und 389 Seiten oder 15 MB nicht überschreiten.',
  cookiesGAMessage: 'Interne Messung mit Google Analytics. Diese Cookies speichern eine einzigartige Kennung zur Wiedererkennung des Benutzers bei künftigen Besuchen auf der Website.',
  cookiesGAFGenericMessage: 'Interne Messung mit Google Analytics.',
  cookiesCookieMessage: 'Cookie',
  cookiesNecessaryCookieDescription: 'Dieser Cookie ist notwendig und wird zur Einstellung der Cookie-Richtlinien des Benutzers verwendet.',
  documentHtmlRule: 'Zulässiger Dateityp - HTML',
  rulesFilesSizePostal: 'Der Anhang darf höchstens 15 MB umfassen.',
  rulesFilesSizeEmail: 'Der Anhang darf höchstens 14Mb wiegen',
  rulesFilesSizeMultiple: 'Die maximale Gesamtgröße der Dateien beträgt 14 MB.',
  rulesFilesSizeHtml: 'Der Anhang darf höchstens 1Mb wiegen',
  rulesFilesExtensionHtml: 'Es muss eine HTML-Datei sein.',
  group_cannot_be_empty: 'Die Gruppe :group_name: darf nicht leer sein.',
  language: 'Sprache',
  defaultLanguage: 'Standardsprache',
  transactionDownloadPee: 'PEE anzeigen',
  transactionTracking: 'Sendungsverfolgung',
  documentOrder: 'Dokumentenreihenfolge festlegen',
  yes: 'Ja',
  no: 'Nein',
  notifications: 'Benachrichtigungen',
  activateNotifications: 'Aktivieren',
  deactivateNotifications: 'Deaktivieren',
  selectCategoryNotifications: 'Wählen Sie eine Handlung für Benachrichtigungen.',
  activateCategoryNotifications: 'Alle aktivieren',
  deactivateCategoryNotifications: 'Alle deaktivieren',
  customCategoryNotifications: 'Personalisieren',
  enabledNotifications: 'Benachrichtigungen aktiviert',
  disabledNotifications: 'Benachrichtigungen deaktiviert',
  customNotifications: 'Benachrichtigungen personalisiert',
  selectNotifications: 'Benachrichtigungseinstellungen auswählen',
  enabledNotificationsDescriptionText: 'Jedes durchgeführte Ereignis generiert eine Benachrichtigung. Sie können auswählen, ob sie jeweils eine einzelne Benachrichtigung oder einen Tagesbericht mit gruppierten Benachrichtigungen erhalten möchten.',
  disabledNotificationsDescriptionText: 'Sie werden keine Benachrichtigungen über den Status der erfolgten Sendungen erhalten.',
  customNotificationsDescriptionText: 'Sie können Prozesse auswählen, über deren Status Sie Benachrichtigungen erhalten möchten, und Prozesse, für die Sie dies nicht wünschen.',
  enabledNotificationsConfigurationText: 'Einstellung',
  enabledNotificationsConfigurationRealTime: 'Benachrichtigungen in Echtzeit',
  enabledNotificationsConfigurationGrouped: 'Gruppierte Benachrichtigungen',
  selectEnabledNotifications: 'Benachrichtigungstyp auswählen',
  enabledNotificationsRealTimeDescriptionText: 'Erhalten Sie eine unmittelbare E-Mail bei jedem durchgeführtem Ereignis.',
  enabledNotificationsGroupedDescriptionText: 'Sie werden im ausgewählten Zeitraum eine E-Mail mit dem Bericht über alle stattgefundenen Ereignisse des vergangenen Tages erhalten.',
  enabledNotificationsScheduleRangeDescriptionText: 'Im folgenden Zeitraum benachrichtigen.',
  enabledNotificationsScheduleDescriptionText: 'Nur im folgenden Zeitraum benachrichtigen.',
  chooseNotificationsRange: 'Wählen Sie, wie und wann Sie Benachrichtigungen erhalten möchten.',
  enabledNotificationsCustomText: 'Personalisierung von Benachrichtigungen',
  realTimeNotificationsDescription: 'Die Benachrichtigungen werden in Echtzeit versendet. Für jedes abgeschlossene Ereignis wird eine E-Mail mit einem Status-Update versendet.',
  groupedNotificationsDescription: 'Die Benachrichtigungen werden in einem beigefügten Bericht, der eine Liste aller Vorgänge samt des jeweils letzten Ereignisses zum ausgewählten Zeitpunkt enthält.',
  groupedNotificationsReportTime: 'Uhrzeit, zu der Sie den Bericht erhalten möchten',
  languageAndTimezone: 'Sprache',
  defaultTimeZone: 'Standardzeitraum',
  user_process_notifications_updated: 'Aktualisierte Benachrichtigungen',
  changeSectionConfirmation: 'Zu speichernde Änderungen',
  changeSectionConfirmationText: 'Es wurden Änderungen durchgeführt, die noch gespeichert werden müssen. Wenn Sie diese speichern möchten, klicken Sie auf',
  discard: 'Entfernen',
  cost_center_updated: 'Kostenstelle erfolgreich aktualisiert',
  loginInfo: 'Anmeldeinformationen',
  lastLoginSuccess: 'Letzte Anmeldung',
  lastLoginError: 'Letzter Zugriffsversuch fehlgeschlagen',
  noRecord: 'Nicht registriert',
  charLimitLength: 'Feld darf höchstens {limit} Zeichen haben',
  requiredLastName: 'Erforderliche Nachrichten',
  optional: 'Optional',
  availableVariables: 'Verfügbare Variablen',
  twoFactorText: 'Der Benutzer erfordert doppelten Authentifizierungsfaktor Um die Sitzung zu starten, geben Sie bitte den Code ein, den Sie in Ihrer Anwendung zur Verwaltung von Multifaktor-Zugängen einsehen können.',
  mfa_configuration_required: 'Der Benutzer erfordert doppelten Authentifizierungsfaktor Zum Einrichten lesen Sie bitte den QR-Code aus Ihrer Anwendung zur Verwaltung von Multifaktor-Zugängen. Anschließend geben Sie den Code aus der unteren Tabelle ein.',
  code: 'Code',
  codeRequired: 'Der Code ist zum Starten der Sitzung erforderlich.',
  mfa_code_invalid: 'Der Code ist ungültig.',
  communicationContent: 'Inhalt der Mitteilung',
  uploadFiles: 'Dokumente anhängen',
  modifyFiles: 'Dokumente bearbeiten',
  fileResume: '{nfiles} Datei(en) insgesamt ({size})MB',
  moreThan1City: 'Die Postleitzahl entspricht mehr als einer Stadt, bitte nutzen Sie das Dropdown-Menü oder bearbeiten Sie das Feld manuell.',
  shipmentDataSectionDescription: 'In diesem Abschnitt wird der Inhalt der Mitteilung hinzugefügt. Die Dokumente müssen PDF-Format und Größe Din-A4 haben.',
  zipCodeFormat: 'Bitte geben Sie eine gültige Postleitzahl ein.',
  deleteRecipient: 'Diesen Empfänger löschen',
  mobileAddRecipientDescription: 'Mehr als ein Empfänger?',
  errorFilesTotalSizeExceeded: 'Die endgültige Größe der Dateien darf 15 MB nicht überschreiten',
  fileExtensionNotAccepted: 'Die Dateierweiterung ist ungültig. (die erlaubten Erweiterungen lauten= {acceptedExtensions})',
  nameAndSurnameLength: 'Die Vor- und Nachnamen dürfen 99 Zeichen nicht übersteigen',
  batchModeTitle: 'Batch-Sendung',
  batchModeCSVStep: 'Empfänger',
  batchModeAttachmentsStep: 'Erforderliche Dateien',
  batchModeDownloadSampleCSV: 'Beispieldatei herunterladen',
  batchModeExtraCSVStepIntroduction: 'Zunächst müssen Sie eine <strong>CSV-</strong> oder <strong>XLS-</strong>Datei mit den Daten der Sendungen hochladen. Unten können Sie eine Beispieldatei mit den erforderlichen Feldern herunterladen.',
  batchModeExtraCSVStepIntroduction2: 'Die vorbereitete Datei können Sie dann hinzufügen, indem Sie sie anklicken oder in den dafür vorgesehenen Bereich ziehen. Denken Sie daran, dass die Datei <strong>weder Leerzeilen noch Fehler</strong> in den Empfängern enthalten darf.',
  batchModeExtraCSVStepIntroduction3: 'Sobald die Dateistruktur validiert wurde, aktiviert sich die Schaltfläche <strong>Fortfahren</strong> und Sie können den Vorgang fortsetzen.',
  batchModeExtraFilesStepIntroduction: 'In diesem Schritt werden die für den Versand an die einzelnen Empfänger erforderlichen Medien hinzugefügt.<br><br>Es wurden insgesamt <b>{count}</b> Dateien gefunden. Sie können sie hinzufügen, indem Sie sie anklicken oder in den dafür vorgesehenen Bereich ziehen.<br><br>Bitte beachten Sie, dass die gesamten Dateien jeder Sendung <b>{fileSizeLimit} MB</b> nicht überschreiten dürfen und die maximale Gesamtgröße der Sendung <b>{totalSizeLimit} GB</b> beträgt.',
  extraFilesTableHeaderFileName: 'Dateiname',
  extraFilesTableHeaderStatus: 'Status',
  extraFilesTableStatusNotFound: 'Zum Hochladen ausstehend',
  extraFilesTableStatusProcessing: 'In Bearbeitung',
  extraFilesTableStatusUploaded: 'OK',
  extraFilesTableStatusUploadedCorrectly: 'Bearbeitet',
  extraFilesTableHeaderActions: 'Aktionen',
  batchModePreviewStep: 'Vorschau und senden',
  batchModeStep1RemoveFile: 'Datei löschen',
  batchModeCSVTableTitle: 'Die Datei <strong>{filename}</strong> enthält <strong>{nOfRecipients}</strong> Empfänger.',
  batchModeCSVTableSubtitle: 'Es folgt eine Vorschau auf die ersten Empfänger der Datei. Klicken Sie auf Mehr anzeigen, um weitere Informationen zu erhalten.',
  batchModeCSVTableSubtitleNoData: 'Die ausgewählte Datei enthält keine Empfänger.',
  batchModeNoDataTitle: 'Keine Datei ausgewählt. Nach dem Hinzufügen können Sie die ersten Empfänger der Datei sehen.',
  dropZoneText: 'Klicken oder ziehen Sie Ihre Dateien hierher',
  dropZoneTextSingle: 'Klicken oder ziehen Sie Ihre Datei hierher',
  batchModeDialogTitle: 'Empfänger hinzufügen',
  batchModeDialogManualColTitle: 'Von Hand',
  batchModeDialogManualColDescription: 'Wenn Sie eine oder mehrere Sendungen einzeln vornehmen möchten, wählen Sie diese Option.',
  batchModeDialogBatchColTitle: 'Batch-Sendung',
  batchModeDialogBatchColDescription: 'Wenn Sie auf einfache Art und Weise eine CSV- oder XLS-Datei mit mehreren Empfängern und Dokumenten versenden möchten, wählen Sie diese Option.',
  batchModeDialogBatchBtnManual: 'Einzelsendung',
  batchModeDialogBatchBtnBatch: 'Batch-Sendung',
  batchModeResourceTableNOfFilesFound: '<b>{nOfRequiredFiles}</b> hinzuzufügende Dateien',
  batchModeResourceTableAllFilesAdded: 'Alle erforderlichen Dateien wurden hinzugefügt',
  // batchModeResourceTableStatus : 'Aktueller Status= <b>$currentFiles$</b>/$nOfRequiredFiles$',
  batchModeResourceTableExpandText: 'Klicken Sie hier für weitere Details',
  batchModeResourceTableFilenameText: 'Dateiname',
  batchModeResourceTableFoundOnNRows: 'betroffene Empfänger',
  batchModeResourceTableErrorFilenameInvalid: 'Eine oder mehrere der Dateien, die hinzugefügt werden sollten, erfüllen nicht die Voraussetzungen.',
  batchModeResourceTableErrorFileSizeLimit: 'Die Dateigröße überschreitet {size} MB',
  batchModeResourceTableErrorTotalFileSizeLimit: 'Das Gesamtvolumen der hochgeladenen Dateien darf {size} GB nicht überschreiten',
  batchModeResourceTableInfoFileReplaced: 'Die Datei {filename} wurde ersetzt.',
  seeMore: 'Mehr zeigen',
  seeLess: 'Weniger zeigen',
  pending: 'Ausstehend',
  batchModePreviewText: 'Nach Abschluss der Bearbeitung können Sie die Transaktionen in der entsprechenden Ansicht einsehen und erhalten eine E-Mail mit den Informationen zu den einzelnen Sendungen und der Transaktionskennung des jeweiligen Empfängers.',
  batchModePreviewText1: 'Falls bestimmte Sendungen nicht möglich waren, wird in der E-Mail angegeben, welche und aus welchem Grund.',
  csvError: 'Die Datei konnte nicht bearbeitet werden',
  csvErrorDetailStructure: 'Die Dateistruktur ist ungültig.',
  csvErrorDetailIncorrectFields: 'Die Felder stimmen nicht mit den erforderlichen Vorgangsdaten überein',
  csvErrorDetailIncorrectExtensions: 'In folgenden Zeilen wurden ungültige Dateierweiterungen gefunden:',
  batchModeStep2SpaceLeft: 'Verwendeter Platz',
  batchModeStep2PendingFiles: 'Ausstehende Dateien',
  launchDataKoBatchMode: 'Die bereitgestellten Daten sind nicht gültig',
  launchDataKoBatchModeStep1: 'Es wurde keine Datei ausgewählt',
  launchDataOkBatchModeStep1: 'Die Dateistruktur ist korrekt, Sie können mit dem Vorgang fortfahren.',
  launchDataKoBatchModeStep2: 'Fügen Sie die erforderlichen Dateien hinzu',
  launchDataOkBatchModeStep2: 'Die erforderlichen Dateien wurden hinzugefügt, Sie können mit dem Vorgang fortfahren.',
  launchDataOkBatchMode: 'Die Daten sind korrekt, sie können mit dem Vorgang fortfahren.',
  batchModeProcessLaunchedStep1: 'Die Registrierung wird gestartet',
  batchModeProcessLaunchedStep2: 'Für die Sendungen benötigte Ressourcen werden hochgeladen',
  batchModeProcessLaunchedStep3: 'Die Empfängerdatei wird hochgeladen',
  batchModeProcessLaunchedStep4: 'Registrierung abgeschlossen',
  transactionsProcessedBatchMode: 'Batch-Sendung erfolgreich registriert',
  transactionProcessingBatchMode: 'Batch-Sendung wird bearbeitet ...',
  transactionProcessingBatchModeError: 'Fehler bei der Verarbeitung der Batch-Sendung',
  transactionProcessingBatchModeErrorDescription: 'Bei der Batch-Verarbeitung ist ein Fehler aufgetreten. Bitte wenden Sie sich an den Support.',
  csvErrorDialogDescription: 'Denken Sie daran, dass Sie eine <b>CSV-</b> oder <b>XLS-</b> Datei hochladen müssen und dass diese <strong>keine Leerzeilen oder Fehler</strong> in den Empfängerdaten enthalten darf.',
  extraFileError: 'Die Datei konnte nicht hinzugefügt werden',
  extraFileErrorDialogDescription: 'Bitte beachten Sie, dass die Größenbeschränkung pro Transaktion <b>{fileSizeLimit} MB</b> beträgt und die gesamte Batch-Sendung maximal <b>{totalSizeLimit} GB</b> groß sein darf.',
  extraFileErrorTransactionSizeLimit: 'Die maximale Sendegröße wurde bei folgenden Datensätzen überschritten= {rows}',
  htmlEditorInfoParagraph1: 'In diesem Schritt können Sie den Inhalt der E-Mails an die einzelnen Empfänger personalisieren.',
  htmlEditorInfoParagraph3: 'Wählen Sie dazu einen Bearbeitungsmodus aus:',
  htmlEditorInfoBullet1: '<span>Auswahl einer Vorlage</span>. Wählen Sie eine Vorlage aus und passen Sie sie im Editor an.',
  htmlEditorInfoBullet2: '<span>Personalisierter HTML-Text</span>. Laden Sie eine benutzerdefinierte HTML-Datei hoch und nehmen Sie die gewünschten Anpassungen mit dem Editor vor.',
  htmlEditorButtonUploadFile: 'HTML HOCHLADEN',
  htmlEditorDragAndDrop: 'Klicken Sie hier oder ziehen Sie die Anhänge in die E-Mail.',
  batchModeExtraCSVStepIntroductionVariables: 'Sie können in den Transaktionsfeldern auch folgende Variablen verwenden:',
  batchModeContactId: 'Referenz für den Empfänger',
  batchModeContactName: 'Vorname des Empfängers',
  batchModeContactSurname: 'Nachname(n) des Empfängers',
  htmlEditorTemplateSelected: 'Ausgewählte Vorlage',
  htmlSubjectError: 'Der Betreff hat mehr als <span>{subjectLimit}</span> Zeichen',
  htmlEditorInfoParagraph4: 'Bedenken Sie, dass die maximale Gesamtgröße der Dateien <b>{totalSizeLimit} Mb</b> beträgt.',
  backToProcess: 'Zurück zu Prozesse',
  infoAndHelp: 'Information und Hilfe',
  modelEmailHtmlTemplateSelector: 'Vorlage auswählen',
  modelEmailEmailBodyNotHtml: "Die ausgewählte Datei ist nicht html.",
  templateSelectorDefaultTemplate: 'Standard',
  templateSelectorCustomTemplate: 'Personalisiert',
  templateSelectorNoLogoTemplate: 'Ohne Logo',
  filesExcedingSizeLimit: 'Dateien, die die maximale Größe überschreiten',
  emailAttachmentsSectionTitle: 'E-Mail-Anhänge',
  emailContent: 'E-Mail-Inhalt',
  emailSubject: 'Betreff der E-Mail',
  multipleFileExtensionError: "Hay uno o más ficheros con extensiones inválidas. (Las extensiones aceptadas son: {acceptedExtensions})",
  filePasswordProtectedOrDamage: "Ungültige Datei. Stellen Sie sicher, dass die Datei nicht beschädigt oder passwortgeschützt ist, und versuchen Sie es erneut",
  backLostChangesHTMLText: "Wenn Sie zurückgehen, verlieren Sie die Änderungen in der E-Mail-Vorlage sowie die angehängten Dateien. <br>Sind Sie sicher, dass Sie zum vorherigen Schritt <b>zurückgehen</b> möchten?",
  backLostChangesHTMLConfirmBack: "Zum vorherigen Schritt zurückgehen",
  backLostChangesHTMLConfirmContinue: "Mit dem Senden fortfahren",
  processStepperDigitalSignatureConfigurationTitle: 'Konfiguration',
  processStepperDigitalSignatureConfigurationDescription: 'Personalisierung der Unterschriftszeremonie',
  processStepperDigitalSignatureSignersTitle: 'Unterzeichner',
  processStepperDigitalSignatureSignersDescription: 'Konfiguration der Unterzeichner und Unterschriftserfahrung',
  processStepperDigitalSignatureDocumentsTitle: 'Dokumente',
  processStepperDigitalSignatureDocumentsDescription: 'Auswahl der Dokumente und Interaktion der Unterzeichner',
  processStepperDigitalSignatureSummaryTitle: 'Bestätigung und Versand',
  processStepperDigitalSignatureSummaryDescription: 'Überprüfung und Bestätigung der Unterschriftszeremonie',
  transactionName: 'Transaktionsname',
  transactionInterveners: 'Transaktionsbeteiligte',
  more: 'mehr',
  finished: 'Abgeschlossen',
  eventsInfo: 'Ereignisinformationen',
  transactionInvalidAlert: 'Bei der Überprüfung der Eingabedaten der Transaktion wurden mögliche Fehler festgestellt, die eine ordnungsgemäße Ausführung verhindern könnten. Die Transaktion wurde vorsorglich blockiert. Sie sollten eine neue Transaktion mit den richtigen Eingabedaten starten.',
  transactionEntryData: 'Eingabedaten der Transaktion',
  goToSignature: 'Zur Unterschrift gehen',
  intervenersNotDetected: 'Keine Beteiligten erkannt',
  transactionEventsRegistered: 'Ereignisse in der Transaktion registriert',
  aboutEvents: 'Über die Ereignisse',
  notEvents: 'Es wurden keine Ereignisse in der Transaktion registriert.',
  notEventsRegistered: 'Es wurden keine Ereignisse in der Transaktion registriert. Wenn die Transaktion kürzlich erstellt wurde, wird sie möglicherweise noch generiert.',
  downloadCertificate: 'Zertifikat herunterladen',
  notValid: 'Ungültig',
  transactionPending: 'Ausstehend',
  refresh: 'Aktualisieren',
  creation: 'Erstellung',
  transactionDigitalSignatureInfo: 'Informationen zur digitalen Transaktionsunterschrift',
  reminders: 'Erinnerungen',
  input: {
    clear: 'Löschen'
  },
  intervenerStartCeremony: 'Um die Unterschriftenschicht dieses Beteiligten zu beginnen, wird ein neuer Tab geöffnet. Bitte beachten Sie, dass, falls in der Transaktion eine Unterschriftsreihenfolge vorhanden ist, die vorherigen Beteiligten die Dokumente abgeschlossen haben müssen.',
  details: 'Details',
  front_renderer_builder_transaction_opened: 'Unterschriftentransaktion aufgerufen',
  front_renderer_builder_transaction_finished: 'Die Unterschriftentransaktion wurde von allen Beteiligten abgeschlossen',
  front_renderer_builder_intervener_signed: 'Ein Beteiligter hat die Unterzeichnung der zugehörigen Dokumente abgeschlossen',
  front_renderer_builder_transaction_created: 'Die Unterschriftentransaktion wurde erfolgreich erstellt',
  front_renderer_builder_transaction_invalid: 'Die Unterschriftentransaktion enthält Fehler und konnte nicht erfolgreich erstellt werden',
  front_renderer_builder_intervener_finished: 'Beteiligter hat alle Unterschriften abgeschlossen',
  certificate_builder_generated: 'Das Abschlusszertifikat der Transaktion wurde erstellt, das den Inhalt der Kommunikation und die zu den im Prozess aufgetretenen Ereignissen gehörenden Beweise enthält.',
  transactionFilesParagraph: 'Liste der Dateien, die die Transaktion enthalten',
  transactionDigitalProcessed: 'Transaktion "{transactionName}" erfolgreich verarbeitet',
  signatureType: 'Art der Unterschrift',
  faceToFace: 'Persönlich',
  remote: 'Remote',
  transactionWarning: 'Mit Fehlern',
  cancelTransaction: 'Transaktion stornieren',
  identifyTransactionPlataformCommunications: 'Identifizieren Sie die Transaktion auf der Plattform und in den Kommunikationen',
  placeholderTransactionName: 'Transaktionsname',
  determineMethodAccessSigning: 'Bestimmen Sie die Zugriffsart zur Unterschriftszeremonie',
  transactionType: 'Transaktionsart',
  descriptionRemoteSignature: '<p><b>Die elektronische Fernsignatur</b> ermöglicht es den Unterzeichnern, von jedem Ort und Gerät über die verfügbaren Kommunikationskanäle auf die Unterschriftszeremonie zuzugreifen.</p>',
  descriptionPresentialSignature: '<p><b>Die persönliche Unterschrift</b> ermöglicht es den Unterzeichnern, über das Gerät, das zur Initiierung der Transaktion verwendet wurde, darauf zuzugreifen.</p>',
  setSignatureReminders: 'Setzen Sie die Erinnerungen für den Zugang zur Unterschriftszeremonie',
  transactionReminder: 'Erinnerungen zur Transaktion',
  transactionRedirectToTransactionDetail: 'Transaktion ansehen',
  transaccion_cancelada: 'Transaktion storniert',
  cancelled: 'Storniert',
  digital_certificate: 'Zert.',
  cert: 'Zert.',
  nif: 'DNI/NIF',
  sms_otp: 'SMS OTP',
  email_otp: 'E-Mail OTP',
  video_id: 'Dokumentenidentifikation',
  ama: 'AMA Portugal',
  lastUpdate: 'Letzte Aktualisierung',
  cancelTransactionAdvertisement: 'Die Stornierung der Transaktion ist nicht umkehrbar. Bitte beachten Sie, dass der Stornierungsprozess einige Minuten in Anspruch nehmen kann. Sind Sie sicher, dass Sie die Transaktion stornieren möchten?',
  transaction_cancelled: 'Transaktion storniert',
  transaction_not_cancelled: 'Die Transaktion konnte nicht storniert werden',
  signed: 'Unterschrift abgeschlossen',
  addReminder: 'Erinnerung hinzufügen',
  sendingNewReminder: 'Fügen Sie eine Erinnerung hinzu, indem Sie die Tage festlegen, die zwischen der ersten Sendung oder der vorherigen Erinnerung vergehen müssen',
  confirm: 'Bestätigen',
  sinceCreation: 'seit der Erstellung',
  sinceReminderNumber: 'seit der Erinnerung Nr.',
  reminderLimitReached: 'Das Erinnerungslimit wurde erreicht',
  reminderLimitReachedZero: 'Es können keine Erinnerungen hinzugefügt werden',
  customTextComplementSignatureAccessInformation: 'Informationstext, der die Zugangsmails zur Unterschrift enthält',
  globalMessageInterveners: 'Globale Nachricht an die Beteiligten',
  exampleInformSignatory: 'Geben Sie den Inhalt der Nachricht ein',
  days: 'Tage',
  infoReminder: '<p>Die Erinnerungen zur Unterschriftszeremonie sind die nach der Erstellung an die Unterzeichner gesendeten Mitteilungen. Das Limit ergibt sich aus dem Ablaufdatum der Transaktion oder, falls vorhanden, den Limits des Prozesses selbst.</p>',
  infoReminderDays: '<p>Die Tage, die im ersten Erinnerungsversand angegeben sind, werden <b>seit der Erstellung der Transaktion</b> gezählt, während die Tage, die in den folgenden Erinnerungen angegeben sind, seit dem Versand der vorherigen Erinnerung gezählt werden.</p>',
  determineDaysSigningProcessRemainActive: 'Bestimmen Sie die Tage, an denen die Unterschriftszeremonie aktiv bleibt',
  transactionExpiration: 'Ablaufdatum der Transaktion',
  orderOfSignature: 'Reihenfolge der Unterschrift',
  signatoriesAdded: 'zur Transaktion hinzugefügt',
  infoTransactionWithoutSignatures: 'Die Transaktion hat noch keine Unterzeichner. Sie können damit beginnen, sie über die Schaltfläche unten hinzuzufügen.',
  intervenersLimitReached: 'Sie haben das Limit der Unterzeichner erreicht',
  addSigner: 'Unterzeichner hinzufügen',
  signerEdition: 'Konfiguration des Unterzeichners',
  nameSurname: 'Name und Nachname / Firmenname',
  placeholderSignerName: 'Juan Beispiel Beispiel',
  identificationType: 'Identifikationstyp',
  withoutIdentification: 'Ohne Identifikation',
  securitySignature: 'Bestätigungsart',
  shippingChannel: 'Versandkanal',
  completetionEmail: 'Abschluss-E-Mail',
  personalizedMessage: 'Personalisierte Nachricht der ersten E-Mail',
  numberDocument: 'Dokument-Nr.',
  contactMethod: 'Kontaktmethode',
  notIndicated: 'Nicht angegeben',
  identificationSignature: 'Identifikation/Unterschrift',
  documents: 'Dokument(e)',
  infoTransactionWithoutDocuments: 'Die Transaktion hat noch keine Dokumente. Sie können beginnen, diese über die Schaltfläche unten hinzuzufügen.',
  documentsLimitReached: 'Sie haben das Limit für Dokumente erreicht',
  addDocument: 'Dokument hinzufügen',
  requirementsAddDocument: 'Sie müssen beachten, dass die Dokumente die folgenden Anforderungen erfüllen müssen:',
  addDocumentError: 'Das Dokument konnte nicht hinzugefügt werden',
  documentNameRepeated: 'Der Name des Dokuments, das Sie hinzufügen möchten, existiert bereits in der Transaktion.',
  documentDetail: 'Dokumentdetails',
  documentActions: 'Aktionen',
  withoutActions: 'Ohne Aktionen',
  positionSignature: 'Unterschriften positionieren',
  deleteDocument: 'Dokument löschen',
  size: 'Größe',
  totalFileSizeExceeded: 'Die Gesamtgröße der hochgeladenen Dateien darf {size&Unit} nicht überschreiten',
  minCharacters: 'Mindestens {min} Zeichen',
  maxCharacters: 'Maximal {max} Zeichen',
  expirationDays: 'Muss größer als 0 und kleiner als {max} Tage sein',
  expirationMustBeLongerThanReminders: 'Das Ablaufdatum muss länger sein als die Erinnerungen',
  reminderRuleAvailableDaysInput: 'Muss größer als 0 und kleiner als {max} Tage sein',
  remoteDigitalSignature: 'Fernsignatur',
  presentialSignature: 'Persönliche Unterschrift',
  none: 'Keine',
  noneMasc: 'Keiner',
  certificateDigital: 'Digitales Zertifikat',
  alertAbandonProcess: 'Wenn Sie den Prozess jetzt abbrechen, verlieren Sie die eingegebenen Daten. Sind Sie sicher, dass Sie das Programm verlassen möchten?',
  amaPT: 'Ama Portugal',
  graphometricSignature: 'Graphometrische Unterschrift',
  signature: 'Unterschrift',
  documentSignatures: 'Dokumentunterschriften',
  closeDocument: 'Dokument schließen',
  selectSignersActions: 'Wählen Sie einen Unterzeichner aus, um die verfügbaren Aktionen für das Dokument zu erfahren',
  sendSignedDocuments: 'Unterschriebene Dokumente senden',
  notSendSignedDocuments: 'Keine unterschriebenen Dokumente senden',
  documentLimitSizeAlert: 'Die Größe des Dokuments darf 12 MB nicht überschreiten',
  documentFormatAlert: 'Die Dokumente müssen im PDF-Format vorliegen und dürfen nicht verschlüsselt oder beschädigt sein',
  documentLimitCountAlert: 'Das Dokumentenlimit für die Transaktion beträgt ',
  signersAdded: 'Unterzeichner',
  sendTransaction: 'Unterschriftszeremonie senden',
  sendTransactionInfo: 'Oben haben Sie eine Zusammenfassung der während der Konfiguration eingegebenen Daten. Nachdem Sie diese überprüft haben, können Sie die Transaktion über die Schaltfläche unten senden',
  transactionOkMessage: 'Die Transaktion wurde erfolgreich verarbeitet. In den nächsten Minuten werden Sie die damit verbundenen Ereignisse in der Ansicht "Transaktionsdetails" sehen können. Sie können darauf zugreifen, indem Sie auf die Schaltfläche unten klicken',
  transactionsOkMessage: 'Die Transaktionen wurden erfolgreich verarbeitet. In den nächsten Minuten werden sie in der Ansicht "Transaktionen" angezeigt. Sie können darauf zugreifen, indem Sie auf die Schaltfläche unten klicken',
  goToPresentialCeremony: 'Beginn der persönlichen Unterschriftszeremonie',
  interactionType: 'Interaktionstyp',
  signDocuments: 'Dokumente unterschreiben',
  acceptDocuments: 'Dokumente akzeptieren',
  requireCompleteReading: 'Vollständige Überprüfung des Dokuments',
  availableActions: 'Verfügbare Aktionen',
  signerAcceptDocument: 'Der Unterzeichner muss dieses Dokument akzeptieren',
  signaturesNotAvailable: 'Unterschriften sind nicht verfügbar, wenn der Unterzeichner die "Akzeptanz der Dokumente" aktiviert hat',
  selectSigners: 'Unterzeichner auswählen',
  selectSignersDescription: 'Wählen Sie die Unterzeichner der Transaktion aus, die am Dokument teilnehmen sollen',
  action: 'Aktion',
  singleClickSignatureInfoText: 'Die Dokumentakzeptanz wurde aktiviert, daher wird es mit einem einzigen Klick unterzeichnet.',
  requireScrollToBottomInfoText: 'Die vollständige Überprüfung des Dokuments ist aktiviert',
  page: 'Seite',
  interveners: 'Intervenienten',
  "face-to-face": 'Persönlich',
  expiration: 'Ablauf',
  signatureStatus: 'Unterschriftsstatus',
  remaining: 'Verbleibend',
  completed: 'Abgeschlossen',
  contact: 'Kontakt',
  events: 'Ereignisse',
  notSpecified: 'Nicht spezifiziert',
  intervener: 'Intervenient',
  reference: 'Referenz',
  expired: 'Abgelaufen',
  documentViewerDialogSubtitle: 'Wählen Sie die Unterzeichner aus, die mit dem Dokument interagieren werden, sowie die durchzuführenden Aktionen',
  front_renderer_builder_intervener_identification_success: 'Der Unterzeichner wurde in der Signaturzeremonie erfolgreich identifiziert',
  front_renderer_builder_signature_otp_generated: 'Der Zugangscode für die Transaktion wurde generiert',
  front_renderer_builder_signature_otp_sent: 'Der Zugangscode für die Transaktion wurde an das Gerät des Unterzeichners gesendet',
  front_renderer_builder_transaction_expired: 'Die Transaktion ist nicht mehr zugänglich, da sie abgelaufen ist',
  batches: 'Batch-Sendungen',
  view_batches: 'Batch-Sendungen',
  open: 'Öffnen',
  selectProcess: 'Wählen Sie einen Prozess aus',
  transactionsSearchPlaceHolder: 'Suche nach Transaktions-ID oder Teilnehmer',
  batchesDescription: 'In diesem Abschnitt werden die auf der Plattform durchgeführten Batch-Sendungen angezeigt',
  scheduleTime: 'Versanddatum',
  schedule: 'Status/Versand',
  totalRecords: 'Einträge',
  showTransactions: 'Transaktionen anzeigen',
  cancelBatch: 'Sendungen stornieren',
  cancelBatchConfirmText: 'Möchten Sie den Versand und alle zugehörigen Transaktionen wirklich stornieren?',
  selectDateFilter: 'Datumsfilter',
  stateFilterPlaceholder: 'Statusfilter',
  processFilterPlaceholder: 'Prozessfilter',
  batchSearchTooltip: 'Die verfügbaren Felder für die Suche sind',
  batchSearchTooltipFirstItem: 'Batch-Sendung-ID',
  batchSearchTooltipSecondItem: 'E-Mail des sendenden Benutzers',
  batchSearchTooltipThirdItem: 'Name der Versanddatei',
  processed: 'Verarbeitet',
  batchCancelled: 'Abgebrochen',
  batchDetail: 'Details der Batch-Sendung',
  batchInfo: 'Versandinformationen',
  batchInfoSectionIntroductionText: 'Wenn gewünscht, können Sie einen eindeutigen Namen auswählen, der während des gesamten Prozesses verwendet wird. Wenn dieses Feld ausgelassen wird, wird der Prozessname verwendet.',
  batchNamePlaceholder: 'Standardname (Prozessname)',
  shipmentScheduling: 'Versandplanung',
  scheduleShipmentIntroduction: 'Sie können den Versand für ein bestimmtes Datum und eine bestimmte Uhrzeit planen. Wenn kein Datum ausgewählt wird, erfolgt der Versand sofort.',
  shippingDate: 'Versanddatum',
  time: 'Uhrzeit',
  selectDate: 'Datum',
  selectTime: 'Uhrzeit',
  scheduledShipmentIncompleteDate: 'Sie müssen ein Datum und eine Uhrzeit auswählen, um den Versand zu planen',
  scheduleShipment: 'Versand planen',
  edit: 'Bearbeiten',
  digitalSignatureEmptyStepMessage: 'Es gibt kein verfügbares Feld zum Bearbeiten. Drücken Sie die Taste <b>Weiter</b>, um fortzufahren.',
  shipmentScheduled: 'Versand geplant für',
  scheduled: 'Geplant',
  sentTransactions: 'Gesendete Transaktionen',
  sent: 'Gesendet',
  pendingTransactions: 'Geplante Transaktionen',
  see: 'Ansehen',
  backToTransactions: 'Zurück zu gesendeten Transaktionen',
  shipmentDate: 'Versanddatum',
  selectProcessFirst: 'Wählen Sie zuerst einen Prozess aus, um die verfügbaren Status anzuzeigen',
  scheduledShipment: 'Versand',
  scheduledPastShipment: 'Versandt',
  errors: 'Fehler',
  checkEmailInfoErrors: 'Sie können die E-Mail mit dem Prozessergebnis überprüfen, um die fehlerhaften Transaktionen und die Gründe zu erfahren.',
  nextMinutes: 'In den nächsten Minuten'

}
